.modal-popup {
    background-color: rgba(black, 0.6);

    .swiper-container {
        padding-bottom: 0;
        margin-bottom: 0;
    }
}

.modal-slide .action-close,
.modal-popup .action-close {
    padding: 10px;
    top: 10px;
    right: 10px;
}
.modal-popup:not(.confirm) .modal-inner-wrap .modal-content{
    padding: 3rem;

    p {
        margin-bottom: 2rem;
    }
}



