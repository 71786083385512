.block-preview-account {
    &__title {
        padding: 15px 0;
        width: 100%;
        border-bottom: 1px solid rgba($color: rgb(154, 154, 154), $alpha: 0.2);

        span {
            font-size: 14px;
            font-weight: bold;
        }
    }

    &__content {
        padding: 25px 0;
    }
}

