.store-locator-container {
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
    align-items: flex-start;

    .store-locator-tabs {
        width: 100%;
        margin-bottom: 40px;
        margin-top: 30px;

        .tab {
            text-transform: uppercase;
            margin-right: 40px;
            letter-spacing: 2px;
            font-size: 16px;
            line-height: 22px;
            position: relative;

            &.active:after {
                content: "";
                height: 2px;
                background-color: #f77e00;
                width: 100%;
                position: absolute;
                left: 0;
                bottom: -4px;
            }
        }
    }

    .store-locator-form {
        width: 100%;

        @include min-screen($screen__m) {
            width: 320px;
        }

        .field {
            width: 100%;
            margin: 0px;
            margin-bottom: 30px;
        }

        .control {
            width: 100%;
        }

        #around-me {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 20px;
                margin-right: 10px;
            }
        }

        .store-locator-link {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .stores-container {
        width: 100%;

        flex-grow: 1;

        @include min-screen($screen__m) {
            margin-left: 20px;
            width: calc(100% - 340px);
        }

        .stores {
            display: flex;
            flex-wrap: wrap;

            .store-city {
                margin-bottom: 40px;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                > p {
                    width: 100%;
                    text-transform: uppercase;
                    margin-bottom: 25px;
                }
            }

            .store {
                padding: 20px;
                cursor: pointer;
                margin-bottom: 10px;

                box-sizing: border-box;
                border: 1px solid rgba(25, 25, 25, 0.32);

                width: 100%;
                @include min-screen($screen__l) {
                    margin-right: 10px;
                    width: calc(50% - 10px);
                }
                @include min-screen($screen__xl) {
                    width: calc(33% - 10px);
                }

                display: flex;

                &:hover {
                    background-color: rgba(200, 200, 200, 0.5);
                }

                .left {
                    width: 30px;
                    padding-top: 10px;

                    svg {
                        width: 24px;
                    }
                }

                .right {
                    margin-left: 10px;
                    width: calc(100% - 40px);
                }

                &-directions {
                    color: $primary;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                    margin-top: 10px;
                }

                &-name {
                    font-size: 14px;
                    font-weight: bold;
                    line-height: 19px;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                    margin-bottom: 10px;
                }

                &-address {
                    margin: 0;
                    font-size: 12px;
                    line-height: 16px;
                    text-transform: uppercase;
                    color: #202020;
                }

                &-phone {
                    margin-top: 10px;
                    font-size: 12px;
                    line-height: 16px;
                    text-transform: uppercase;
                }

                &-email {
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }
    }

    .load-more {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 64px;
    }
}

.store-details-container {
    margin-left: 20px;
    margin-right: 20px;

    display: flex;
    margin-top: 30px;
    margin-bottom: 80px;
    flex-wrap: nowrap;
    flex-direction: column;

    @include min-screen($screen__m) {
        flex-direction: row;

        .store-details,
        #map {
            flex: 1;
        }
    }

    .store-details {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 50px;
        font-size: 1.4rem;

        &-name {
            width: 100%;

            h2 {
                font-size: 28px;
                line-height: 39px;
                text-transform: uppercase;
            }
        }

        &-bottom {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
        }

        &-return {
            border: 0;
            font-size: 12px;
            letter-spacing: 2px;
            text-transform: uppercase;
            line-height: 16px;
            margin-bottom: 30px;
            padding: 0;
        }

        &-type {
            display: flex;
            align-items: center;

            p {
                margin: 0 !important;
                font-weight: 300;
                line-height: 25px;
                color: #262729;
            }

            svg {
                margin-right: 10px;
                height: 30px;
                width: 36px;
            }
        }

        &-address,
        &-contact {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-bottom: 30px;

            strong {
                display: block;
                margin-bottom: 10px;
                font-weight: bold;
                font-size: 1.8rem;
                line-height: 24px;
                letter-spacing: 1px;
            }

            .contact-item {
                margin: 0;
                line-height: 25px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                svg {
                    margin-right: 10px;
                    width: 14px;
                }
            }

            a {
                color: $primary;
            }
        }

        &-direction {
            text-transform: uppercase;
            margin-top: 10px;
            font-size: 1.2rem;
        }
    }

    #map:not(:empty) {
        height: 600px;
        width: 100%;
        z-index: 0;
    }

    .store-image {
        width: 100%;
        display: flex;
        align-items: center;

        @include min-screen($screen__m) {
            width: 50%;
        }

        img {
            width: 100%;
        }
    }
}
