.column_image_link {
    padding: 30px 15px;

    @include min-screen($screen__m) {
        padding: 40px 20px;
    }

    @include min-screen($screen__xl) {
        padding: 40px calc(3vw + 20px);
    }

    .column-link-image-group {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        @include min-screen($screen__m) {
            flex-direction: row;
        }

        .column-link-image {
            display: flex;
            flex-direction: column;
            width: 100%;

            @include min-screen($screen__m) {
                width: calc(50% - 10px);
            }

            img {
                flex-grow: 1;
                object-fit: cover;
            }

            &__link {
                @extend .link-more;
                margin-bottom: 10px;
                margin-top: 10px;
            }
        }
    }
}
