
.block-owc {
    h1 {
        @extend .title-h1;
    }

    p {
        @extend .text-default;
    }

    & > .page-container {
        padding-bottom: 10rem;

        @include max-screen($screen__m) {
            padding: 0 0 10rem;
        }

    .listCards .productCard .technical-spec {
      min-width:100%;
      padding: 0 3rem;
      text-align: left;

      svg {
        vertical-align: text-top;
        margin-right: 0.6rem;
      }

      .toggle {
        text-transform: uppercase;
        color: #252525;
        letter-spacing: 2px;
        font-size: 12px;
        padding: 10px 0;
        border-top: 1px solid rgba(0,0,0,.2);
      }

      .toggle:after {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 28px;
        line-height: 16px;
        color: inherit;
        content: "";
        font-family: icons-blank-theme;
        vertical-align: middle;
        display: inline-block;
        font-weight: 400;
        overflow: hidden;
        speak: none;
        text-align: center;
        position: static;
        float:right;
        width:20px;
      }
      .productCard__infos {
        margin: 1rem 0px 2rem;
        text-align: left;
        line-height: 1;

          span {
              text-align:left;
              display: inline;

              .description {
                  font-weight:bold;
              }
          }

          p {
              font-size: 12px;
              line-height: 16px;
              letter-spacing: 2px;
              text-transform: uppercase;
          }

          .btn {
              display:block;
              min-width: 100%;
              margin-top: 1rem;
              text-align:center;
              text-decoration:none;
          }
      }
    }
    .listCards .productCard .technical-spec.active>.toggle:after {
          content: "";
      }
    .form,
    .page-sub-title {
      margin-bottom: 2rem;
      max-width: 54rem;
      width: 80%;
      @include max-screen($screen__m) {
        width: 100%;
      }

            .fieldset {
                margin-bottom: 2rem;
            }

            .form-group {
                width: 100%;
            }
        }

        .product-wrapper {
            max-width: 50rem;
            width: 80%;
            @include max-screen($screen__m) {
                width: 100%;
            }
        }

        & > .productCard {
            max-width: 43rem;
            width: 100%;

            @include max-screen($screen__s) {
                max-width: none;
            }
        }

        .page-btns.actions-toolbar {
            margin-right: -1rem;
            max-width: 55rem;

            @include max-screen($screen__s) {
                margin-right: 0;
                max-width: none;
            }

            .btn {
                min-width: calc(50% - 1rem);
                margin-bottom: 1rem;
                @include max-screen($screen__s) {
                    min-width: 0;
                }
            }
        }
    }

    .page-btns {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 5rem;
        margin-left: 0 !important;

        .btn {
            margin-right: 1rem;
        }

        p {
            width: 100%;
            font-size: 14px;
            line-height: 21px;
            color: #404141;

            a {
                text-decoration: underline;
            }
        }

        @include max-screen($screen__s) {
            flex-direction: column;
            margin-bottom: 3rem;

            .btn {
                width: 100%;
                margin-right: 0;
                margin-bottom: 1rem;
            }
        }
    }

    .page-back {
        color: #DA322A;
        font-weight: 300;
        font-size: 14px;
        line-height: 30px;
        display: block;
        margin-bottom: 2rem;

        .icon {
            display: inline-block;
            vertical-align: text-top;
            margin-right: 0.6rem;
            transition: all 0.2s ease-in-out;
        }

        &:visited:not(:hover) {
            color: #DA322A;
        }

        &:hover, &:focus {
            color: #DA322A;

            .icon {
                margin-right: 1rem;
            }
        }
    }

    .page-alert {
        background: rgba(26, 159, 11, 0.1);
        margin-bottom: 3rem;
        padding: 2rem;

        p {
            color: #404141;
            font-weight: bold;
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 0;

            &:not(:last-child) {
                @include max-screen($screen__s) {
                    margin-bottom: 2rem;
                }
            }
        }
    }

    .listCards {
        margin: 0 -.5rem 10rem;
        padding: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;

        li {
            width: 50%;
            padding: .5rem;
            margin-bottom: 0;

            @include max-screen($screen__l) {
                width: 100%;
                margin-bottom: 1.5rem;
            }

            .productCard {
                display: flex;
                margin-bottom: 0;
            }
        }
    }

    .productCard {
        display: flex;
        margin-bottom: 3rem;
        height: 100%;
        align-items: center;
        text-align: center;
        padding: 1.8rem 3rem 3rem;
        flex-direction: column;
        border: 1px solid #C4C4C4;

        @include max-screen($screen__l) {
            padding: 1.8rem 1rem 3rem;
        }

        &__actions {
            width: 100%;
            margin-top: -1.4rem;

            @include min-screen($screen__l) {
                margin-right: -5rem;
            }

            .action-close {
                background-image: none;
                background: none;
                -moz-box-sizing: content-box;
                border: 0;
                box-shadow: none;
                line-height: inherit;
                margin: 0;
                padding: 0;
                text-shadow: none;
                font-weight: 400;
                text-decoration: none;
                float: right;

                &:before {
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    font-size: 32px;
                    line-height: 32px;
                    color: #8f8f8f;
                    content: "";
                    font-family: icons-blank-theme;
                    margin: 0;
                    vertical-align: top;
                    display: inline-block;
                    font-weight: 400;
                    overflow: hidden;
                    speak: none;
                    text-align: center;
                }
            }
        }

        &__aroundImage {
            height: 300px;
        }

        &__visual {
            width: 26.5rem;
            margin-bottom: 1.6rem;
        }

        &__title {
            color: black;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 2px;
            margin-bottom: 1.6rem;
            text-transform: uppercase;
        }

        &__infos {
            margin-bottom: 2.5rem;

            &:last-child {
                margin-bottom: 0;
            }

            span {
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                letter-spacing: 2px;
                display: block;
                text-transform: uppercase;
            }
        }

        &__warranty {
            margin-bottom: auto;
            color: #333333;
            font-size: 13px;
            line-height: 24px;
            vertical-align: middle;
            text-transform: uppercase;
            font-weight: bold;

            &.-reversed {
                color: white;
                background-color: black;
                border-radius: 16px;
                padding: 4px 12px;
            }

            svg {
                vertical-align: text-top;
                margin-right: 0.6rem;
            }
        }

        p {
            color: #404141;
            font-size: 14px;
            line-height: 21px;
            margin-top: 1.4rem;
            margin-bottom: 0;
            width: 80%;

            a {
                text-decoration: underline;
            }
        }

        .btn {
            min-width: 25rem;
            margin-top: 3rem;
        }

        .btn.only {
            margin-top: 0;
        }

        .btn + .btn {
            margin-top: 1rem;
        }
    }

    .owc-account {
        min-height: 70vh;

        @include max-screen($screen__m) {
            min-height: 0;
        }

        .page-container {
            max-width: 50rem;
            margin: 0 auto;
            padding: 5rem 0;

            @include max-screen($screen__m) {
                padding: 2rem 2rem 10rem;
            }
        }

        .page-sub-title, .page-sub-title p {
            font-weight: 300;
            font-size: 18px;
            line-height: 30px;
            margin-bottom: 4rem;

            @include max-screen($screen__m) {
                margin-bottom: 3rem;
            }
        }

        .page-sub-title p {
            margin-bottom: 2rem;
            @include max-screen($screen__m) {
                margin-bottom: 0;
            }
        }

        &.-create {
            .page-title {
                margin-bottom: 5rem;
                @include max-screen($screen__m) {
                    margin-bottom: 2rem;
                }
            }

            .fieldset {
                margin-bottom: 5rem;
                @include max-screen($screen__m) {
                    margin-bottom: 4rem;
                }
            }
        }

        &.-create, &.-login {
            .page-title, .page-sub-title {
                text-align: center;
                @include max-screen($screen__m) {
                    text-align: left;
                }
            }
        }

        &.-landing {
            position: relative;
            overflow: hidden;

            &:after {
                display: block;
                content: '';
                background: #000 var(--url-image) no-repeat center center / cover;
                position: absolute;
                left: 50%;
                top: 0;
                width: 50%;
                height: 100%;
                @include max-screen($screen__m) {
                    display: none;
                }
            }

            .page-title {
                margin-bottom: 2rem;
                @include max-screen($screen__m) {
                    margin-bottom: 1rem;
                }
            }

            .page-sub-title {
                margin-bottom: 3rem;
                @include max-screen($screen__m) {
                    margin-bottom: 1rem;
                }
            }

            .page-container {
                width: calc(50vw - 8rem);
                transform: translateX(-25vw);

                @include max-screen($screen__m) {
                    width: 100%;
                    transform: none;
                }
            }
        }

        &.-check {
            position: relative;

            &:after {
                display: block;
                content: '';
                background: #000 url("../images/OWC_CS.jpg") no-repeat center center / cover;
                position: absolute;
                left: 50%;
                top: 0;
                width: 50vw;
                height: 100%;
                @include max-screen($screen__m) {
                    display: none;
                }
            }

            .page-title {
                margin-bottom: 2rem;
                @include max-screen($screen__m) {
                    margin-bottom: 1rem;
                }
            }

            .page-sub-title {
                margin-bottom: 3rem;
                @include max-screen($screen__m) {
                    margin-bottom: 1rem;
                }
            }

            .page-container {
                width: calc(50vw - 8rem);
                transform: translateX(-25vw);

                @include max-screen($screen__m) {
                    width: 100%;
                    transform: none;
                }
            }
        }

        .block-title > h4 {
            @extend .title-h4;
            margin-top: 0;
            margin-bottom: 2rem;
            font-size: 16px;
            line-height: 23px;
            letter-spacing: 2px;
            text-transform: uppercase;
        }

        .form {
            &.create.account {
                min-width: 0;
                width: 100%;
            }

            .form-group {
                width: 100%;
            }

            .control .fieldset {
                margin-bottom: 2rem;
            }
        }

        .actions-toolbar {
            margin-top: 3rem;
            margin-left: 0 !important;

            .btn {
                width: 100%;
            }
        }
    }

    .captcha-container {
        margin-bottom: 2rem;
        @include max-screen($screen__m) {
            margin-bottom: 3rem;
        }
    }

    .form-link {
        display: inline-block;
        margin-bottom: 2rem;
        font-weight: 300;
        font-size: 18px;
        line-height: 30px;
        text-decoration-line: underline;
    }

    #google-captcha {
        margin-top: 0;
    }

    div.field-recaptcha {
        position: relative;

        div.field {
            position: absolute;
            z-index: -1;
            top: 2rem;
            left: 2rem;

            div.control {
                position: relative;
                z-index: -1;

                input.mage-error {
                    border-color: white;
                }

                input:focus {
                    outline: none;
                    outline-offset: 0;
                }

                div.mage-error {
                    position: relative;
                    top: unset;
                    left: unset;
                    text-transform: none;
                }
            }
        }
    }

  .warrantycard {
    text-align: center;

    .page-sub-title {
        margin: 0 auto 2rem;
    }

      .page-btns.actions-toolbar{
          margin:0 auto 5rem !important;

          a{
              margin:0 auto 1rem
          }
      }
  }
}

.block-owc.double {
    .page-title{
        width: 100%;
        text-align: center;
        margin: 40px 0 15px;
    }
    .warrantycard {

        .left,.right {
            -webkit-box-flex: 1;
            flex: 1;
        }

        .block-title h3{
            @extend .title-h3;
            font-family: $font-family-headings;
            font-weight: 500;
            font-size: 1em;
            line-height: 1.44em;
            letter-spacing: 2px;
            text-transform: uppercase;
            color: #000;
            text-align: center;
            margin: 1.5rem auto 1rem;
        }

        .block-content p{
            padding: 40px 0;
            margin: 0;
        }
    }
}

@media only screen and (min-width: 768px) {
    .block-owc.double .warrantycard {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
        -webkit-box-align: start;
        align-items: flex-start;
        display: flex;
        margin: 50px 0;
        width: 100%;

        .left {
            padding-right: 5%;
        }

        .right {
            padding-left: 5%;
            border-left: 1px solid #cdcdcd;
        }
    }
}

.modal-owc {
    &.modal-popup.modal-slide {
        @include max-screen($screen__m) {
            left: 0;
        }
    }

    .modal-inner-wrap {
        max-width: 73rem;

        .modal-content {
            padding: 0 3rem;
        }
    }

    .owc-modal {
        padding-top: 4rem;
        padding-bottom: 5rem;

        &__title {
            font-size: 40px;
            line-height: 50px;
            text-align: center;
            letter-spacing: 1.5px;
            text-transform: uppercase;
            color: #000000;
            font-weight: bold;
            margin-bottom: 4rem;

            @include max-screen($screen__m) {
                font-size: 25px;
                line-height: 28px;
                letter-spacing: 1.5px;
            }
        }

        &__visual {
            margin-bottom: 4rem;
        }

        &__desc {
            text-align: center;
            @extend .text-default;
        }

        .productError {
            max-width: 43rem;
            margin: 4rem auto 0;
            text-align: center;
        }

        .productResume {
            background: #F2F2F2;
            display: flex;
            max-width: 43rem;
            margin: 0 auto 2rem;
            padding: 1.4rem 2.5rem;
            align-items: center;

            &__visual {
                width: 5rem;
                margin-right: 4rem;
                margin-bottom: 0;
            }

            &__title {
                font-weight: bold;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 2px;
                text-transform: uppercase;
            }
        }

        .form {
            max-width: 43rem;
            margin: 0 auto;

            .select-group {
                width: 100%;
                margin: 0 0 2rem;

                label {
                    display: block;
                    margin-bottom: 1rem;
                }

                &:before {
                    z-index: 10;
                    bottom: 0;
                    top: auto;
                    transform: translateY(0);
                    font-size: 28px;
                    line-height: 56px;
                }
            }
        }

        .btn {
            width: 100%;
            max-width: 43rem;
        }
    }
}
