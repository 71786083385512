.tissot-ajax-loading {
    &:before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.7);
        z-index: 100;
        opacity: 1;
    }

    &:after {
        content: "";
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 20vh;
        height: 20vh;
        background: url(../images/loader/loader.gif) no-repeat center center;
        background-size: cover;
        opacity: 1;
        z-index: 101;
    }
}

body:after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: opacity $transition-fast;
}

body:before {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    right: 100%;
    bottom: 100%;
    overflow: hidden;
    opacity: 0;
    transition: opacity $transition-fast;
}

.loading-mask {
    .loader {
        img {
            width: 110px;
        }
    }
}
