.paypal-express-review,
.onestepcheckout-index-index {
    .footer {
        .wide_contentsystem_summary {
            padding: 16px;
        }
    }
}

.paypal-express-review,
.checkout-cart-index,
.onestepcheckout-index-index {
    .footer {
        padding: 0;


        .wide_contentsystem_summary {

            .footer-top {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;

                p {
                    width: 100%;
                    text-align: left;
                    text-transform: uppercase;
                    font-weight: 500;
                    font-size: 16px;;
                    letter-spacing: 1px;
                    margin-bottom: 20px;

                    @include min-screen($screen__m) {
                        text-align: center;
                    }
                }

                .phone-number-container {
                    width: 50%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    align-items: center;

                    @include min-screen($screen__m) {
                        max-width: 300px;
                        justify-content: center;
                    }

                    .phone-number {
                        width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        font-weight: 500;
                        font-size: 16px;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        order: 2;

                        @include min-screen($screen__m) {
                            order: 1;
                            justify-content: center;
                        }

                        .icon {
                            margin-right: 6px;
                            width: 15px;
                        }
                    }

                    .schedule {
                        font-style: normal;
                        font-weight: normal;
                        color: $light-grey;
                        font-size: 14px;
                        text-align: left;
                        order: 1;

                        @include min-screen($screen__m) {
                            text-align: center;
                            order: 2;
                        }
                    }
                }
            }
        }
    }

    .page-content {
        @include min-screen($screen__m) {
            min-height: calc(100vh - 150px - 150px - 70px); //footer-top, logo, heder
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .page-title h1 {
        @extend .title-h1;
        margin-bottom: 30px;

        @include min-screen($screen__m) {
            margin-bottom: 60px;
        }
    }

    .cart-empty {
        p {
            @extend .text-default;
        }

        a {
            text-decoration: underline;

            &:hover {
                color: $primary;
            }
        }
    }

    .btns-top {
        max-width: calc(100% + 30px);
        flex-flow: column;
        margin-left: -15px;
        margin-right: -15px;

        @include min-screen($screen__m) {
            flex-flow: row;
            margin-left: 0;
            margin-right: 0;
            max-width: 100%;
        }

        & > div:first-child {
            text-align: left;
        }

        & > div:last-child {
            text-align: right;
        }

        .btn {
            width: 100%;

            @include min-screen($screen__m) {
                width: auto;
            }
        }
    }

    .collection-header {
        @include min-screen($screen__m) {
            margin-top: 60px;
        }
    }

    #billing_address_list > .field .input-box {
        @extend .select-group;
    }

    #billing_address_list label {
        z-index: 3;
    }

    .checkbox-group label p a {
        color: $dark-grey;

        &:hover {
            color: $primary;
            text-decoration: underline;
        }
    }
}

.cart-container {
    margin: 0 -30px;

    @include min-screen($screen__m) {
        margin: 30px auto;
    }
}

.cart-table {
    .product-title {
        @extend .title-h4;
    }

    .product-qty {
        display: inline-flex;

        input[type="text"] {
            height: auto;
            width: auto;
        }
    }
}

.cart-totals {
    @include max-screen($screen__m) {
        margin-left: 15px;
        margin-right: 15px;
    }

    // @include min-screen($screen__m) {
    //     margin-left: 0;
    //     margin-right: 0;
    //     width: 100%;
    // }

    .total-global {
        font-size: 2rem;
        font-weight: bold;

        @include min-screen($screen__m) {
            font-size: 3rem;
        }
    }
}

table.cart-table tr > td:last-child,
table.cart-table tr > th:last-child {
    padding-right: 0;
}

table.cart-table tr > td:first-child,
table.cart-table tr > th:first-child {
    padding-left: 15px;

    @include min-screen($screen__m) {
        padding-left: 0;
    }
}

.cart-advantage {
    padding: 0;

    @include min-screen($screen__m) {
        padding-left: 15px;
        padding-right: 15px;
    }

    .form-group {
        width: auto;
        display: flex;
        flex-flow: column;

        @include min-screen($screen__m) {
            flex-flow: row;
        }
    }

    .form-code label {
        font-weight: 300;
    }

    .form-code .form-input {
        float: none;
        width: 100%;
        margin: 0 0 20px;

        @include min-screen($screen__m) {
            width: 300px;
        }
    }

    .form-buttons .btn {
        margin-left: 0;
        margin-bottom: 30px;

        @include min-screen($screen__m) {
            margin-left: 20px;
            margin-bottom: 0;
        }
    }

    input[type="text"] {
        height: 42px;
    }
}

.cart-actions {
    padding: 0;

    @include min-screen($screen__m) {
        padding: 40px 0;
    }

    .btn {
        padding: 12px 30px;
        width: 100%;

        @include min-screen($screen__m) {
            width: auto;
        }
    }

    & > div:last-child {
        @include min-screen($screen__m) {
            text-align: right;
            padding-right: 0;
        }
    }

    .list-payment .list-payment-title {
        font-weight: 600;
    }
}

// inputs + labels
.paypal-express-review,
.onestepcheckout-index-index {
    .form-group,
    .input-box:not(.checkbox-group),
    .field {
        position: relative;

        label {
            position: absolute;
            z-index: 2;
            top: -7px;
            left: 15px;
            font-size: 1rem;
            text-transform: uppercase;
            color: $black;
            background: $white;
            padding: 0 5px;
            font-weight: 300;
            line-height: 1em;

            &.zipcode-jp {
                position: relative;
            }
        }

        br {
            display: none;
        }
    }

    .form-control {
        box-shadow: none;

        &:focus {
            border: 1px solid $border-color;
        }
    }
}

// radio
.radio-choice .icon-checkbox,
.radio-choice .checkbox-group label .icon-checkbox {
    display: none;
}

.form-checkout-contain input[type="radio"] {
    border: 1px solid $border-color;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 1px;
    left: 0;

    &:checked {
        border-color: $primary;
    }

    &:checked::before {
        width: 8px;
        height: 8px;
        background: $primary;
        top: 3px;
        left: 3px;
    }
}

//onestep checkout

.header-checkout {
    border-bottom: 1px solid $border-color;
    padding: 25px;

    img {
        width: 158px;
    }
}

.form-checkout-contain .input-box {
    margin-bottom: 40px;
}

.onestepcheckout-coupons {
    position: relative;

    label {
        position: absolute;
        z-index: 2;
        top: -7px;
        left: 15px;
        font-size: 1rem;
        text-transform: uppercase;
        color: $black;
        background: $white;
        padding: 0 5px;
        font-weight: 300 !important;
        line-height: 1em;
    }

    & > div {
        padding: 0;
    }

    input#id_couponcode {
        height: 42px;
    }

    .error-msg {
        position: absolute;
        bottom: 28px;
        left: 0;

        &#coupon-notice {
            position: inherit;
            bottom: 0;
        }
    }

    .col-xs-6 {
        width: 100%;

        @include min-screen($screen__m) {
            width: 50%;
        }
    }

    .btn-container {
        padding-left: 0;
        margin-top: 20px;

        @include min-screen($screen__m) {
            padding-left: 20px;
            margin-top: 0;
        }

        .btn {
            width: 100%;

            @include min-screen($screen__m) {
                width: auto;
            }
        }
    }
}

.input-telephone .icon-container {
    display: none;
}

.form-checkout-contain .checkout-choice {
    border: 0;
}

.form-checkout-contain .chevron {
    display: none;
}

.payment-method fieldset {
    border: 0;
    padding: 0;
    margin: 0;
}

.payment-method ul {
    padding: 0;
    margin: 0;
}

.payment-methods dd {
    padding-left: 25px;
}

.payment-methods dt {
    position: relative;
    padding-left: 25px;

    input[type="radio"] {
        top: 3px !important;
    }
}

.payment-methods .validation-advice {
    position: static;
    font-weight: 300;
    padding: 0 !important;
}

.contain-checkout .validation-advice {
    position: static;
    padding: 5px 0 0 20px;
}

.shipment-methods {
    .validation-advice {
        position: static;
    }

    .checkbox-group label {
        text-transform: none;
    }
}

.shipment-methods .validation-advice,
.checkout-agreements .validation-advice {
    position: static;
    padding: 0 !important;
}

div.onestepcheckout-place-order-wrapper {
    text-align: left;

    .btn {
        width: 100%;

        @include min-screen($screen__m) {
            width: auto;
        }
    }

    #onestepcheckout-place-order {
        width: 100%;
        font-size: 1.2rem;
        margin-top: 40px;
        padding: 12px 30px;
        height: auto;

        @include min-screen($screen__m) {
            width: auto;
        }
    }
}

.info-checkout-container h1 {
    @extend .title-h1;
}

.checkout-agreements label {
    padding-right: 0;
    width: auto;

    @include min-screen($screen__m) {
        padding-right: 30px;
        width: calc(100% - 35px);
    }
}

.checkout-footer-info {
    width: auto;

    @include min-screen($screen__m) {
        width: 70%;
    }
}

.onestepcheckout-totals .grand_total td {
    font-size: 2rem;

    @include min-screen($screen__m) {
        font-size: 3rem;
    }
}

//modals
.onestepcheckout-index-index {
    .action-close {
        opacity: 1;
    }

    #user-connect .close {
        font-size: 1rem;
    }

    .modal-popup .modal-title {
        border-bottom: 0;
        padding-bottom: 0;

        @extend .title-h4;
    }

    .modal-header {
        border: 0;
    }

    .modal-footer {
        display: none;
    }

    #sgecomupslocation-modal .modal-body {
        padding-left: 0;
    }

    #sgecomupslocation-request-submit p {
        margin: 0;
    }

    #sgecomupslocation-request-choose {
        margin-bottom: 0;
    }

    #sgecomupslocation-request-submit {
        padding-left: 0;
        padding-right: 0;
    }

    .modal-content .login-container {
        .validation-advice {
            position: static;
        }
    }

    .modal-user-header {
        @extend .title-h4;
        margin-bottom: 30px;
    }

    #onestepcheckout-login-form,
    #onestepcheckout-forgotpassword-form {
        label.text-uppercase {
            font-weight: 300;
            font-size: 1rem;
            margin-bottom: 0;
            background: $white;
            margin-left: 15px;
            color: $black;
            padding: 0 5px;
            position: relative;
            z-index: 2;

            & + .form-control {
                margin-top: -9px;
            }
        }

        .btn-user {
            width: auto;
            margin: 40px 20px 0 0;
        }

        p {
            margin-top: 10px;
            color: $dark-grey;
        }

        .modal-user-header + p {
            @extend .text-default;
            margin-top: 0;
        }

        #onestepcheckout-login-error,
        #onestepcheckout-forgot-error,
        #onestepcheckout-forgot-success {
            display: inline;

            & + a {
                font-size: 1.4rem;
                display: inline-block;
                margin-top: 40px;
                color: $dark-grey;
                text-decoration: none;
                border-bottom: 1px solid $dark-grey;
                vertical-align: middle;

                &:hover {
                    color: $primary;
                    border-color: $primary;
                }
            }
        }

        .validation-advice {
            position: static;
            margin-top: 2px;
        }
    }

    #user-connect .modal-content {
        @include max-screen($screen__m) {
            width: 100%;
        }

        .modal-body {
            @include max-screen($screen__m) {
                padding: 0;
            }
        }
    }
}

// header sticky
.onestepcheckout-index-index {
    .header-checkout {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 10;
        background: $black;
        padding: 20px;

        @include min-screen($screen__xl) {
            padding: 25px;
        }
    }

    .page {
        padding-top: 60px;

        @include min-screen($screen__xl) {
            padding-top: 70px;
        }
    }
}

/**
 * Checkout US
 */
.paypal-express-review,
.checkout-index-index,
.checkout-cart-index {
    .message-success, .message-error {
        text-align: center;
    }

    .modal-popup.modal-slide._inner-scroll {
        -webkit-overflow-scrolling: auto;
    }


    .footer {
        .footer-copyright {
            margin-top: 11px !important;
            color: $white;
            font-size: 8px;
            border: none;
            padding-top: 0;
        }

        .footer-menu.footer-list {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;

            li {
                margin: 10px 13px;
                padding: 0;
                text-align: center;
                width: auto;
                text-transform: none;

                svg.icon {
                    fill: $primary !important;
                }

                .footer-label {
                    color: $white;
                }

                a {
                    font-size: 12px;
                }
            }
        }

        .footer-payment-methods {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: center;
            padding: 1rem 0;
            text-transform: uppercase;
            color: $white;

            &__label,
            &__image {
                margin: 1rem 3px;
            }

            &__image {
                height: 34px;

                img {
                    height: 100%;
                    width: auto;
                }
            }
        }

        .footer-bottom {
            .content-system--html {
                padding-top: 12px;
            }

            .wide_contentsystem_summary:nth-child(3) {
                text-align: center;

                .content-system--html {
                    display: inline-block;
                }
            }

            .wide_contentsystem_summary:nth-child(2),
            .wide_contentsystem_summary:nth-child(3) {
                .content-system--html {
                    border-top: 1px solid $white;
                }
            }

            @include min-screen($screen__l) {
                .wide_contentsystem_summary:nth-child(2) {
                    .content-system--html {
                        border-top: 0;
                    }
                }
            }
        }

    }
}


.paypal-express-review,
.checkout-index-index {
    --header-height: 70px;
    --sidebar-width: 363px;
    --mobile-cart-button-height: 50px;
    --logo-height: 20px;

    @include max-screen($screen__m) {
        --header-height: 60px;
    }


    .loading-mask {
        .loader {
            img {
                width: 110px;
                height: 110px;
            }
        }
    }

    ._block-content-loading {
        z-index: 5;
    }

    .header {
        padding: 0 15px;
        height: var(--header-height);
        background: $white;
        border-bottom: 1px solid #e6e6e6;
        z-index: 999;

        .logo-block {
            height: 100%;
            float: left;
            padding: 0;
            margin: 0;
            position: unset;
            transform: unset;
            top: unset;
            left: unset;

            .logo {
                --padding-top-bottom: calc((var(--header-height) - var(--logo-height)) / 2);
                padding-top: var(--padding-top-bottom);
                padding-bottom: var(--padding-top-bottom);
                padding-left: calc(var(--padding-top-bottom) - 15px);
                height: var(--logo-height);
            }
        }

        .checkout-header__menu {
            float: right;
            height: 100%;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            .menu__label {
                font-size: 16px;
                font-weight: bold;
                text-transform: uppercase;
                margin-right: 15px;
                color: $primary;

                @include max-screen($screen__m) {
                    display: none;
                }
            }

            .menu__phone-number-wrapper {
                margin: 0 5px;
                color: $primary;

                .phone-number {
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;

                    svg {
                        margin-right: 6px;
                        fill: $primary;
                    }

                    span {
                        font-size: 16px;
                        font-weight: bold;
                    }
                }

                .schedule {
                    font-size: 11px;
                    float: right;
                }
            }
        }
    }

    .page-main {
        padding-top: var(--header-height);

        @include max-screen($screen__m) {
            padding-top: calc(var(--header-height) + var(--mobile-cart-button-height));
        }
    }

    .columns {
        margin-left: auto;
        margin-right: auto;
        padding: 25px 15px;

        @include max-screen($screen__m) {
            padding: 0 15px;
        }

        @include min-screen($screen__xl) {
            max-width: $screen__xl;
        }

        @include screen($screen__l, $screen__xl) {
            max-width: $screen__l;
        }

        @include screen($screen__m, $screen__l) {
            max-width: $screen__m;
        }
    }

    .footer {
        &-bottom {
            border-top: 1px solid $dark-grey;
        }

        &-list {
            padding: 1rem 0;
        }

        &-label {
            color: $white;
        }

        &-links {
            ul {
                width: 100%;
                display: flex;
                flex-flow: row wrap;
                justify-content: center;
            }

            a {
                position: relative;
                display: inline-block;
                font-size: 1.1rem;

                &:hover:after {
                    width: 100%;
                }

                &:after {
                    position: absolute;
                    bottom: -5px;
                    display: block;
                    content: "";
                    width: 0;
                    height: 1px;
                    background-color: white;
                    transition: width 0.3s;
                }
            }
        }

        .icon {
            fill: $white;
        }
    }

    .checkout-container {
        .opc-progress-bar {
            max-width: 684px;
        }

        .opc-wrapper,
        .opc-sidebar {
            margin: 40px 0 20px;
        }

        // fixing forms at once
        .form-create-account,
        .form-login,
        #discount-form {
            .form-group,
            .field,
            .field .control {
                width: 100%;
            }


            .btn {
                max-height: 56px;
            }
        }

        @include min-screen($screen__l) {
            .opc-wrapper {
                float: left;
            }

            .opc-sidebar {
                float: right;
            }
        }

        .opc-wrapper {
            width: 100%;

            #account {
                width: 100%;
            }

            @include min-screen($screen__m) {
                width: calc(100% - var(--sidebar-width));
                #account {
                    max-width: 500px;
                }
            }

            .step-title {
                @extend .title-h1;
                color: $dark-grey;
                border: none;

                @include max-screen($screen__m) {
                    padding-bottom: 12px;
                }
            }

            #checkout-step-shipping {
                padding: 0 0 40px 0;
            }

            .step-content {
                padding: 40px 0;
                margin: 0;

                .text-info {
                    @extend .text-default;
                }

                .form-login {
                    margin-bottom: 0;
                }

                .fieldset.login {
                    margin: 0;
                }

                // step 1
                .form-login-guest,
                .form-login {
                    padding: 0;

                    .forgot-password {
                        text-decoration: underline;
                    }

                    .actions-toolbar {
                        margin-left: 0;

                        .primary {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .opc-sidebar {
            width: var(--sidebar-width);

            & + .modals-overlay {
                display: none;
            }

            .title-summary {
                margin: 0 0 19px;
                color: $dark-grey;

                @extend .title-h2;

                @include max-screen($screen__m) {
                    display: none;
                }
            }

            @include max-screen($screen__m) {
                position: fixed;
                top: calc(var(--mobile-cart-button-height) + var(--header-height));
                left: 0;
                height: calc(100vh - (var(--mobile-cart-button-height) + var(--header-height)));
                width: 100vw;
                overflow-y: scroll;
                margin: 0;
            }

            .opc-block-summary {
                padding: 15px;
                position: relative;

                .minicart-items-wrapper {
                    max-height: none;
                    padding: 0;
                    max-width: 100% !important;
                    overflow-x: hidden;
                }

                .title {
                    @extend .title-emphasis;
                    font-size: 18px;
                    font-weight: 500;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    border-bottom: none;
                    padding-bottom: 29px;

                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-start;

                    h3 {
                        @extend .title-h3;
                        margin: 0;
                        font-size: 18px;
                        font-weight: 500;
                    }


                    .action {
                        font-size: 12px;
                        line-height: 12px;
                        letter-spacing: 2px;
                        font-weight: normal;
                        position: relative;

                        span {
                            padding-left: 17px;
                        }

                        .icon {
                            position: absolute;
                            width: 12px;
                            height: 12px;
                            left: 0;
                            bottom: 0;
                            transform: translateY(-25%);
                        }
                    }
                }

                .items-in-cart {
                    margin-bottom: 20px;
                }

                .product-item {
                    &:last-child {
                        padding-bottom: 0;
                    }

                    &-name-block strong {
                        font-size: 14px;
                        text-transform: uppercase;
                        letter-spacing: 2px;
                    }

                    &-inner.details-qty {
                        margin-top: 19px;

                        .label,
                        .value,
                        .price {
                            font-weight: bold;
                        }
                    }

                    .technical-spec {
                        .content {
                            padding: 15px 0;

                            table {
                                td,
                                th {
                                    text-transform: uppercase;
                                    letter-spacing: 2px;
                                    font-size: 8px;
                                }

                                thead {
                                    th {
                                        padding: 0 0 0 10px;
                                    }
                                }

                                tbody {
                                    tr {
                                        :first-child {
                                            padding: 0 0 0 10px;
                                        }

                                        td:last-child {
                                            padding: 0;
                                            text-align: right;
                                        }
                                    }
                                }

                                &:not(last-child) {
                                    margin-bottom: 20px;
                                }
                            }
                        }

                        .toggle {
                            text-transform: uppercase;
                            color: $dark-grey;
                            letter-spacing: 2px;
                            font-size: 12px;
                            padding-bottom: 10px;
                            border-bottom: 1px solid rgba(black, 0.2);

                            &::after {
                                float: right;
                                width: 20px;
                            }
                        }
                    }
                }

                .warranty-message {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 20px;
                    background-color: #ededed;

                    .icon {
                        margin: 0 5px;
                    }
                }

                .opc-payment-additional.discount-code {
                    #apply-discount {
                        margin: 20px 0;

                        .form-discount {
                            display: flex;
                            flex-flow: row nowrap;
                            white-space: nowrap;
                            text-overflow: ellipsis;

                            .fieldset {
                                margin: 0;
                                flex-grow: 1;
                            }

                            button {
                                width: auto;
                                padding: 0 5px !important;
                            }
                        }

                        .discount-message {
                            padding-left: 21px;

                            span {
                                font-size: 1rem;
                                letter-spacing: 0.75px;
                                text-transform: uppercase;
                                font-weight: normal;

                                &.discount-value {
                                    font-weight: bold;
                                }
                            }

                            .message {
                                padding: 0;
                                padding-top: 10px;
                                background: none;
                                font-size: 1rem;
                                letter-spacing: 0.75px;
                                text-transform: uppercase;
                                font-weight: normal;

                                > div {
                                    &::before {
                                        display: none;
                                    }
                                }
                            }

                        }
                    }

                    .i-have-a-promo-code {
                        span {
                            font-size: 1.6rem;
                            text-transform: none;
                        }
                    }
                }

                .gift-message-container {
                    .checkbox-group {
                        .this-is-a-gift {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            align-items: center;

                            &__text,
                            &__edit {
                                .icon {
                                    position: absolute;
                                }


                                span {
                                    font-size: 1.6rem;
                                    margin-left: 25px;
                                    text-transform: none;
                                }
                            }

                            &__text {
                                .icon {
                                    top: 1px;
                                }

                                flex-grow: 1;
                            }

                            &__edit {
                                line-height: 12px;

                                .icon {
                                    //top: 2px;
                                }

                                svg {
                                    width: 12px;
                                    height: 12px;
                                }

                                span {
                                    letter-spacing: 2px;
                                    color: $dark-grey;
                                    font-size: 1.2rem;
                                    margin-left: 15px;
                                }
                            }
                        }
                    }

                    .gift-message-from {
                        color: $light-grey;
                        font-size: 1.6rem;

                        &:before {
                            content: "— ";
                        }
                    }
                }

                table.table-totals {
                    tbody {
                        tr:first-child,
                        tr:last-child {
                            th,
                            td {
                                padding-top: 20px;
                            }
                        }

                        th,
                        td,
                        tr {
                            padding: 0;
                            padding-left: 0 !important;
                            padding-right: 0 !important;
                        }

                        .tax-not-calculated {
                            line-height: 1rem;

                            td {
                                padding-bottom: 5px;
                            }

                            span {
                                font-size: 1rem;
                                font-weight: normal;
                                letter-spacing: 0.75px;
                                text-transform: uppercase;
                                color: rgba(0, 0, 0, 0.87);
                                mix-blend-mode: normal;
                                opacity: 0.6;

                            }
                        }
                    }
                }

                .actions-toolbar {
                    padding-bottom: 10px;

                    .viewcart {
                        .icon {
                            padding-top: 3px;
                            height: 15px;
                        }

                        span {
                            text-transform: uppercase;
                            letter-spacing: 2px;
                            color: $dark-grey;
                        }
                    }
                }

                .shipping-information-edit{
                    min-width: 20%;
                    text-align: center;
                    display: grid;
                }
            }
        }

        &::after {
            clear: both;
        }
    }

    // wtf is this
    .authentication-wrapper {
        display: none !important;
    }

    // mobile cart
    .opc-estimated-wrapper {
        position: fixed;
        top: var(--header-height);
        height: var(--mobile-cart-button-height);
        border-bottom: 1px solid $border-color !important;
        left: 0;
        right: 0;
        padding: 0;
        margin: 0;
        border-bottom: unset;
        z-index: 900;

        &.-active {
            .estimated-block {
                background: $black;
            }

            .estimated-price,
            .estimated-label {
                svg {
                    fill: $white;
                }

                span {
                    color: $white;
                }
            }

            .estimated-price .icon {
                transform: rotate(180deg);
            }
        }

        .estimated-block {
            appearance: none;
            height: 100%;
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            padding: 0 15px;
            background: $lighter-grey;
            transition: all $transition-fast;

            .estimated-price,
            .estimated-label {
                margin: 0;
                display: flex;
                flex-flow: row nowrap;
                align-items: center;

                .icon {
                    margin-right: 6px;
                    transition: all $transition-fast;
                }

                span {
                    font-weight: normal;
                    text-transform: uppercase;
                }
            }
        }
    }

    // Progress stepper
    .opc-custom-progress-bar {
        --steps-height: 47px;
        --offset: 1px;
        --steps-border-width: calc(var(--offset) + var(--steps-height) / 2);
        --arrow-length: 15px;
        margin-bottom: 15px;

        display: flex;
        flex-flow: row wrap;
        height: var(--steps-height);

        @include max-screen($screen__m) {
            margin: 0 -15px;
            top: calc(var(--mobile-cart-button-height) + var(--header-height));
        }

        @include min-screen($screen__l) {
            max-width: 684px;
        }

        &-item {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            position: relative;
            text-align: center;
            flex: 1 0 0;
            border-bottom: 1px solid $light-grey2;
            overflow: hidden;
            background-color: white;
            padding: 0 1rem;

            @include min-screen($screen__m) {
                border-top: 1px solid $light-grey2;
            }

            .icon {
                margin-right: 10px;
                margin-bottom: 2px;
                fill: $light-grey;
            }

            span {
                @extend .title-emphasis;
                font-size: 12px;
                color: $light-grey;
            }

            &._active {
                border-bottom: 1px solid $primary;

                .icon {
                    fill: $primary;
                }

                span {
                    color: $black;
                }
            }

            &._complete {
                background: $lighter-grey;
                cursor: pointer;

                & + ::after {
                    border-color: transparent transparent transparent $lighter-grey !important;
                }
            }

            &:first-child {
                border-left: 1px solid $light-grey2;
            }

            &:last-child {
                border-right: 1px solid $light-grey2;
            }

            &:not(:first-child) {
                padding-left: calc(1rem + var(--arrow-length));

                &::before,
                &::after {
                    content: "";
                    border-style: solid;
                    border-width: var(--steps-border-width) 0 var(--steps-border-width) var(--arrow-length);
                    position: absolute;
                    top: -1px;
                    transform: translateY(calc(var(--offset) * -1));
                }

                &::before {
                    border-color: transparent transparent transparent $light-grey2;
                    left: 0;
                }

                &::after {
                    left: -1px;
                    border-color: transparent transparent transparent white;
                }
            }
        }
    }
}

//checkout-us
.paypal-express-review,
.checkout-index-index {
    form.form.methods-shipping {
        div.pickup-time-delivery {
            @include min-screen($screen__m) {
                display: flex;
            }

            div.field.form-group {
                width: 100%;

                @include min-screen($screen__m) {
                    width: calc(50% - 5px);
                }
            }
        }
    }

    .table-checkout-shipping-method {
        min-width: unset;
    }

    #als_pcc.affirm-as-low-as {
        display: none;
    }

    #shipping-postcode {
        width: 100%;
        max-width: 330px;

        > span {
            font-size: 18px;
            line-height: 30px;
            font-weight: 300;
            margin-bottom: 18px;
            display: flex;
        }

        #postcode-verify {
            margin: 0;
        }

        form.form {
            display: flex;
            flex-direction: column;
            width: 100%;

            .field.form-group {
                width: 100%;
                margin-bottom: 5px;

                label {
                    font-weight: normal;
                    text-transform: none;
                }

                > div {
                    display: flex;

                    button {
                        display: flex;
                        padding-left: 0;
                        padding-right: 0;
                        min-width: 74px;
                        max-width: 74px;
                        align-items: center;

                        span {
                            text-align: center;
                            width: 100%;
                        }
                    }
                }
            }
        }

        .shipping-info {
            font-size: 10px;
            line-height: 15px;
            letter-spacing: 0.75px;
            text-transform: uppercase;
        }
    }

    form.form-shipping-address {
        width: 100%;
    }

    #shipping-new-address-form {
        display: flex;
        flex-wrap: wrap;
    }

    // #checkout {
    .message.warning {
        background-color: $orange-warning;
        padding: 20px;
        margin-bottom: 38px;
        display: flex;
        flex-direction: column;

        br {
            display: none;
        }

        span {
            color: $dark-grey;
            font-size: 16px;
            line-height: 18px;

            &:first-child {
                margin-bottom: 12px;
            }
        }

        & > *:first-child:before {
            display: none;
        }
    }

    .shipping-method-item {
        cursor: pointer;
        display: flex;
        flex-wrap: nowrap;
        font-size: 16px;
        padding: 20px 0;
        border-top: 1px solid $light-grey2;
        border-bottom: 1px solid $light-grey2;

        &:focus-within {
            outline: 1px dashed $primary;
            outline-offset: 5px;
        }

        &:first-child {
            border-bottom: 0;
        }

        .method {
            &-code {
                width: 20px;
            }

            &-price {
                width: 100px;
                text-align: right;
                line-height: 23px;
                letter-spacing: 2px;
                font-weight: 500;
            }

            &-details {
                margin-left: 32px;
                flex-grow: 1;
            }

            &-carrier {
                text-transform: uppercase;
                font-weight: 500;
                line-height: 23px;
            }

            &-estimation-time {
                line-height: 18px;
            }

            &-infos {
                margin-top: 16px;
                font-size: 14px;
                line-height: 16px;
                color: black;

                p {
                    margin-bottom: 0;
                }
            }
        }
    }

    #co-shipping-form {
        width: 100%;
        max-width: 100%;

        #shipping-new-address-form {
            padding-top: 10px;
            display: flex;
            justify-content: space-between;

            fieldset.street {
                width: 100%;
                margin: 0;

                .control {
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    .field {
                        width: 100%;
                        margin-left: 0;
                        margin-top: 0;
                    }
                }
            }

            fieldset.phone_number-container {
                width: 100%;
                margin: 0;

                .control {
                    width: 100%;
                    display: flex;
                    flex-direction: row;

                    .field {
                        width: calc(50% - 5px);
                        margin: 0 0 38px 0;

                        &:first-of-type {
                            margin-right: 10px;
                        }

                        .control {
                            flex-direction: column;
                        }
                    }
                }
            }

            .field .control {
                width: 100%;
            }

            .field {
                margin-bottom: 38px;
            }

            .field[name="shippingAddress.telephone"] {
                width: 100%;
            }

            @include min-screen($screen__m) {
                .form-group {
                    width: calc(50% - 5px);

                    &.fieldset-jp-postcode {
                        width: 100%;
                    }
                }
            }

            .form-group {
                &.fieldset-jp-postcode {
                    width: 100%;

                    .control {
                        .zipcode-generator {
                            display: flex;
                            flex-wrap: wrap;

                            .zip-container {
                                width: calc(25% - 2.5px);
                                display: flex;
                                align-items: center;

                                @include max-screen($screen__m) {
                                    width: 50%;
                                }

                                span {
                                    text-align: center;
                                    width: 20%;
                                }

                                .field.form-group {
                                    width: 100%;
                                    margin: 0;
                                }

                                .validation-advice {
                                    padding: 0 0 0 5px;
                                }
                            }

                            .calculate-city {
                                flex-grow: 1;
                                margin-left: 10px;

                                @include max-screen($screen__m) {
                                    margin-top: 15px;
                                    margin-left: 0;
                                }
                            }
                        }
                    }
                }
            }

            .field-error {
                //display: none; //problem with the label/animation otherwise
            }

            .field.checkbox-group label.label {
                width: 100%;
                padding-top: 0;
                text-align: left;
                padding-left: 30px;
            }

            div[name="shippingAddress.prefix"] {
                width: 100%;
            }
        }
    }

    // }

    .new-address-popup {
        button {
            border: 0;
            background: 0;
            color: $primary;
            letter-spacing: 2;
            text-transform: uppercase;
            font-size: 12px;
            line-height: 16px;
        }
    }

    .shipping-address-items {
        display: flex;
        flex-direction: column;

        .shipping-address-item {
            cursor: pointer;
            width: 100%;
            display: flex;
            border: 0;
            margin: 0;
            padding: 20px 0px;
            border-top: 1px solid #eae8e7;
            border-bottom: 1px solid #eae8e7;
            font-size: 16px;
            line-height: 18px;

            &:focus-within {
                outline: 1px dashed $primary;
                outline-offset: 5px;
            }

            &:first-child {
                border-bottom: 0;
            }

            &:before {
                display: none;
            }

            &-code {
                width: 20px;
            }

            &-details {
                margin-left: 32px;
                flex-grow: 1;
            }

            &-name {
                text-transform: uppercase;
                font-weight: 500;
                line-height: 23px;
            }

            &-actions {
                button {
                    margin-top: 0;
                }
            }
        }
    }

    .modal-title {
        margin-bottom: 20px;
    }

    .checkout-shipping-address .actions-toolbar .btn {
        width: 100%;
        @include min-screen($screen__m) {
            width: 320px;
        }
    }

    //amazon pay
    .amazon-widget-container {
        width: 100%;
        min-height: 400px;

        .widget-container {
            z-index: 1;
            width: 100%;
            min-height: 400px;
        }
    }

    .amazon-sandbox-simulator {
        border: 2px solid blue;
        background-color: rgba(0, 0, 250, 0.1);
        margin-bottom: 100px;
        padding: 20px;

        &:before {
            content: "This is for development purposes";
        }
    }

    .amazon-payment-method {
        .payment-method-title {
            display: none;
        }

        .payment-method-content {
            padding-left: 0;

            .actions-toolbar {
                margin-left: 0;

                .primary {
                    width: 100%;
                }

                .btn {
                    width: 100%;
                    margin-top: 20px;
                }
            }

            .checkout-agreements {
                .checkout-agreement {
                    margin-top: 5px;
                    display: flex;
                    justify-content: center;

                    button {
                        color: black;
                        text-decoration: underline;
                        font-size: 14px;
                        line-height: 21px;
                        text-align: center;
                    }
                }
            }
        }
    }
}

.cart-page {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    div.checkout-btn-container {
        display: none;

        @include max-screen($screen__m) {
            display: block;
            width: 100%;
            padding: 15px;
            margin-bottom: 15px;
            border-bottom: 1px solid #eae8e7;

            button {
                width: 100%;
                padding: 12px 30px;
            }
        }
    }

    .info-block-mobile {
        width: 100%;
        display: flex;
        @include min-screen($screen__l) {
            display: none;
        }
    }

    .info-block-desktop {
        width: 100%;
        display: none;
        @include min-screen($screen__l) {
            display: flex;
        }
    }

    .form.form-cart {
        width: 100%;
    }

    h1.page-title {
        width: 100%;
        text-align: left;
        text-transform: uppercase;
        font-size: 30px;
        line-height: 35px;
        letter-spacing: 1.5px;
        margin-left: 18px;
        margin-right: 18px;
        margin-top: 40px;
        font-family: $font-family-headings;

        @include min-screen($screen__l) {
            text-align: center;
            font-size: 45px;
            line-height: 50px;
            margin-left: 0;
            margin-right: 0;
        }
    }

    .cart-empty {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 18px 18px;
        max-width: 800px;

        .no-items-message {
            height: 56px;
            background-color: #f6f6f6;
            text-align: center;
            line-height: 56px;
            margin-bottom: 20px;
        }
    }

    .cart-container {
        margin-left: 18px;
        margin-right: 18px;

        display: flex;
        flex-direction: column;
        max-width: 100%;


        @include min-screen($screen__l) {
            margin-left: 30px;
            margin-right: 30px;

            flex-direction: row;
        }

        form > div.cart {
            padding-left: 4px;
            padding-right: 4px;
        }

        .cart.item {
            margin-bottom: 30px;
            display: flex;
            flex-wrap: wrap;
            border: 0;

            .product-item-photo-container {
                width: 74px;
                height: 74px;
                @include min-screen($screen__l) {
                    width: 211px;
                    height: 276px;
                }
                background-color: #f6f6f6;

                > a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    padding: 0;

                    .product-image-container {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .product-image-wrapper,
            .product-image-photo {
                width: 100%;
            }

            .product-image-container {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .product-item-name > a {
                font-size: 14px;
                font-weight: bold;
                line-height: 21px;
                text-transform: uppercase;

                @include min-screen($screen__l) {
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 24px;
                }
            }

            .product-item-details {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-content: flex-start;
                flex-wrap: wrap;
                padding-left: 16px;
                width: 1%;
                flex-grow: 1;
                @include min-screen($screen__l) {
                    padding-left: 33px;
                }

                .product-item-name {
                    width: 80%;
                    margin: 0;
                    padding-bottom: 4px;
                }

                .item-actions {
                    min-width: 32px;
                    width: 20%;
                    display: flex;
                    justify-content: flex-end;
                }

                .product-item-sku {
                    color: #4F4F4F;
                    width: 100%;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 2px;
                    text-transform: uppercase;

                    @include min-screen($screen__l) {
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: 0.7333px;
                    }
                }

                .quantity {
                    margin-top: 22px;
                    width: 50%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .field {
                        .control {
                            label {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                position: relative;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                border: 1px solid #c4c4c4;

                                a {
                                    font-weight: 600;
                                    padding-left: 2px;
                                    padding-right: 2px;
                                    font-size: 18px;
                                    line-height: 18px;
                                }

                                input {
                                    width: 50px;
                                    text-align: center;
                                    font-size: 20px;
                                    border: 0;
                                }
                            }
                        }
                    }
                }

                .subtotal {
                    margin-top: 22px;
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    height: 60px;

                    .price {
                        font-size: 14px;
                        line-height: 21px;
                        letter-spacing: 2px;
                        font-weight: bold;
                        @include min-screen($screen__l) {
                            font-size: 18px;
                            line-height: 24px;
                            letter-spacing: 1px;
                            font-weight: 500;
                        }
                    }

                    @include min-screen($screen__l) {
                        width: 50%;
                    }

                    .cart-price {
                        padding-left: 4px;
                        padding-right: 4px;
                    }
                }
            }
        }

        .cart-summary {
            min-width: 360px;
            order: 2;
            background-color: white;
            margin-top: 28px;
            width: 100%;
            padding-left: 4px;
            padding-right: 4px;
            display: flex;
            flex-direction: column;


            @include min-screen($screen__l) {
                order: 1;
                max-width: 360px;
                background-color: #f5f5f5;
                padding-left: 20px;
                padding-right: 20px;
                margin-top: 0;
            }

            .summary-title {
                display: none;
                color: $black;
                text-transform: uppercase;
                font-weight: normal;
                font-style: normal;
                font-size: 25px;

                @include min-screen($screen__l) {
                    display: block;
                }
            }

            .cart-totals {
                margin-left: 0;
                margin-right: 0;
                margin-top: 33px;
                order: 2;

                @include min-screen($screen__l) {
                    margin-top: 10px;
                    border-top: 0;
                }
            }

            .checkout-methods-items {
                order: 3;

                .affirm-as-low-as {
                    font-size: 1.5rem;
                    margin: 0;

                    a {
                        font-style: italic;
                    }
                }
            }

            .grand.totals {
                font-size: 18px;

                .mark {
                    padding-top: 0;
                    text-transform: uppercase;
                }

                .amount {
                    padding-top: 0;
                }

                strong {
                    font-weight: bold;
                }
            }

            button {
                width: 100%;
            }

            .coupon {
                .field {
                    label.label {
                        display: none;
                    }
                }
            }

            #block-discount {
                .opc-payment-additional.discount-code {
                    #apply-discount {
                        margin: 20px 0;

                        .form-discount {
                            display: flex;
                            flex-flow: row nowrap;
                            white-space: nowrap;
                            text-overflow: ellipsis;

                            .fieldset {
                                margin: 0;
                                flex-grow: 1;

                                .field.form-group {
                                    width: 100%;
                                    margin: 0;

                                    &.field-error {
                                        & > label.control-label {
                                            color: $primary;
                                        }

                                        & > div > input#discount-code {
                                            border-color: $primary;
                                        }
                                    }
                                }
                            }

                            button {
                                width: auto;
                                padding: 0 5px !important;
                            }

                        }

                        .discount-message {
                            padding-left: 20px;

                            span {
                                font-size: 1rem;
                                letter-spacing: 0.75px;
                                text-transform: uppercase;
                                font-weight: normal;

                                &.discount-value {
                                    font-weight: bold;
                                }
                            }

                            .message {
                                padding: 0;
                                padding-top: 10px;
                                background: none;
                                font-size: 1rem;
                                letter-spacing: 0.75px;
                                text-transform: uppercase;
                                font-weight: normal;

                                > div {
                                    &::before {
                                        display: none;
                                    }
                                }
                            }

                        }
                    }

                    .i-have-a-promo-code {
                        span {
                            font-size: 1.6rem;
                            text-transform: none;
                        }
                    }
                }
            }
        }

        .item-options {
            margin-top: 20px;
            width: 100%;

            .item-option {
                display: flex;
                flex-direction: row;
                color: $black;
                font-size: 12px;
                font-weight: normal;
                line-height: 16px;
                letter-spacing: 2px;
                text-transform: uppercase;
                @include min-screen($screen__l) {
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 24px;
                    letter-spacing: 1px;
                    text-transform: none;
                }

                div:first-child::after {
                    content: " : ";
                    padding-right: 2px;
                }

                div {
                    margin: 0 2px;

                    .action {
                        margin-left: 4px;

                        .icon {
                            width: 15px;
                            @include min-screen($screen__l) {
                                width: 20px;
                            }
                        }
                    }
                }

                .action-edit {
                    margin-left: 5px;
                }
            }
        }

        .info-block {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            background-color: #f6f6f6;
            height: 60px;
            margin-top: 22px;

            > svg {
                margin: 20px;
            }

            > span {
                line-height: 18px;
                font-size: 16px;
            }
        }
    }

    #shopping-cart {
        @include min-screen($screen__l) {
            margin-right: 100px;
        }
        max-width: 1000px;
        min-width: 300px;

        > div:not(:first-child) {
            padding-top: 20px;
            border-top: 1px solid #eae8e7;
        }
    }

    #discount-coupon-form {
        .fieldset.coupon {
            margin-left: 0;
            display: flex;
            flex-direction: row;

            > .field {
                flex-grow: 1;

                input {
                    border-right: 0;
                }
            }

            .actions-toolbar {
                button.primary {
                    background-color: black;
                    color: white;
                    font-size: 12px;
                    line-height: 16px;
                    height: 56px;
                    border-radius: 0;
                    border: 1px solid rgba(0, 0, 0, 0.2);
                    border-left: 0;
                    text-transform: uppercase;
                    font-weight: normal;
                    letter-spacing: 2px;
                    font-family: $font-family;
                }
            }
        }
    }

}

.checkout-success {
    margin-left: 5px;
    margin-right: 5px;

    .btn.-primary {
        width: 100%;
    }


    h1.title-h1 {
        @extend .title-h1;
        margin-top: 0;
        margin-bottom: 40px;
    }

    h2.title-h2 {
        @extend .title-h2;
    }

    h3.title-h3 {
        @extend .title-h3;
        margin-top: 0;
        margin-bottom: 40px;
    }

    h4.title-h4 {
        @extend .title-h4;
        margin-top: 0;
        margin-bottom: 10px;
        font-size: 18px;
        line-height: 30px;
    }

    margin-top: 47px;

    .container {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        max-width: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 30px;
        padding-bottom: 40px;

        &.with-separator {
            border-top: 1px solid #c1c1c1;
        }

        .field.newsletter {
            display: flex;

            .control {
                width: 100%;
                float: none;
            }

            label {
                text-align: left;
                display: flex;

                p {
                    display: block;
                    width: 100%;
                    text-align: left;
                }
            }
        }

        .field._captcha {
            margin: 0;
        }

        .primary {
            width: 100%;
            text-align: left;
        }

        .action-toolbar {
            margin-top: 11px;
        }

        .disclaimer {
            font-size: 14px;
            line-height: 21px;

            p {
                display: block !important;
                text-align: left;
            }

            > a {
                text-decoration: underline;
            }
        }

        // a.primary.create {
        //     text-align: center;
        //     height: 40px;
        //     border-radius: 1px;
        //     border: 0;
        //     color: white;
        //     background: linear-gradient(271deg, #E32117 2.57%, #93291E 137.17%);
        //     width: 100%;
        //     font-size: 12px;
        //     line-height: 16px;
        //     letter-spacing: 2px;
        //     text-transform: uppercase;
        //     padding-top: 12px;
        //     padding-bottom: 12px;
        // }
    }

    .form.create.account.form-create-account {
        min-width: 0;
        width: 100%;

        .field {
            width: 100%;
        }

        .actions-toolbar {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin-left: 0;

            .btn.-primary {
                width: 100%;
                margin-bottom: 11px;
            }
        }
    }
}

#opc-new-shipping-address {
    padding: 3rem;
}

#gift-message-popin {
    display: flex;
    flex-flow: column nowrap;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background: repeating-linear-gradient(
            -45deg,
            #eaeaea,
            #eaeaea 2px,
            #e1e1e1 2px,
            #e1e1e1 4px
        );
    }

    .gift-message-popin {
        &-header {
            display: none;
        }

        &-body {
            display: flex;
            flex-flow: row wrap;

            .recto,
            .verso {
                @include min-screen($screen__m) {
                    padding: 0 10px;
                }

                label,
                p {
                    color: #404141;
                    font-weight: 300;
                }
            }

            .recto {
                @include min-screen($screen__m) {
                    flex: 1;
                }

                @include max-screen($screen__m) {
                    width: 100% !important;
                }

                img {
                    width: 100%;
                    height: auto;
                }
            }

            .verso {
                flex: 7;
                min-height: 40px;
                display: flex;
                flex-direction: column;

                &__content {
                    flex-grow: 1;
                    background-color: white;
                    padding: 15px;
                    display: flex;
                    flex-flow: row wrap;
                }

                p {
                    width: 100%;
                }

                .img-wrapper {
                    display: flex;
                    align-items: center;
                    width: 30%;

                    @include max-screen($screen__m) {
                        width: 100%;
                    }
                }

                .form-wrapper {
                    padding-left: 20px;
                    display: flex;
                    flex-direction: column;
                    width: 70%;

                    @include max-screen($screen__m) {
                        width: 100%;
                        padding-left: 0;
                    }

                    #gift-message-form {
                        flex-grow: 1;
                        display: flex;
                        flex-direction: column;

                        .message {
                            flex-grow: 1;
                            display: flex;
                            flex-direction: column;

                            textarea {
                                min-height: 150px;
                                flex-grow: 1;
                            }
                        }
                    }

                    .logo-wrapper {
                        padding: 30px 0;

                        img {
                            width: 30%;
                            float: right;
                        }
                    }
                }
            }
        }

        &-footer {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            margin-top: 2.6rem;

            .btn {
                margin: 0 5px;

                @include max-screen($screen__m) {
                    width: 100%;
                    margin: 5px 0;
                }
            }
        }
    }
}


//Payment step
.checkout-index-index {
    .checkout-payment-method {
        #co-payment-form {
            legend, br {
                display: none;
            }

        }
        .payment-method-content > .actions-toolbar > .primary .action.primary {
            text-transform: uppercase;
            font-size: 0.75em;
            letter-spacing: 2px;
        }

        #checkout-step-payment {
            padding-top: 0;
        }

        #cart-summary-totals {

            @include min-screen($screen__m) {
                display: none;
            }
            margin-top: 50px;

            .amount, .mark {
                font-size: 18px;
                font-weight: 300;
                line-height: 30px;
            }

            .amount {
                text-align: right;
            }

            .grand.totals {
                border-top: 1px solid $light-grey2;

                letter-spacing: 1px;

                .amount > strong, .mark > b {
                    font-weight: 500;
                }
            }
        }


        .payment-methods {
            margin: 0;

            .payment-method {
                &:focus-within {
                    outline: 1px dashed $primary;
                    outline-offset: 5px;
                }

                &-title {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    cursor: pointer;
                    padding-left: 0;
                    padding-right: 0;
                }

                &-input {
                    width: 40px;
                }

                &.payment-method-credit-card {
                    .payment-method-label > label {
                        vertical-align: super;
                    }
                }

                &.payment-method-banktransfer {
                    .payment-method-content {
                        p.instructions {
                            background-color: #fcfcfc;
                            padding: 20px;
                            margin-bottom: 2.5rem;

                            br {
                                display: block !important;
                            }
                        }
                    }
                }

                &-label {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;

                    label {
                        cursor: pointer;
                    }

                    img {
                        cursor: pointer;
                        height: 22px;
                        margin-left: 5px;

                        &.credit-card-psp {
                            vertical-align: bottom;
                            height: 40px;
                        }
                        &.twint {
                            vertical-align: bottom;
                            height: 26px;
                        }
                    }
                }

                &-amazon {
                    .payment-method-title {
                        img {
                            margin-top: 10px
                        }
                    }

                }

                &-note {
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 30px;
                }


                &-content {
                    padding-left: 0;
                    padding-right: 0;


                    .actions-toolbar {
                        margin-left: 0;
                        display: flex;
                        flex-wrap: wrap;
                    }
                }

                &.payu-payment {
                    .payment-method-content {
                        .method__single._disabled {
                            display: none
                        }

                        .payu-checkbox {
                            label {
                                a {
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                }
            }
        }

    }

    .checkout-billing-address {
        .shipping-address-item-actions {
            margin-top: 10px;
        }

        .billing-address-form {
            width: 100%;
            max-width: unset;
        }

        div.fieldset {
            margin-top: 20px;

            div.field {
                width: 100%;

                div.control {
                    width: 100%;
                    float: unset;
                }
            }


        }

        div.fieldset.address {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;

            .field {
                width: 100%;
                @include min-screen($screen__l + 1) {
                    width: calc(50% - 15px);
                }

                &.form-group {
                    &.fieldset-jp-postcode {
                        width: 100%;

                        .control {
                            .zipcode-generator {
                                display: flex;
                                flex-wrap: wrap;

                                .zip-container {
                                    width: calc(25% - 7.5px);
                                    display: flex;
                                    align-items: center;

                                    @include max-screen($screen__l) {
                                        width: 50%;
                                    }

                                    span {
                                        text-align: center;
                                        width: 20%;
                                    }

                                    .field.form-group {
                                        width: 100%;
                                        margin: 0;
                                    }

                                    .validation-advice {
                                        padding: 0 0 0 5px;
                                    }
                                }

                                .calculate-city {
                                    flex-grow: 1;
                                    margin-left: 30px;

                                    @include max-screen($screen__l) {
                                        margin-top: 15px;
                                        margin-left: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .field[name='billingAddressaffirm_gateway.company'],
            .field[name='billingAddressaffirm_gateway.telephone'] {
                width: 100%;
            }


            .field.street {
                width: 100%;
                display: flex;
                flex-direction: column;

                .form-group {
                    margin: 0;
                }

                .field.form-group.additional {
                    margin-top: 20px;
                }


                .control {
                    float: unset;
                    width: 100%;
                }

                .field {
                    width: 100%;
                }
            }

            .field.phone_number-container {
                width: 100%;
                display: flex;
                flex-direction: row;

                .control {
                    width: 100%;
                    display: flex;
                    flex-direction: row;

                    .field {
                        width: calc(50% - 15px);
                        margin: 0 0 38px 0;

                        &:first-of-type {
                            margin-right: 30px;
                        }

                        .control {
                            flex-direction: column;
                        }
                    }
                }
            }

            .field.form-group.checkbox-group {
                width: 100%;
            }

            .field-tooltip {
                display: none;
            }
        }


        .billing-address {
            display: none;

            &.current {
                background-color: $lighter-grey;
                padding: 20px;
                display: block;
            }

            padding: 20px 0px;
            width: 100%;

            &-title {
                font-size: 18px;
                line-height: 26px;
                font-weight: 500;
                text-transform: uppercase;
                margin-bottom: 8px;
            }

            &-item {
                &-details {

                }

                &-name {
                    font-weight: bold;
                    font-size: 14px;
                    letter-spacing: 2px;
                    line-height: 21px;
                    text-transform: uppercase;
                }
            }
        }

        .actions-toolbar {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            button {
                margin-right: 10px;
            }
        }

    }

    .payment-method-billing-address + .fieldset {
        .field.payment-description {
            font-size: 18px;
            line-height: 30px;
            font-weight: 300;
            margin-bottom: 30px;
        }


    }
}

.checkout-index-index,
.checkout-cart-index {


    .checkout-agreements-block {
        width: 100%;
        font-size: 14px;
        line-height: 21px;
        margin-top: 5px;

        a {
            text-decoration: underline;

        }
    }

    .checkout-methods-items {

        li.item + .item {
            margin-top: 10px;
        }

        .item {
            div {
                margin: 10px 0;

                button {
                    height: 40px;
                    padding: 0;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    span {
                        line-height: 12px;
                    }

                    img {
                        max-width: 90px;
                        margin-left: 10px;
                    }

                    &.paypal-btn:hover > img,
                    &.paypal-btn:active > img,
                    &.paypal-btn:focus > img {
                        content: url("../images/logo/paypal_white_logo.png");
                    }

                    &.login-with-amazon:hover > img,
                    &.login-with-amazon:active > img,
                    &.login-with-amazon:focus > img {
                        content: url("../images/logo/amazonpay_white_logo.png");
                    }

                    &.affirm-btn:hover > img,
                    &.affirm-btn:active > img,
                    &.affirm-btn:focus > img {
                        content: url("../images/logo/affirm_white_logo.png");
                    }

                    &.affirm-btn > img {
                        max-height: 40px;
                        margin-top: -5px;
                    }
                }
            }
        }
    }

    .amazon-button-container {
        .field-tooltip, .field-tooltip-action {
            display: none;
        }
    }

}


//fixes for paypal express review
.paypal-express-review {
    .opc-sidebar {
        @include max-screen($screen__m) {
            background-color: white;
        }

        .opc-block-summary:last-child {
            .title {
                padding-bottom: 12px;
            }

            .block {
                display: flex;
                flex-direction: column;

                & > b {
                    text-transform: uppercase;
                    margin-bottom: 5px;
                    font-weight: bold;
                }

                & > img {
                    max-width: 200px;
                    margin-bottom: 5px;
                }

                & > span {
                    font-weight: 300;
                }
            }
        }
    }


    #order-review-form {
        display: flex;
        flex-wrap: wrap;

        .checkout-agreements-block {
            width: 100%;
            font-size: 14px;
            line-height: 21px;
            margin-top: 5px;

            a {
                text-decoration: underline;

            }
        }
    }

    #discount-coupon-form {
        .fieldset.coupon {
            margin-top: 10px;
            margin-left: 0;
            display: flex;
            flex-direction: row;

            > .field {
                flex-grow: 1;
                margin-bottom: 5px;

                .control {
                    width: 100%;
                    float: unset;
                }

                input {
                    border-right: 0;
                }
            }

            .actions-toolbar {
                margin-left: 0;
                padding: 0;

                button.primary {
                    background-color: black;
                    color: white;
                    font-size: 12px;
                    line-height: 16px;
                    height: 56px;
                    border-radius: 0;
                    border: 1px solid rgba(0, 0, 0, 0.2);
                    border-left: 0;
                    text-transform: uppercase;
                    font-weight: normal;
                    letter-spacing: 2px;
                    font-family: $font-family;
                }
            }
        }
    }

    #block-discount {
        .fieldset.coupon {
            margin-bottom: 3px;
        }

        .checkbox-group {
            label {
                span {
                    text-transform: none;
                    font-size: 16px;
                    font-weight: normal;
                    color: $dark-grey2;
                }
            }
        }

        .discount-info {
            font-size: 10px;
            line-height: 15px;
            letter-spacing: 0.75px;
            text-transform: uppercase;
            margin-bottom: 10px;
        }

        #coupon_code {
            &::placeholder {
                color: $light-grey;
                font-size: 16px;
                font-weight: normal;
            }
        }
    }

}

.paypal-express-review {
    .ups-access-point-container {
        .ups-shipping-information {
            margin-top: 15px;
        }
    }

    .ajax-loader {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 20;
        top: 0;
        left: 0;

        .ajax-loader-background {
            background-color: #fff;
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0.5;
        }

        .ajax-loader-container {
            position: absolute;
            left: calc(50%);
            top: calc(50%);
            transform: translateX(-50%) translateY(-50%);

            .ajax-loader-img-container {
                width: 125px;
                height: 125px;
                margin-left: auto;
                margin-right: auto;
            }

            h3 {
                @extend .title-h3;
                background: #f1f1f1;
                border-radius: 5px;
                border: 1px solid #a7a7a7;
                padding: 5px;
                font-weight: bold;
                display: none;
            }
        }
    }
}

.checkout-cart-index {
    .ajax-loader {
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;

        .ajax-loader-background {
            background-color: #fff;
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0.5;
        }

        .ajax-loader-img-container {
            position: absolute;
            left: calc(50%);
            top: calc(50%);
            transform: translateX(-50%) translateY(-50%);
            width: 125px;
            height: 125px;
        }
    }


    .cart-page {
        .cart-container {
            .cart-summary {
                background: none;
                padding-left: 0;
                padding-right: 0;

                &-content {
                    background: #f5f5f5;
                    padding-left: 20px;
                    padding-right: 20px;
                    padding-bottom: 20px;
                }
            }
        }
    }
}

#payment-method-popin {
    padding: 0 3rem 3rem 3rem;

    .payment-method-popin-content {
        .selected-payment-method {
            margin-top: 15px;
            font-size: 1.6rem;

            span {
                text-transform: uppercase;
                font-weight: bold;
                font-style: normal;
            }
        }
    }

    .payment-method-popin-footer {
        margin-top: 15px;
    }
}

.tissot-slide {
    @extend .custom-slide;

    @include max-screen($screen__m) {
        .modal-inner-wrap {
            transform: translateY(-100%) translateX(0);
        }

        &._show {
            .modal-inner-wrap {
                transform: translatey(0) translateX(0);
            }
        }
    }
}

.modal-popup.gift-modal {
    .modal-inner-wrap {
        width: calc(100% - 8px);
        margin-left: auto;
        margin-right: auto;
        left: 0;

        @include min-screen($screen__m) {
            width: calc(100% - 40px);
        }
    }
}

.modal-popup.ups-access-point-modal {
    .modal-inner-wrap {
        @include max-screen($screen__m) {
            width: 100%;
        }
        width: calc(100% - 80px);
        margin-left: auto;
        margin-right: auto;
        left: 0;

        @include min-screen($screen__l) {
            width: calc(100% - 120px);
        }

        @include min-screen($screen__xxl) {
            width: calc(100% - 240px);
        }
    }

    .modal-content {
        @include max-screen($screen__m) {
            height: 100%;
        }
    }

    #ups-access-point-modal {
        height: 550px;
        padding: 0 30px 60px 30px;

        .ajax-loader {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 20;
            top: 0;
            left: 0;

            .ajax-loader-background {
                background-color: #fff;
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: 0.5;
            }

            .ajax-loader-img-container {
                position: absolute;
                left: calc(50%);
                top: calc(50%);
                transform: translateX(-50%) translateY(-50%);
                width: 125px;
                height: 125px;
            }
        }

        .ups-access-point-modal__content {
            display: flex;
            height: 100%;

            @include max-screen($screen__xl) {
                display: block;
            }

            .search-container {
                flex: 0.5;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                @include max-screen($screen__xl) {
                    margin-bottom: 30px;
                }

                form.form-tissot {
                    display: flex;

                    @include max-screen($screen__xl) {
                        margin-bottom: 30px;
                    }

                    @include min-screen($screen__xs) {
                        .form-group {
                            margin-top: 10px;
                            margin-right: 30px;
                            flex: 0.5;

                            &:first-child {
                                flex: 1;
                                margin-right: 15px;
                            }

                            button.-primary {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }

                .location-list-items {
                    height: 300px;
                    overflow: auto;

                    @include max-screen($screen__xl) {
                        margin-bottom: 30px;
                    }

                    ul.location-list {
                        padding: 0;
                        margin-left: 10px;

                        li.location-item {
                            list-style: none;
                            display: flex;

                            .information {
                                display: block;
                                margin-left: 15px;
                                margin-right: 15px;

                                label {
                                    font-weight: bold;
                                }

                                small {
                                    position: relative;
                                    top: -15px;
                                    color: $light-grey;
                                }
                            }

                            hr {
                                margin-top: 0;
                            }
                        }
                    }
                }

                .actions-container {
                    button.-primary {
                        width: calc(100% - 30px);
                        margin-left: 15px;
                        margin-right: 15px;
                    }
                }
            }

            .map-container {
                flex: 1;

                @include max-screen($screen__xl) {
                    height: 100%;
                }

                #ups-access-point-map {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

//Internet explorer specifics
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .menus-container {
        overflow: visible;
    }
}

.cart-page, .checkout-index-index, .paypal-express-review {
    div.page.messages {
        margin: 0 15px;

        .messages {
            .message {
                font-size: 14px;

                &.error {
                    font-size: 16px;
                    font-weight: bolder;
                    text-transform: uppercase;
                }

                .close {
                    color: inherit;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    font-size: 28px;
                    font-family: "icons-blank-theme", serif;
                    margin: -21px 0 0;
                    vertical-align: middle;
                    display: inline-block;
                    font-weight: normal;
                    overflow: hidden;
                    speak: none;
                    text-align: center;
                    right: 0;
                    width: 40px;
                    height: 40px;
                    position: absolute;
                    cursor: pointer;
                }
            }
        }
    }
}

.cart-page {
    div.page.messages {
        width: 100%;

        .messages {
            padding: 0;
        }
    }
}

.checkout-index-index {
    div.page.messages {
        margin: 0;

        &.js {
            .messages {
                padding: 0;
            }
        }
    }
}
