.faq-container {
    max-width: 1140px;
    margin: 60px auto;

    h1 {
        @extend .title-h1;
        color: $primary;
        font-family: $font-family-headings;
        text-align: center;
    }
    .row {
        .summary {
            font-size: 26px;
            font-family: $font-family-headings;
        }
        .question-list {
            h2 {
                @extend .title-h2;
                color: $primary;
                cursor: pointer;
                font-family: $font-family-headings;
            }
            .topic-content {
                display: none;
                font-weight: 300;
            }
            h3 {
                @extend .title-h3;
                font-family: $font-family-headings;
                font-size: 18px;
            }
        }
    }
}

@media (max-width: 767px) {
    .faq-container {
        padding: 0 15px;
        margin-top: 20px;
        .sidebar {
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid $primary;
        }
    }
}
@media (min-width: 768px) {
    .faq-container {
        .row {
            display: flex;

            .sidebar {
                width: 25%;
                margin-right: 50px;
                border-right: 1px solid $primary;
            }
        }
    }
}
