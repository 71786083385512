.message.global.noscript,
.message.global.cookie {
    background: $lighter-grey;
    color: $primary;
    font-size: 1.6rem;
    padding: 30px;

    @include min-screen($screen__m) {
        padding: 15px 25px;
    }
    .wide_contentsystem_summary {
        margin-bottom: 0;
    }

    .content {
        display: flex;
        align-items: stretch;
        flex-direction: column;

        @include min-screen($screen__m) {
            flex-direction: row;
            align-items: center;
        }

        & > .wide_contentsystem_summary {
            display: flex;
            width: 100%;
        }
    }

    .content-system--html {
        font-size: 1.4rem;
        text-align: left;

        @include min-screen($screen__m) {
            padding: 0 40px;
            flex-grow: 1;
            text-align: center;
        }

        @include min-screen($screen__xl) {
            padding: 0 110px;
        }
    }

    .actions {
        margin-top: 20px;

        @include min-screen($screen__m) {
            margin: 0;

            display: flex;
            flex-flow: column;
        }

        @include min-screen($screen__xl) {
            flex-flow: row;
        }
    }

    .btn-disallow,
    .btn-allow {
        @include make-btn;
        white-space: nowrap;

        width: 100%;

        @include min-screen($screen__m) {
            width: auto;
        }
    }

    .btn-allow {
        @include make-btn-primary;
        margin: 15px 0 0 0;

        @include min-screen($screen__xl) {
            margin: 0 0 0 20px;
        }
    }

    .btn-disallow {
        @extend .-stroke;

        background: transparent;
        border-color: $primary;
        color: $primary;

        &:hover {
            color: $primary;
        }
    }

    a {
        color: $primary;

        &:hover {
            color: $primary;
        }
    }
}
