.pagebuilder-video-container {
    padding-top: 56.25%;
    position: relative;

    iframe {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100% !important;
        height: 100% !important;
    }
}

.pagebuilder-mobile-hidden {
    width: 100%;
    display: none;

    @include min-screen($screen__m) {
        display: block;
    }
}

.pagebuilder-mobile-only {
    width: 100%;
    display: block;

    @include min-screen($screen__m) {
        display: none;
    }
}

.pagebuilder-button-primary {
    @include make-btn;
    @include make-btn-primary;
}

.pagebuilder-button-secondary {
    @include make-btn;
    @extend .-stroke;
}

.pagebuilder-button-link {
    @extend.link-more;
}

.swiper-pagination-bullet-active {
    background: $primary;
}

main {
    .simple_text,
    .simple_html {
        max-width: $width_medium;
        margin: 40px auto;

        h1 {
            @extend .title-h1;

            & + p {
                font-family: $font-family-headings;
                color: $dark-grey3;
                max-width: 836px;
                margin: 10px auto auto auto;
                text-transform: uppercase;
            }
        }

        p {
            @extend .text-default;
        }
    }
}
