// =============================================================================
// 1# Settings / Fonts
// =============================================================================

// Context
// =============================================================================
$body-context: 16px;

// Typefaces
// =============================================================================

//@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400&display=swap');
@font-face {
    font-family: "Noto Sans";
    src: url("../images/fonts/notosans/NotoSans-Regular.ttf") format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display:  swap;
}

$font-icons: 'icomoon';

@font-face {
    font-family: 'Gill Sans';
    src:  url('../images/fonts/GillSansStd_Light.otf');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Gill Sans';
    src:  url('../images/fonts/GillSans.woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Akzidenz-Grotesk';
    src:  url('../images/fonts/AkzidenzGrotesk_MediumCond.otf');
    font-weight: normal;
    font-style: normal;
    font-stretch: condensed;
}
@font-face {
    font-family: 'Akzidenz-Grotesk';
    src:  url('../images/fonts/AkzidenzGrotesk_Condensed.otf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Proxima-Nova-Regular';
    src:  url('../images/fonts/proximanova-regular.ttf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Proxima-Nova-Light';
    src:  url('../images/fonts/proximanova-light.ttf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Proxima-Nova-Condensed';
    src:  url('../images/fonts/proximanovacond-regular.ttf');
    font-weight: normal;
    font-style: normal;
}

$font-family-system: -apple-system, BlinkMacSystemFont, 'Segoe UI', Noto Sans, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif !default; // font operating system
$font-family-headings: 'Proxima-Nova-Condensed', sans-serif !default; // font for h1, h2.. h6
$font-family-monospace: consolas, courier, monospace !default; // font for code and samples

$font-formats-default: 'woff2', 'woff' !default;
$font-family-default: $font-family-system !default;
$font-family: "Proxima-Nova-Regular", Arial, Helvetica, sans-serif;
$font-family-secondary: "Proxima-Nova-Condensed", Arial, Helvetica, sans-serif;


// Line heights
// =============================================================================
$line-height-default: 1.66 !default;


// Weight
// =============================================================================
$weight-light: 200 !default;
$weight-book: 300 !default;
$weight-regular: 400 !default;
$weight-medium: 500 !default;
$weight-bold: 700 !default;
