//
//  Tissot colors
//  -----------------------------------------------
$primary: #8A6E54;
$red: $primary;
$red-disabled: #989592;
$green: #27AE60;
$black: #000;
$white: #fff;
$ultralight-grey:#F8F8F8;
$lighter-grey: #F6F6F6;
$light-grey3: #9a9a9a;
$light-grey2: #EAE8E7;
$light-grey: #8e8e8e;
$almost-black: #141414;
$dark-grey: #202020;
$dark-grey2: #333333;
$dark-grey3: #4F4F4F;
$orange-warning: rgba(238, 143, 0, 0.1);


// Borders
$border-color: rgba(black, 0.2);
$border-color-form: rgba(25, 25, 25, 0.32);
$border-active-color: black;

$transition-slow: 0.5s ease-in;
$transition-fast: 0.2s ease-in;
$transition-05: 0.5s ease-in;
$transition-02: 0.2s ease-in;

$width-medium: 652px;
$width-large: 860px;
$screen__xxl: 1681px;

// Header
$header-height: 70px !default;
$header-total-height: $header-height !default;
$header-block-callout-height: 40px !default;
$header-height-mobile: 60px !default;
$header-total-height-mobile: $header-height-mobile !default;
$header-block-callout-height-mobile: 60px !default;
$header-header-bottom-height: 60px !default;
