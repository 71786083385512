.news-thumbnail {
    display: flex;
    flex-direction: column;
    position: relative;
    width: auto;
    height: 100%;
    cursor: pointer;

    &__img-container {
        padding-top: 92%;
        position: relative;
        overflow: hidden;

        img {
            position: absolute;
            top:50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(1);
            transition: all 200ms ease-in-out;
            max-width: initial;
            height: 100%;
        }
    }

    &:hover {
        .news-thumbnail__img-container img {
            transform: translate3d(-50%, -50%, 0) scale(1.03);
            transition: transform 200ms;
        }

        h3 {
            color: $primary;
        }
    }

    &__btn-more {
        @include make-btn;
        @include make-btn-primary;
        @extend .icon-plus;

        position: absolute;
        bottom: 0;
        right: 0;
        border-radius: 0;
        padding: 0;
        width: 34px;
        height: 34px;

        &:after {
            font-family: 'icomoon';
            font-size: 24px;
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
        }
    }

    &__description {
        padding: 17px 0;
        color: $dark-grey;
    }

    &__title {
        font-size: 1em;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: normal;
        font-family: $font-family-headings;
        margin: 0;
        color: $primary;
    }

    &__date {
        margin-bottom: 10px;
        font-weight: 300;
        letter-spacing: 2px;
        font-size: 13px;
        color: $dark-grey2;
    }

    &__text {
        @extend .text-default;
        margin-bottom: 10px;
        overflow: hidden;
        white-space: pre-wrap;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    &__link {
        @extend .link-more;
        margin-bottom: 0;
    }
}


.news-list {
    margin: 60px 0 50px;
    text-align: center;
    position: relative;

    @include min-screen($screen__m) {
        margin: 105px 20px 50px;
    }

    @include min-screen($screen__xl) {
        margin: 105px calc(3vw + 20px) 50px;
    }

    &__list {
        list-style: none;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding: 0;
        margin: 0 16px;

        @include min-screen($screen__m) {
            flex-direction: row;
            margin: 0 -5px 65px;
        }
    }

    &__item {
        text-align: left;
        width: 100%;
        margin-bottom: 25px;

        @include min-screen($screen__m) {
            width: 25%;
            padding: 0 5px;
            margin-bottom: 0;
        }
    }

    &__item--full {
        width: calc(100% + 20px);
        text-align: left;
        margin: 0 -10px 25px;

        @include min-screen($screen__m) {
            margin: 40px -25px 95px;
            width: calc(100% + 50px);
        }

        .text-media {
            background-color: $primary;
            &__label, &__title, &__description, &__link {
                color: #fff;
            }
        }

        .text-media__text {
            order: -1;
        }
    }

    &__title {
        @extend .title-h2;
        margin-bottom: 35px;
    }

    &__btn-more {
        @include make-btn;
        @include make-btn-primary;
    }
}

.news-list__nav {
    margin: 30px 10px;
    text-align: center;

    @include min-screen($screen__m) {
        margin: 70px 25px 30px;
        text-align: left;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        margin: 0 10px;
        padding: 0;
        display: inline-block;
        font-size: 1em;
        text-transform: uppercase;
        font-weight: 500;
        font-family: $font-family-headings;
        letter-spacing: 2px;

        @include min-screen($screen__m) {
            margin: 0 30px 0 0;
        }
    }

    a {
        color: $black;
    }

    .active {
        position: relative;

        &:after {
            content: "";
            display: block;
            width: 100%;
            height: 2px;
            background-color: $primary;
            position: absolute;
            bottom: -2px;
            left: 0;
        }
    }
}

.news-list__page-content {
    @extend .news-list;
    margin: 0;

    @include min-screen($screen__m) {
        margin: 0 25px;
    }

    .news-list__list {
        flex-wrap: wrap;
    }

}
