.wcs-customer_service {
    .header {
        &__title {
            @extend .title-h1;
            margin-bottom: 0;
        }

        &__subtitle p {
            @extend .text-default;
            font-family: $font-family-headings;
            color: $dark-grey3;
            max-width: 836px;
            margin: 10px auto auto auto;
            text-align: center;
            text-transform: uppercase;
        }
    }
    .main .simple_html {
        max-width: 1200px;
    }
    .text-media {
        background-color: #f6f6f6;
        &.bg-black {
            background-color: $primary;

            .text-media__btn {
                color: #fff;
                border: 1px solid #fff;
                background: transparent;
            }
        }
    }

    .cs-ask-question {
        display: flex;
        flex-flow: column nowrap;
        padding: 100px 0;

        &__text,
        &__icons {
            flex: 1;
            padding: 0 20px;
        }

        &__text {
            order: 1;
        }

        &__icons {
            order: 0;
            padding-bottom: 80px;
        }

        @include min-screen($screen__l) {
            flex-flow: row nowrap;

            &__text {
                order: 1;
            }

            &__icons {
                order: 1;
                padding-bottom: 0;
            }
        }

        &__text {
            &-title {
                @extend .title-h3;
                letter-spacing: 2px;
            }

            &-desc {
                @extend .text-default;
            }

            &-btn {
                @include make-btn;
                @include make-btn-primary;
            }
        }

        &__icons {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-evenly;
            align-items: center;

            &-mail,
            &-support {
                height: 150px;
                width: 150px;
                text-align: center;
                background-color: #f8f8f8;
                border-radius: 99999px;
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: center;

                .icon {
                    height: 60%;
                    width: 60%;
                }
            }
        }
    }

    .cs-contact {
        padding: 30px 15px;

        @include min-screen($screen__m) {
            padding: 40px calc(3vw + 20px);
        }

        &__title {
            text-align: center;
            padding: 10px 0 30px 0;
            margin: 0;
        }

        &__top {
            display: flex;
            flex-flow: column nowrap;

            @include min-screen($screen__m) {
                flex-flow: row nowrap;
            }
        }

        &__desc,
        &__information {
            flex: 1;
            padding-bottom: 30px;
        }

        &__desc {
            p {
                margin-bottom: 10px;
            }

            .btn {
                min-width: 220px;
            }

            @include min-screen($screen__m) {
                padding-right: 110px;

                p {
                    margin-bottom: 20px;
                }
            }
        }

        &__information {
            h4 {
                @extend .title-h4;
                margin-top: 0;
            }

            .cells {
                display: flex;
                flex-direction: column;

                &__address {
                    order: 1;
                }

                &__schedule {
                    display: flex;
                    flex-direction: row;
                    order: 0;
                }

                @include min-screen($screen__l) {
                    flex-direction: row;

                    &__address {
                        order: 0;
                    }

                    &__schedule {
                        order: 1;
                        flex-direction: column;
                    }
                }

                &__title {
                    font-weight: 900;
                }

                &__address,
                &__schedule {
                    flex: 1;
                }

                &__address {
                    padding-bottom: 20px;
                }

                &__schedule {
                    &-hours,
                    &-phone {
                        flex: 1;
                        padding-bottom: 20px;
                    }
                }
            }
        }

        &__bottom {
            display: flex;
            flex-flow: column wrap;
            justify-content: space-between;
            align-items: flex-start;

            @include min-screen($screen__m) {
                flex-flow: row wrap;
            }

            &-item {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                text-transform: uppercase;
                letter-spacing: 2px;
                padding-bottom: 10px;

                .icon {
                    margin-right: 15px;
                    height: 40px;
                    width: 40px;
                }
            }
        }
    }

    .maintenance {
        padding: 30px 15px;

        @include min-screen($screen__m) {
            padding: 40px calc(3vw + 20px);
        }
    }
}
