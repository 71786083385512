.not-found {
    display: flex;
    flex-direction: column;
    font-family: $font-family;

    @include min-screen($screen__m) {
        flex-direction: row;
        min-height: 690px;
    }

    &__left,
    &__right {
        width: 100%;

        img {
            display: block;
            max-width: 300px;
        }

        @include min-screen($screen__m) {
            width: 50%;
        }
    }

    &__left {
        padding: 20px 16px 60px;

        display: flex;
        flex-flow: column;
        justify-content: center;

        @include min-screen($screen__m) {
            padding: 40px;
        }

        @include min-screen($screen__xl) {
            padding: 0 40px 0 135px;
        }

        .not-found__title,
        .not-found__subtitle {
            text-transform: uppercase;
        }

        .not-found__title {
            @extend .title-h2;
            color: $primary;

        }

        .not-found__subtitle {
            @extend .title-h4;
        }

        .not-found__search-form {
            position: relative;
            margin-top: 40px;

            @include min-screen($screen__m) {
                width: 300px;
            }

            .not-found__search-input {
                padding: 20px 10px;
            }

            .not-found__search-submit-input {
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
                padding: 0;
                border-width: 0;
                background-color: transparent;

                svg {
                    fill: #8E8E8E;
                    transform: rotateY(180deg);
                }
            }
        }
    }

    &__right {
        display: none;

        @include min-screen($screen__m) {
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;

            min-height: 50vw;
        }
    }

    &__categories,
    &__links {
        margin-top: 40px;

        p {
            @extend .text-default;
        }
    }

    &__other-links {
        list-style: none;
        padding: 0;
        margin: 0 0 0 -30px;
        font-family: $font-family;
        font-size: 16px;

        li {
            display: inline-block;
            margin: 0 0 0 30px;
            text-transform: uppercase;
            font-weight: 500;
            letter-spacing: 2px;

            a, a:visited {
                color: $primary;
                position: relative;
                display: block;

                span {
                    display: block;
                }

                &:before {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 100%;
                    height: 1px;
                    background: black;
                    transition: right $transition-fast;
                }

                &:hover {
                    color: black;

                    &:before {
                        right: 0;
                    }
                }
            }
        }
    }
}
