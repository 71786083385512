.page-content__header {
    text-align: center;
    margin: 0;

    @include min-screen($screen__m) {
        margin: 0 25px;
    }

    &-title {
        @extend .title-h1;
        margin: 0;
        letter-spacing: 1.5px;
    }

    &-description {
        font-size: 1em;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: 300;
        font-family: $font-family-headings;

        @include min-screen($screen__m) {
            margin-top: 10px;
        }
    }
}

