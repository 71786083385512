.text-media {
    display: flex;
    flex-flow: column;
    background: $primary;
    padding: 30px 15px;
    align-items: center;
    text-align: left;

    @include min-screen($screen__m) {
        flex-flow: row;
        padding: 40px 20px;
        //margin-bottom: 40px;

        &__media,
        &__text {
            width: calc(50% - 10px);
            margin: 0 5px;
        }

        &:nth-child(2n-1) {
            .text-media__media {
                order: -1;
            }
        }

        &:nth-child(2n) {
            .text-media__text {
                order: -1;
            }
        }
    }

    // @include min-screen($screen__xl) {
    //     margin-bottom: 60px;
    // }

    @include min-screen($screen__xl) {
        padding: 40px calc(3vw + 20px);
    }

    &__media {
        width: 100%;

        @include min-screen($screen__m) {
            width: 50%;
            padding-right: 20px;
        }

        @include min-screen($screen__xl) {
            padding-right: 0;
        }

        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        right: 0;
        bottom: 0;
        width: 100% !important;
        height: 100% !important;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        opacity: 1;
        visibility: visible;
        transition: opacity 0.5s, visibility 0.5s;

        &.hide {
            opacity: 0;
            visibility: hidden;
        }

        &:hover:before {
            width: 70px;
            height: 70px;
        }

        &:hover:after {
            border-top-width: 15px;
            border-bottom-width: 15px;
            border-left-width: 20px;
        }

        &:before,
        &:after {
            display: block;
            content: " ";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: all 0.3s;
        }

        &:before {
            width: 60px;
            height: 60px;
            background-color: $primary;
        }

        &:after {
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 15px solid white;
        }
    }

    &__video {
        padding-top: 56.25%;
        position: relative;

        iframe {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100% !important;
            height: 100% !important;
        }
    }

    &__text {
        margin-top: 30px;

        @include min-screen($screen__xl) {
            padding: 0 110px;
            margin-top: 0;
        }
    }

    &__label {
        @extend .text-highlight;
        margin-bottom: 0;
    }

    &__title {
        @extend .title-h3;
        margin-bottom: 15px;
        color: $primary;
    }

    &__description {
        @extend .text-default;
        margin-bottom: 15px;
    }

    &__btn {
        @include make-btn;
        @include make-btn-primary;
        margin-top: 10px;
    }

    &__link {
        @extend .link-more;
    }

    &__container {
        &__gwp-container {
            margin-top: 30px;

            @include min-screen($screen__m) {
                margin-top: 40px;
            }
        }
    }

}

.wide_contentsystem-right {
    .text-media {
        .text-media__media {
            @include min-screen($screen__m) {
                order: 1;
                padding-left: 20px;
                padding-right: 0;
            }
        }
    }

    .content-push {
        .text-media__title,
        .text-media__description {
            text-align: left;
        }
    }
}

.wide_contentsystem-left {
    .text-media {
        .text-media__media {
            @include min-screen($screen__m) {
                order: -1;
            }

            @include min-screen($screen__xl) {
                margin-left: 0;
            }
        }

        .text-media__text {
            @include min-screen($screen__xl) {
                padding-right: 0;
                margin-right: 0;
            }
        }
    }
}

.text-media.bg-white,
.bg-white .text-media {
    background: white;
}

.text-media.bg-brown,
.bg-brown .text-media {
    background: $primary;

    &__title, &__description {
        color: white;
    }
    &__btn{
        position: relative;
        display: inline-block;

        @extend .icon-chevron-right;

        &:before {
            bottom: auto;
            color: inherit;
            font-family: 'icomoon';
            left: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            transition: left $transition-fast;
            width: auto;
        }
        &:hover {
            &:before {
                left: 3px;
            }
        }
    }
}


.text-media.bg-black,
.bg-black .text-media {
    background: black;

    &__title,
    &__description {
        color: white;
    }
}
