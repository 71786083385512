
.pagebuilder-slide-wrapper {
    .pagebuilder-overlay {
        &:not(.pagebuilder-poster-overlay) {
            max-height: 80vh;
            max-width: none;
        }
    }
    img {
        max-height: 80vh;
        margin: 0 auto;
    }
}

//.wcs-ui_kit,
.wide_contentsystem-content-page {
    .custom_form {
        .wide_contentsystem-contained {
            display: flex;
            flex-wrap: wrap;
        }
    }

    .wide_contentsystem- {
        position: relative;
        padding: 15px;

        @include min-screen($screen__m) {
            margin: 0 auto;
            padding: 0;
            width: $width-medium;
        }

        ul:not(.slick-dots) {
            @extend .text-default;
            padding: 0 20px;
            margin-bottom: 1.5em;
            list-style: none;

            li {
                padding-left: 20px;
                position: relative;

                &:before {
                    content: "" !important;
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background: $primary;
                    position: absolute;
                    left: 5px !important;
                    top: 14px !important;
                }
            }
        }

        ol {
            @extend .text-default;
            padding: 0;
            margin-bottom: 1.5em;
            list-style: none;
            counter-reset: list;

            > li {
                padding-left: 20px;
                position: relative;
                counter-increment: list;

                &::before {
                    content: counters(list, '.')".";
                    color: $primary;
                    position: absolute;
                    top: 0;
                    left: -15px;
                }
            }

            li + li {
                margin-top: 10px;
            }
        }

        h1 {
            @extend .title-h1;
            text-align: center;
        }

        h2 {
            @extend .title-h2;
            text-align: center;
        }

        h3 {
            @extend .title-h3;

            &.form-notes {
                font-size: 1.4rem;
                color: $dark-grey;
                margin: 15px 0 10px 0;

                @extend .title-h4;

                &:first-child {
                    margin-top: 60px;
                }
            }
        }

        h4 {
            @extend .title-h4;
        }

        h5 {
            @extend .title-h4;
        }

        h6 {
            @extend .title-h4;
        }

        p {
            @extend .text-default;
            margin-bottom: 1.5em;

            strong {
                font-weight: 600;
            }

            a {
                color: $primary;
            }
        }

        // Specific to pagebuilderslider
        .pagebuilder-slide-wrapper p:last-child {
            margin-bottom: 0;
        }

        .form-notes p {
            font-size: 1.4rem;
            font-weight: 300;
            color: $dark-grey;
            margin: 10px 0;

            &:last-child {
                margin-bottom: 60px;
            }
        }

        h1 + .date,
        h2 + .date {
            margin-top: -15px !important;
            margin-bottom: 20px !important;

            @include min-screen($screen__m) {
                margin-top: -30px !important;
                margin-bottom: 50px !important;
            }
        }

        .text-media__label {
            @extend .text-highlight;
            margin-bottom: 0;
        }

        /*
         * Ideally the centering of the text and the limited width should be applied to the
         * "wide_contentsystem--appearance_contained" class. But the pages were not designed with that class at first,
         * so that would require all the contributions to be redone.
         * We have therefore keeped the old behaviour and change it for the full-bleed & full-width cases.
         */
        &.wide_contentsystem--appearance_full-bleed {

            @include min-screen($screen__m) {
                width: 100%;
            }

            h2 {
                @extend .title-h2;
                text-align: left;
            }
        }

        &.wide_contentsystem--appearance_full-width {

            display: block;
            > figure img{
                margin-bottom: 3rem;
            }

            @include min-screen($screen__m) {
                margin: 0 25px;
                width: calc(100% - 50px);
            }

            @include min-screen($screen__xl) {
                margin: 0 3vw;
                width: calc(100% - 6vw);
            }

            h2 {
                @extend .title-h2;
                text-align: left;
            }

            .pagebuilder-column-group {
                margin: auto;
            }
        }
        .arabic-style {
            ol,ul {
                li {
                    &::before {
                        left: unset!important;
                        right: -25px;
                    }
                }
                ul {
                    padding-right: 20px;
                    li {
                        &:before {
                            right: -16px !important;
                        }
                    }
                }
                ol{
                    li {
                        &:before {
                            top: 14px;
                            right: -40px!important;
                        }
                    }
                }
            }
        }

        .form-tissot {
            .-center {
                text-align: center;
                margin-bottom: 50px;
            }
        }
        .form-group-message, .form-group-width {
            width: 100%;
        }
        .form-group-agreement {
            p {
                font-size: 1.4rem;
                font-weight: 400;
                margin-top: 0;
                margin-bottom: 1rem;
            }
        }

        .cathegory-list__item {
            &:before {
                content: none !important;
            }
        }
    }

    //img style
    .pagebuilder-mobile-hidden {
        display: none;

        @include min-screen($screen__m) {
            display: block;
        }
    }

    .pagebuilder-mobile-only {
        display: block;

        @include min-screen($screen__m) {
            display: none;
        }
    }

    .img-full-width {
        margin-bottom: 30px !important;

        @include min-screen($screen__m) {
            margin-bottom: 50px !important;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    .img-center {
        width: 100%;
        margin-bottom: 30px !important;

        @include min-screen($screen__m) {
            margin-bottom: 50px !important;
        }

        img {
            margin: 0 auto;
        }
    }

    .date {
        font-weight: 300;
        font-size: 1.3rem;
        line-height: 19px;
        letter-spacing: 2px;
        text-transform: uppercase;
        text-align: center;
    }

    blockquote,
    .blockquote {
        position: relative;
        margin: 30px 0 20px 0 !important;
        font-weight: normal;
        font-size: 2.5rem;
        line-height: 125%;
        text-transform: uppercase;
        font-style: normal;
        font-family: $font-family;

        @include min-screen($screen__m) {
            font-size: 3.5rem;
            margin: 70px 0 30px 0 !important;
        }

        p {
            font-size: 1em;
            line-height: inherit;
            font-weight: normal;
        }

        &:before,
        &:after {
            position: absolute;
            font-style: normal;
            font-weight: 300;
            font-size: 100px;
            color: $primary;
        }

        &:before {
            content: '“';
            top: 0;
            left: 0;
        }

        &:after {
            content: '”';
            right: 0;
            bottom: -2.2rem;
        }
    }

    .quote-author {
        line-height: 1.2em;
        margin-bottom: 30px !important;

        @include min-screen($screen__m) {
            margin-bottom: 50px !important;
        }

        p {
            line-height: inherit;
        }
        strong {
            display: block;
            font-weight: 500;
            letter-spacing: 2px;
            text-transform: uppercase;
            font-size: 0.88em;
        }

        span {
            font-weight: 300;
            color: $primary;
        }
    }

    .pagebuilder-button-primary {
        @include make-btn;
        @include make-btn-primary;
        &:before{
           z-index: -1;
        }
        background: none;
    }

    .pagebuilder-button-secondary {
        @include make-btn;
        @extend .-stroke;
    }

    .pagebuilder-button-link {
        @extend.link-more;
    }

    div[data-content-type="button-item"] {
        vertical-align: top;

        & + div[data-content-type="button-item"] {
            margin-left: 2em;
        }
    }

    div[data-content-type="buttons"] {
        margin-bottom: 30px !important;

        @include min-screen($screen__m) {
            margin-bottom: 50px !important;
        }
    }

    .pagebuilder-video-inner {
        margin-bottom: 30px !important;

        @include min-screen($screen__m) {
            margin-bottom: 50px !important;
        }
    }

    .pagebuilder-video-container {
        padding-top: 56.25%;
        position: relative;

        iframe {
            position: absolute;
            top:0;
            bottom:0;
            left:0;
            right:0;
            width: 100% !important;
            height: 100% !important;
        }
    }

    .row-with-share {
        position: relative;
    }

    .btn-share {
        display: none;
        margin-bottom: 10px;
        width: 42px;
        height: 42px;
        text-align: center;
        color: $white;
        background-color: $primary;

        @extend .icon-share;

        &:before {
            font-family: 'icomoon';
            font-size: 2.4rem;
            line-height: 42px;
        }

        @include min-screen($screen__m) {
            position: absolute;
            top: 8px;
            left: -100px;
            display: block;
        }
    }

    .share {
        margin: 0 16px 50px;
        text-align: center;

        &__icons {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                display: inline;
            }

            li + li {
                margin-left: 15px;
            }
        }

        &__title {
            @extend .title-h4;
            font-weight: bold;
            margin-bottom: 10px;
        }
    }



    //SWIPER for pagebuilder

    .swiper-pagination-bullet-active {
        background: $primary;
    }

    .swiper-slide {

        &:first-child {
            padding-left: 20px;

            @include min-screen($screen__m) {
                padding-left: 136px;
            }
        }

        &:last-child {
            padding-right: 10px;

            @include min-screen($screen__m) {
                padding-right: 136px;
            }
        }

        .pagebuilder-overlay{
            padding: 0 !important;
        }

        .pagebuilder-poster-content {
            max-width: 75vw;
        }
    }

    .products-slider {
        .swiper-slide {
            &:first-child {
                padding-left: 0;

                @include min-screen($screen__m) {
                    padding-left: 0;
                }
            }

            &:last-child {
                padding-right: 0;

                @include min-screen($screen__m) {
                    padding-right: 0;
                }
            }
        }
    }
}

.wide_contentsystem_summary {
    &__title {
        @extend .title-h1;
        text-align: center;
        margin-bottom: 0;
    }

    &__description {
        font-size: 1em;
        text-transform: uppercase;
        font-weight: 300;

        @include min-screen($screen__m) {
            font-size: 1.25em;
            margin-top: 10px;
        }
    }

    &__img {
        display: block;
        width: 100%;
        height: auto;
        margin-top: 30px;

        @include min-screen($screen__m) {
            margin-top: 50px;
        }
    }
}

.wide_contentsystem_grid{

    width: 50%;
    margin-left: 25%;
    margin-bottom: 5%;

    .redirectbox{
        border: 2px solid #ddd;
    }

    .caption-kr{
        font-weight: 600;
        color: black;
        text-decoration: underline;
    }

    .caption{
        text-align: center;
        margin-bottom: 1em;
    }

    .col-md-4 {
        margin-top: 4%;
    }

    @include min-screen($screen__m) {
        .wide_contentsystem_grid_container{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .col-md-4 {
            width: 33.3333%;
            display: block;
            position: relative;
            min-height: 1px;
            padding-left: 15px;
            padding-right: 15px;
        }

        .col-md-offset-0 {
            margin-left: 0;
        }
    }

    .visible-sm {
        //desktop
        display:none;

        //mobile
        @include max-screen($screen__m) {
            display:block;
        }
    }

    .visible-md {

        //mobile
        @include max-screen($screen__m) {
            display: none;
        }
    }

}

.catalog-category-view,
.wide_contentsystem-content-page {
    .img-full-width {
        margin-bottom: 30px !important;

        @include min-screen($screen__m) {
            margin-bottom: 50px !important;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    .img-center {
        margin-bottom: 30px !important;

        @include min-screen($screen__m) {
            margin-bottom: 50px !important;
        }

        img {
            margin: 0 auto;
        }
    }

    .is-medium-width {
        margin: 0 auto !important;
        max-width: $width-medium;
    }

    .landing-quote-simple {
        margin: 60px 0;
    }

    .swiper-container {
        max-width: 100vw;
        overflow: hidden;
        padding: 0 15px 23px;

        .swiper-wrapper {
            @include max-screen($screen__m) {
                margin-bottom: 0px;
            }
        }
    }
}

.catalog-category-view {
    .swiper-container {
        @include min-screen($screen__m) {
            padding: 0 23px 23px;
        }

        @include min-screen($screen__xl) {
            padding: 0 calc(3vw + 20px) 23px;
        }

    }
}
