.breadcrumbs {
    padding: 20px 15px 30px;
    text-transform: uppercase;
    font-size: 0.75em;
    letter-spacing: 0.1em;
    color: $dark-grey;
    max-width: 100%;
    margin: 0;

    @include min-screen($screen__m) {
        padding: 45px 35px 35px;
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    li {
        display: inline;
        margin-right: 10px;

        &:after {
            color: $primary;
        }

        &:hover {
            color: $primary;
        }
        &:last-child {
            color: $primary;
        }
    }

    strong {
        font-weight: bold;
    }

    a {

        &:hover {
            color: $primary;
            text-decoration: none;
        }
    }

}

.catalog-product-view .breadcrumbs {
    @include min-screen($screen__xl) {
        margin-left: calc(3vw + 20px);
        margin-right: calc(3vw + 20px);
        padding-left: 0;
    }
}

.catalog-category-view {
    .breadcrumbs {
        @include min-screen($screen__m) {
            padding-left: 20px;
            padding-right: 20px;
        }

        @include min-screen($screen__xl) {
            margin: 0 3vw;
        }
    }
}

