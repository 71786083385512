.page-newsletter {
    max-width: $width-medium;
    margin: 0 15px;

    @include min-screen($screen__m) {
        margin: 0 auto;
    }

    .title-h1 {
        @extend .title-h1;
        font-weight: bold;
        margin-bottom: 50px;
        text-align: center;
    }

    .section-newsletter-form__description {
        p {
            @extend .text-default;
            text-align: center;
            display: block;
        }

        h2 {
            @extend .title-h2;

            @include max-screen($screen__m) {
                font-size: 2rem;
            }
        }

        p + h2 {
            margin-top: 50px;
        }
    }
}

.page .messages {
    padding-top: 15px;
}

.newsletter-benetifs {
    font-size: 1.4rem;
    font-weight: 300;
    color: $dark-grey;
    margin: 60px 0;

    h3 {
        @extend .title-h4;
    }
}

.newsletter-benefits-item {
    margin-bottom: 30px;
}

.form-tissot {
    display: flex;
    flex-wrap: wrap;
    margin: 0;

    #google-captcha {
        margin-top: 0;
    }

    .-center {
        margin-left: auto;
        margin-right: auto;
    }

    .form-group {
        select.form-control:focus {
            box-shadow: none;
        }

        div.field-recaptcha {
            div.field {
                div.control {
                    position: relative;
                    top: -25px;
                    z-index: -1;
                    input.mage-error {
                        border-color: white;
                    }

                    input:focus {
                        outline: none;
                        outline-offset: 0;
                    }

                    div.mage-error {
                        position: relative;
                        top: unset;
                        left: unset;
                        text-transform: none;
                    }
                }
            }
        }

    }

    .form-group-line-break {
        width: 100%;
    }
}

.sgecomnewsletter-newsletter-subscriptionconfirm p.newsletter-success {
    margin-bottom: 60px;
}
