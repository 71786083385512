.countdown {
    position: relative;
    color: white;

    img {
        width: 100%;
        height: auto;
    }

    &-content {
        height: 90%;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: center;
        position: absolute;

        h2 {
            text-align: center;
        }
    }

    &-background {
        padding-top: 20px;
        background-color: black;
        display: flex;
        justify-content: center;
        position: relative;

        img{
            object-fit: cover;
        }
    }

    &-display {
        flex-direction: row;
        justify-content: center;

        &-container {
            display: flex;
        }

        div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-width: 90px;

            span {
                width: 100%;
                text-align: center;
            }

            span:first-child {
                font-size: 40px;
            }
        }

        div:not(:last-child) {
            span:first-child {
                border-right: 1px solid white;
            }
        }
    }
}
