.product-thumbnail,
.wide_contentsystem-content-page .wide_contentsystem- .product-thumbnail {
    display: block;
    position: relative;
    width: auto;

    &__img-container {
        padding-top: 100%;
        position: relative;
        overflow: hidden;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0) scale(1);
            transition: all 200ms;
            min-width: 100%;
        }
    }

    &__front .product-thumbnail__img-container {
        background: transparent;

        img {
            background: white;
        }
    }


    &__description {
        padding: 15px 0 0;
        position: relative;

        @include min-screen($screen__m) {
            padding: 15px 20px 0;
        }
    }

    &__title {
        font-size: 1em;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        text-align: center;
        font-weight: 500;
        color: $primary;
    }

    &__price {
        font-size: 1.125em;
        letter-spacing: 0.05em;
        text-align: center;
        margin: 25px 0 0;

        .product-original-price {
            display: inline-block;
            color: $light-grey;
            font-size: 0.72em;
        }
    }

    &__cta {
        @extend .btn;
        @extend .-primary;

        margin-top: 5px;
        width: 100%;
    }

    &__out-of-stock {
        text-transform: uppercase;
        color: $primary;
        font-size: 0.5625em;
        text-align: center;
        position: absolute;
        top: 8px;
        left: 0;
        right: 0;
    }

    &:hover,
    &:focus {
        color: $primary;

        .product-thumbnail__img--front {
            opacity: 1;
            transform: translate3d(-50%, -50%, 0) scale(1.03);
            transition: transform 200ms;
        }
    }
}

.product-thumbnail__title {
    margin-bottom: 0.3rem
}

.product-thumbnail__technical-badges {
    text-align: center;
}

.badge-technical {
    font-size: 0.8em;
    font-weight: normal;
    text-transform: uppercase;
    display: inline-block;
    line-height: 1.2em;
}

.badge-technical:after {
    content: "-";
}

.badge-technical:last-child:after {
    content: "";
}

.product-thumbnail__price {
    margin-top: 12px;
}
