.tissot_catalog-compatible-strap {
    .compatible-strap-page {
        padding: 40px 0;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;

        @include min-screen(margin-top) {
            padding: 60px 4vw;
        }

        .header-content {
            text-align: center;

            h1 {
                @extend .title-h1;

                margin-bottom: 0;
                margin-left: 0;
            }

            p {
                @extend .text-default;
            }
        }

        .page-content {
            margin-left: auto;
            margin-right: auto;

            .form-tissot {
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 30px;
                max-width: 500px;

                .form-group {
                    width: 100%;
                    margin-top: 15px;

                    .contain-help {
                        display: flex;

                        a.help-btn {
                            margin-top: 18px;
                            margin-left: 10px;

                            .icon {
                                align-self: center;
                            }

                            &.active {
                                color: #e32119;
                            }
                        }
                    }

                    a.btn {
                        width: calc(100% - 30px);
                        margin-top: 30px;
                    }

                    .btn-strap-search {
                        width: 100%;
                    }

                    .btn-strap-search {
                        width: calc(100% - 30px)
                    }
                }
            }
        }
    }

    .modal-owc {
        .modal-content {
            text-align: center;

            .image-container {
                margin-top: 15px;

                img {
                    width: 75%;
                }
            }
        }
    }
}

.products-grid {
    display: flex !important;
    flex-wrap: wrap;
    padding: 0 11px;

    @include min-screen($screen__m) {
        padding: 0 15px;
    }

    @include min-screen($screen__xl) {
        margin: 0 40px;
        padding: 0 15px;
    }

    .product-thumbnail {
        width: calc(50% - 10px);
        margin: 0 5px 30px;

        @include min-screen($screen__m) {
            width: calc(25% - 10px);
            margin: 0 5px 30px;
        }
    }
}

.products-section {
    @include min-screen($screen__m) {
        margin-top: 40px;
    }
}

.sort-by-num-result-wrapper {
    display: flex;
    margin: -40px 16px 20px;
    justify-content: flex-end;

    @include min-screen($screen__m) {
        margin: 0 25px 20px;
    }

    @include min-screen($screen__xl) {
        margin: 0 calc(3vw + 20px) 20px;
    }

    .number-results {
        order: -1;

        text-transform: uppercase;
        font-size: 1.5rem;
        font-weight: 500;
        flex-grow: 1;

        display: none;

        @include min-screen($screen__m) {
            display: block;
        }
    }

    .highlight {
        color: $primary;
    }

    .custom-select-2 {
        width: 210px;
        @include min-screen($screen__m) {
            min-width: 145px;
            width: auto;
            max-width: 100%;
        }
    }
}

.collection-header {
    text-align: center;
    padding: 0 16px;

    @include min-screen($screen__m) {
        padding: 0 20px;
    }

    @include min-screen($screen__xl) {
        margin: 0 3vw;
    }

    &__title {
        @extend .title-h1;
        margin-bottom: 0;

        strong {
            display: block;
            font-weight: 300;
        }
    }

    &__description {
        @extend .text-default;
        font-family: $font-family-headings;
        color: $dark-grey3;
        max-width: 836px;
        margin: 10px auto auto auto;
        text-transform: uppercase;
    }

    &__banner {
        margin-top: 40px;
        overflow: hidden;
        position: relative;
        /* need to bypass the scss compiler & use a literal instead. */
        padding-bottom: #{"min(75%, 500px)"};
        background: var(--background-image) no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    &__products {
        display: flex;
        margin: 10px -5px 0;

        &-img {
            display: block;
            margin: 0 5px;
            width: calc(50% - 10px);
        }

        &-img-container {
            padding-top: 100%;
            position: relative;
            overflow: hidden;
            display: block;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) scale(1);
                transition: all 2s ease-in-out;
                min-width: 100%;
                min-height: 100%;
            }
        }
    }

    &__message {
        color: $primary;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 1.6rem;
        margin-top: 20px;
        margin-bottom: 50px;

        @include min-screen($screen__m) {
            margin: 30px 0 0;
        }

        p {
            &:first-child, a {
                color: $primary !important;
            }
        }
    }

    &__banner.banner--anim {
        &.is-visible {
            img {
                top: -10%;
            }
        }
    }

    &__products.collection-header--anim {
        &.is-visible {
            img {
                transform: translate(-50%, -50%) scale(1.1);
            }
        }
    }
}

.collection-subcategories {
    text-align: center;

    &__show-more {
        margin: 30px 0;
    }

    .subcategories {
        display: flex;
        flex-wrap: wrap;
        padding: 20px 11px;
        margin-top: 60px;
        background-color: $lighter-grey;

        @include min-screen($screen__m) {
            padding: 20px 15px;
        }

        @include min-screen($screen__xl) {
            padding: 20px 55px;
        }

        .subcategory {
            width: calc(50% - 10px);
            margin: 20px 5px;

            @include min-screen($screen__m) {
                width: calc(25% - 10px);
            }

            &__title {
                font-family: $font-family;
                font-weight: 500;
                font-size: 18px;
                line-height: 1.44em;
                letter-spacing: 2px;
                text-transform: uppercase;
                margin-bottom: 1rem;
            }

            &__description {
                font-family: $font-family;
                font-weight: bold;
                font-size: 12px;
                line-height: 1.5em;
                letter-spacing: 2px;
                text-transform: uppercase;
            }
        }
    }
}

.filters {
    margin-top: 30px;
    margin-bottom: 5px;

    @include min-screen($screen__m) {
        margin-bottom: 0;
        padding: 0 20px;
    }

    @include min-screen($screen__xl) {
        margin-left: 3vw;
        margin-right: 3vw;
    }

    &-used {
        flex-grow: 1;

        .container {
            display: flex;
            flex-direction: column;
            height: 100%;

            @include min-screen($screen__m) {
                justify-content: center;
                flex-wrap: wrap;
                flex-direction: row;
                height: auto;
                .filters-actions {
                    display: none;
                }
            }

            .items {
                flex-grow: 1;
                display: flex;
                flex-direction: column;

                @include min-screen($screen__m) {
                    display: inline-block;
                    flex-grow: 0;
                    margin-right: 20px;
                }
            }
        }

        .filter-item {
            margin-top: 50px;
            @include min-screen($screen__m) {
                margin-top: 15px;
            }
        }

        .filters-actions {
            .filter-item,
            .filter-item + .filter-item {
                margin: 0;

                button {
                    margin: 0;
                    width: 100%;
                }
            }
        }
    }

    .filter-clear {
        @include max-screen($screen__m) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: $lighter-grey;

            padding: 16px;
            margin: 30px -16px 0 !important;
        }

        .btn-see-results {
            @include make-btn;

            max-width: 70%;
            background: $black;
            color: $white;

            @include min-screen($screen__m) {
                display: none;
            }

            &:hover {
                background: $black;
                color: $white;
            }
        }

        a {
            font-size: 1.2rem;
            padding-left: 15px;
            position: relative;
            text-transform: uppercase;
            display: inline-block;
            margin-right: 5px;

            @extend .icon-x;

            &::before {
                font-family: "icomoon";
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                display: inline-block;
            }
        }
    }

    .filter-item {
        align-items: flex-start;

        @include max-screen($screen__m) {
            padding-right: 0;

            .filters__wrapper {
                border-top: solid 1px $dark-grey2;
            }

            &:last-child {
                .filters__wrapper {
                    border-bottom: solid 1px $dark-grey2;
                }
            }
        }

        &__number {
            padding-left: .5rem;
        }

        &.checkbox-group {
            &:active, &:focus {
                outline: 1px dashed $red;
                outline-offset: 3px;
                box-shadow: none;
            }

            label {
                display: flex;
                flex: 1;
                font-size: 1.4rem;
                height: auto;
                line-height: inherit;
                margin-right: 0;
                max-width: 100%;
                overflow: hidden;
                padding-left: 0;
                text-overflow: inherit;
                white-space: inherit;
                transition: all 0.2s ease;

                .icon-checkbox {
                    position: relative;
                    top: 3px;
                    margin-right: 5px;
                }

                &:active, &:focus {
                    outline: 1px dashed $red;
                    outline-offset: 3px;
                    box-shadow: none;
                }
            }
        }

        input[type='checkbox'] {
            //display: inline-block;
            //margin-right: 1rem;
            //margin-top: .5rem;
            width: 1.6rem;
            height: 1.6rem;
            overflow: hidden;
            display: block;
            position: absolute;
            top: .7rem;
            left: 1.5rem;
            margin: 0;
            opacity: 0;

            &:focus {
                background-color: green;
            }

            &:checked {
                background-color: $red;

                & ~ label {
                    font-weight: $weight-bold;

                    .icon-checkbox {
                        border-color: $red;
                        background-color: $red;
                    }
                }
            }
        }

        & + .filter-item {
            margin: 30px 0 0;

            @include min-screen($screen__m) {
                margin: 0 0 0 20px;
                .filters__wrapper_items {
                    left: auto;
                    right: -1px;
                }
                .filters__wrapper_title {
                    border-left: solid 1px $dark-grey2;
                }
            }
        }
    }

    .filter-name {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 1.6rem;
        vertical-align: middle;
        display: inline-block;
    }

    .filter-value {
        margin-left: 10px;
        vertical-align: middle;
        display: inline-block;

        a {
            display: inline-block;
            padding: 2px 30px 2px 8px;
            border: 1px solid #e8e8e8;
            border-radius: 2px;
            background: #f8f8f8;
            position: relative;
            font-size: 1.2rem;
            //color: $dark-grey;
            transition: all $transition-02;

            @extend .icon-x;

            &::before {
                font-family: "icomoon";
                position: absolute;
                right: 8px;
                top: 50%;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                display: block;
                line-height: 12px;
                margin-top: -6px;
                font-size: 0.8rem;
                text-align: center;
                color: #9e9e9e;
                background: #e8e8e8;
                color: $dark-grey;
                transition: all $transition-02;
            }

            &:hover {
                color: $dark-grey;
                border: 1px solid $dark-grey;

                &::before {
                    color: $white;
                    background: $dark-grey;
                }
            }
        }
    }

    ///////////////////////////

    &-container {
        display: flex;
        flex-direction: column;

        .filter-item {
            position: relative;
            margin-top: 20px;
            width: 100%;

            label {
                position: relative;
                z-index: 2;

                text-transform: uppercase;
                font-size: 1.6rem;
                font-weight: 400;
                margin: 0 25px 0 0;
                background: white;
                height: auto;
                min-height: 30px;
                line-height: 30px;
                display: block;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                @include max-screen($screen__m) {
                    margin-left: 25px;
                }
            }

            .field-tooltip {
                z-index: 9;
                top: 3px;

                @include max-screen($screen__m) {
                    top: 7px;
                }
            }

            .select-styled {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;

                select {
                    color: transparent;
                }

                option {
                    color: $black;
                }

                @include max-screen($screen__m) {
                    .arrow {
                        left: 0;
                    }

                    .field-tooltip {
                        right: 0;
                        top: 3px;
                        z-index: 9;
                    }
                }
            }

            &.has-tooltip {
                .filters__wrapper_title {
                    padding-right: 6rem;

                    &::before {
                        right: 4rem;
                    }
                }

                .field-tooltip {
                    right: 1rem;
                }
            }
        }

        .filter-item + .filter-item {
            margin: 50px 0 0;
            //width: 100%;
        }

        @include min-screen($screen__m) {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            margin-left: -50px;

            .filter-item {
                margin: 10px 0 0;
                width: auto;

                + .filter-item {
                    margin: 10px 0 0;
                    //width: auto;
                }

                select, .select {
                    border-bottom: none;
                }

                label {
                    overflow: visible;
                    position: relative;
                }
            }
            .filter-title + .filter-item label:before {
                display: none;
            }
        }
    }

    &-inner {
        padding: 20px 0 50px;
        position: fixed;
        top: 100%;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 11111;
        overflow: scroll;
        background: $white;
        display: flex;
        flex-flow: column;
        transition: top $transition-02;

        @include min-screen($screen__m) {
            padding: 0;
            position: static;
            overflow: initial;
            display: block;
            &:not(.is-open) .btn-close{
                display: none;
            }
        }

        @include max-screen($screen__m) {
            padding-bottom: 100px;

            .filters-container .filter-item + .filter-item {
                margin: 0;
            }

            .filters-used {
                opacity: 0;
                visibility: hidden;
            }

            &.is-open .filters-used {
                opacity: 1;
                visibility: visible;
            }
        }

        &.is-open {
            top: 0;
        }

        .filter-item > .arrow {
            display: none;
        }

        .filters-actions {
            .filter-clear {
                display: none;
                position: absolute;
                bottom: 34px;
                padding: 10px 0 1.5rem;
                left: 0;
                width: 100%;
                margin: 0 !important;
                text-align: center;
                background-color: $white;
                flex-basis: 100%;
                max-width: 100%;

                .reset_filters {
                    display: block;
                }

                .btn {
                    border: solid 1px $black;
                    background-color: transparent;
                    padding-left: 30px;

                    &:before {
                        display: none;
                    }
                }
            }

            &.hasResetButton {
                @include max-screen($screen__m) {
                    .filter-clear {
                        display: block;
                    }
                }
            }
        }
    }

    &-available {
        & + .filters-used {
            bottom: 0;
            left: 0;
            position: fixed;
            width: 100%;
            z-index: 3;
        }
    }

    .btn-filters {
        &:not(.btn) { // Prevent modal button
            @include make-btn;
            @extend .-stroke;
            padding-left: 20px;
            padding-right: 20px;
            margin-left: 16px;

            span,
            svg {
                vertical-align: middle;
            }

            svg {
                margin-left: 20px;
            }
        }

        &.-sticky {
            bottom: 0;
            border-radius: 0;
            color: $white;
            font-size: 1.6rem;
            font-weight: $weight-bold;
            left: 0;
            letter-spacing: 0;
            margin: 0;
            position: fixed;
            padding: 1rem 5rem;
            width: 100%;
            z-index: 5;

            &::before {
                background-size: cover;
            }

            &::after {
                bottom: 1rem;
                content: '\e901';
                font-family: $font-icons;
                font-size: 2.5rem;
                position: absolute;
                right: 1rem;
                transform: rotate(180deg);
                z-index: 5;
            }
        }

        @include min-screen($screen__m) {
            display: none !important;
        }
    }

    .btn-close {
        position: absolute;
        top: 10px;
        right: 0;
        border: 0;
        background: transparent;
    }

    .visible-xs {
        display: none;
    }

    .filter-title {
        text-align: center;

        .title-h2 {
            margin-top: 0;
        }

        @include min-screen($screen__m) {
            display: none;
        }
    }

    // Collapse
    &__wrapper {
        width: auto;
        position: relative;
        z-index: 3;

        &_title {
            background-color: transparent !important;
            font-weight: $weight-regular;
            border: solid 1px white;
            border-radius: 0;
            cursor: pointer;
            display: inline-block;
            font-size: 1.4rem;
            margin-bottom: 0;
            padding: 1rem 4rem .5rem 3rem;
            position: relative;
            text-transform: uppercase;
            transition: border-color .3s ease;
            z-index: 3;
            @extend .icon-chevron-down;

            &::-moz-selection,
            &::selection {
                color: inherit;
                background: transparent;
            }

            &::before {
                font-family: $font-icons;
                position: absolute;
                right: 2rem;
            }

            &::after {
                content: '';
                background-color: transparent;
                position: absolute;
                bottom: -2px;
                height: 2px;
                width: 100%;
                left: 0;
                transition: background-color .3s ease;
                z-index: 3;
            }
        }

        &_items_wrapper {
            background-color: $white;
            border: solid 1px $white;
            left: 0;
            max-height: 60vh;
            min-width: 24rem;
            overflow: hidden;
            padding-top: 1rem;
            position: absolute;
            top: 0;
            transition: border-color .3s ease;
            width: 100%;
            z-index: 2;
        }

        &_content.collapse {
            opacity: 0;
            position: relative;
            transition: opacity .3s ease, visibility .3s ease;
            visibility: hidden;

            .filter-item { // prevent regression
                display: flex;
                flex-flow: row wrap;
                margin: 0;
                padding: .5rem 1rem;

                > .arrow {
                    display: none;
                }
            }

            .filters-actions {
                border-width: 1px 0 0;
            }

        }

        &.active {
            z-index: 4;

            .filters__wrapper {
                border-color: $dark-grey2 $dark-grey2 $white;
            }

            .filters__wrapper_title {
                font-weight: $weight-bold;
                margin-right: -1px;

                & ~ .collapse {
                    opacity: 1;
                    visibility: visible;

                    .filters__wrapper_items_wrapper {
                        border-color: $dark-grey2;
                    }
                }

                &::after {
                    background-color: $white;
                }
            }

            @include min-screen($screen__m) {
                .filters__wrapper_items_wrapper {
                    display: flex;
                    flex-direction: column;


                    .filters__wrapper_items {
                        position: relative;
                        flex: 1 0 auto;
                        max-height: 40vh;
                        overflow-y: auto;
                    }
                }
                .filters-actions {
                    position: sticky;
                    left: 0;
                    width: 100%;
                    z-index: 2;
                    margin-top: 5px;
                }
            }
        }

        .filters__wrapper_title {
            color: $black;

            &.bold {
                font-weight: $weight-bold;
            }
        }

        @include max-screen($screen__m) {
            &_title {
                width: 100%;
                text-align: left;
                border: none;
                display: block;
                padding: 1.5rem 4rem 1rem;

                &::before {
                    font-size: 2rem;
                    left: 1rem;
                    right: auto;
                }

                &::after {
                    display: none;
                }
            }

            &_items_wrapper {
                border: none;
                display: none;
                padding: 0 1rem 1rem;
                position: relative;
                width: calc(100% - 2rem);

                .filter-item > input[type="checkbox"] + label {
                    margin-left: .5rem;
                }

                .filters-actions {
                    display: none;
                }
            }

            &.active {
                .filters__wrapper_items_wrapper {
                    display: block;
                }
            }
        }
    }

    // Filters actions
    &-actions {
        align-items: center;
        box-sizing: border-box;
        border-collapse: collapse;
        border: solid 1px $dark-grey2;
        display: flex;
        justify-content: stretch;
        margin-top: 1rem;

        > * {
            flex: 0 0 50%;
            max-width: 50%;
        }

        button {
            background-color: $white;
            border: none;
            border-radius: 0;
            line-height: 1;
            min-height: 32px;
            padding: 1.2rem 2rem .8rem;
        }
    }
}

.reset_filters {
    margin: 10px 10px 0;

    @include max-screen($screen__m) {
        display: none;
    }
}


.block-cta {
    margin-bottom: 20px;
}

.block-cta-left {
    @include min-screen($screen__m) {
        .text-media__text {
            order: 1 !important;
        }

        .text-media__media {
            order: 0 !important;
        }
    }
}

.block-cta-right {
    @include min-screen($screen__m) {
        .text-media__text {
            order: 0 !important;
        }

        .text-media__media {
            order: 1 !important;
        }
    }
}

.catalog-category-view {
    .page-wrapper {
        padding-top: 60px;
    }
}

.product-retailers-pop {
    width: 332px;
    position: absolute;
    background-color: #ffffff;
    border: 1px solid #7777;
    z-index: 2;
    box-sizing: border-box;
    margin-top: 0px;
    margin-bottom: 10px;
    padding: 5px;

    &__close {
        width: 332px;
    }

    .retailer-media {
        width: 49%;
        display: inline-block;
    }

    .retailer-link {
        width: min-content;
    }
}

.narrow {
    max-width: 652px;
}
