.maintenance {
    &__steps {
        max-width: $width-medium;
        margin: 0 auto;
        padding: 50px 20px;

        ul {
            margin: 0;
            padding: 0;
        }

        &-item {
            list-style: none;
            padding: 0 0 100px 0 !important;
            margin: 0;
            text-align: center;
            position: relative;

            &:after {
                content: "";
                height: 100px;
                width: 2px;
                background: $light-grey;
                position: absolute;
                bottom: 0;
                left: calc(50% - 1px);
            }

            &:before {
                display: none;
            }

            p {
                @extend .text-default;
            }

            img {
                display: inline-block;
                margin: 20px 10px;
            }

            a {
                color: $primary;
                display: inline-block;
                position: relative;

                &:after {
                    content: "";
                    height: 1px;
                    position: absolute;
                    left: 0;
                    right: 100%;
                    background-color: $primary;
                    bottom: 3px;
                    transition: right $transition-fast;
                }

                &:hover {
                    &:after {
                        right: 0;
                    }
                }
            }
        }
    }

    &__img {
        img {
            display: block !important;
            width: 100%;
        }
    }

    &__table {
        overflow: hidden;

        @include min-screen($screen__m) {
            border: 1px solid $lighter-grey;
            padding: 10px;
            margin: 0 20px 40px;
        }

        @include min-screen($screen__xl) {
            padding: 20px 4vw;
        }

        @include max-screen($screen__m) {
            font-size: 1.2rem;
            background: $lighter-grey;
        }

        tr:nth-child(2n) {
            background-color: $lighter-grey;
        }

        th {
            text-align: left;
            color: $light-grey;
            font-size: 1.4rem;
            vertical-align: middle;
            font-weight: 400;

            @include max-screen($screen__m) {
                width: 55%;
                padding-left: 16px;
            }

            @include min-screen($screen__m) {
                text-align: right;
                font-weight: 500;
            }
        }

        td {
            text-align: center;
            vertical-align: middle;

            @include min-screen($screen__m) {
                width: 27%;

                &:first-child {
                    width: 19%;
                }
            }
        }

        .titles-mob {
            background: white !important;

            th {
                padding-left: 5px;
                padding-right: 5px;
                text-align: center;
                font-size: 1rem;
                width: auto;
                font-weight: 300;
                text-transform: uppercase;

                &:last-child {
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            @include min-screen($screen__m) {
                height: 0px;
                overflow: hidden;

                span {
                    text-indent: 9999px;
                    width: 0;
                    overflow: hidden;
                    display: block;
                    height: 0;
                }
            }
        }

        .highlight {
            border-top: 1px solid $primary;
            border-bottom: 1px solid $primary;

            p {
                display: none;

                @include min-screen($screen__m) {
                    display: block;
                }
            }

            p + i {
                display: inline-block;

                @include min-screen($screen__m) {
                    display: none;
                }
            }

            sup {
                display: inline-block;
                margin-top: -2px;
                vertical-align: bottom;
            }
        }

        .maintenance__descriptions {
            @include max-screen($screen__m) {
                font-size: 1.6rem;
                display: flex;
                flex-flow: column;
            }

            &:nth-child(n) {
                background: white;
            }

            th {
                text-align: left;
                color: black;
                font-weight: normal;
                font-size: 1.6rem;
                width: 27%;
                vertical-align: top;
                padding-bottom: 20px;

                &:first-child {
                    width: 19%;
                }

                @include max-screen($screen__m) {
                    display: block;
                    width: 100%;
                    padding-left: 16px;
                    padding-right: 16px;

                    &:first-child {
                        display: none;
                    }
                }

                @include min-screen($screen__m) {
                    &:nth-child(2) {
                        @include min-screen($screen__m) {
                            .title {
                                position: relative;

                                &::after {
                                    content: "";
                                    bottom: 0;
                                    height: 1px;
                                    background: $primary;
                                    width: calc(300% + 40px);
                                    left: 0;
                                    position: absolute;
                                }
                            }
                        }
                    }

                    &:first-child {
                        p {
                            padding-top: calc(145% + 5rem + 10px);
                            text-align: right;
                            color: $light-grey;
                            font-size: 1.4rem;
                            font-weight: 500;
                            line-height: 1.9em;
                        }
                    }
                }

                @include min-screen($screen__m) {
                    font-size: 1.4rem;
                }

                @include min-screen($screen__xl) {
                    font-size: 1.6rem;
                }
            }
        }

        .title {
            text-transform: uppercase;
            font-size: 1.4rem;
            font-weight: bold;
            display: flex;
            height: 5rem;
            align-items: flex-end;

            @include min-screen($screen__m) {
                font-size: 1.2rem;
            }

            @include min-screen($screen__xl) {
                font-size: 1.4rem;
            }

            @include max-screen($screen__m) {
                border-bottom: 1px solid $primary;
                height: auto;
                margin-top: 20px;
            }
        }

        .icon-x,
        .icon-check {
            font-size: 2rem;
            color: black;
            margin-top: 4px;
            display: inline-block;
        }

        .icon-x {
            color: $primary;
        }

        .btns-container {
            text-align: center;
            padding: 25px 0;

            @include min-screen($screen__m) {
                margin-left: 19%;
            }
        }

        .legend-container {
            font-size: 1rem;
            padding: 0 16px 16px;

            @include min-screen($screen__m) {
                margin-left: 19%;
                padding: 0;
            }
        }

        .prices {
            text-align: center;
            font-size: 1rem;
            color: black;

            td {
                padding-left: 5px;
                padding-right: 5px;
                white-space: nowrap;
            }

            @include min-screen($screen__m) {
                font-size: 1.2rem;
            }

            strong {
                display: block;
                font-size: 1.2rem;
                text-transform: uppercase;
                font-weight: 500;
                line-height: 1em;
                white-space: nowrap;

                @include min-screen($screen__m) {
                    font-size: 1.8rem;
                }
            }
        }
    }
}
