::-moz-focus-inner {
    border: 0;
}

input[type="text"],
input[type="number"],
input[type="email"],
input[type="date"],
input[type="password"],

select {
    border: 1px solid $border-color-form;
    height: 56px;
    width: 100%;
    padding: 0 10px 0 15px;
    font-family: $font-family;
    font-size: 1.6rem;
    font-weight: 300;
    color: $dark-grey;
    background: $white;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    filter: none;

    &::placeholder {
        color: #757575;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus{
        -webkit-box-shadow: 0 0 0 1000px $white inset;
        -webkit-text-fill-color: $dark-grey;
    }
}
.fieldset {
    .control {
        position: relative;

        .label {
            position: absolute;
            top: -10px;
            left: 10px;
            padding: 0 5px;
            background-color: white;
            text-transform: uppercase;
            font-size: 10px;
            color: #202020;
        }

        input, textarea {
            border: 1px solid $border-color-form;
        }
    }
    .is-focus {
        .control {
            input, textarea, select {
                border: 1px solid $border-active-color;
            }
        }
    }
}

.form-group {
    width: 100%;
    position: relative;
    margin: 30px 0 0;

    @include min-screen($screen__m) {
        width: calc(50% - 30px);
        margin: 60px 15px 0;

        &.fieldset-jp-postcode {
            width: 90%;
        }
    }

    .control-label {
        position: absolute;
        top: 28px;
        left: 15px;
        background: $white;
        color: $light-grey;
        font-size: 1.6rem;
        transform: translateY(-50%);
        padding: 0 5px;
        font-weight: 300;
        text-transform: uppercase !important;
        z-index: 1;
        transition: all $transition-fast;
    }

    &._disabled,
    &.is-focus,
    &.has-value {
        .control-label {
            top: -2px;
            font-size: 1rem;
            text-transform: uppercase;
            color: $black;
        }
    }

    &.field-mask {
        input {
            letter-spacing: 2px;
        }
    }

    small {
        font-size: 1rem;
        text-transform: uppercase;
        display: block;
        margin-bottom: 30px;
    }

    .btn {
        width: 100%;

        @include min-screen($screen__m) {
            width: auto;
        }
    }
}

.custom-select-2,
.select-styled {
    position: relative;

    .arrow{
        font-size: 19px;
        line-height: 17px;
        content: "\e901";
        font-family: 'icomoon';
        speak: none;
        position: absolute;
        right: 0;
        top: 5px;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }



    select {
        font-family: $font-family;
        border: 0;
        border-bottom: 1px solid $border-color;
        text-transform: uppercase;
        font-size: 1.6rem;
        font-weight: 300;
        height: auto;
        padding: 0 20px 0 0;
        color: $dark-grey;
        border-radius: 0;
        background: transparent;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        width: 100%;
        position: relative;
        z-index: 2;
    }

    .validation-advice {
        top: 35px;
        left: 0;
    }

    .sorter-options {
        -webkit-appearance: none!important;
        appearance: none!important;
    }
}

.select-group {
    position: relative;

    @extend .icon-chevron-down;

    &:before {
        font-family: "icomoon";
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
    }

    select {
        text-transform: none;
        height: 56px;
        border-radius: 0;
        background: transparent;
        width: 100%;
        position: relative;
        z-index: 2;
    }

    .control-label {
        z-index: 3;
    }
}

.tissot-m2, .checkout-index-index {
    .select-group {
        &:before {
            top: 28px;
        }
    }
}

.checkbox-group {
    label {
        font-size: 1.4rem;
        text-transform: uppercase;
        position: relative;
        padding-left: 25px;
        cursor: pointer;

        .icon-checkbox {
            display: block;
            position: absolute;
            top: 2px;
            left: 0;
            width: 1.6rem;
            height: 1.6rem;
            border-radius: 2px;
            background-color: $white;
            border: 1px solid $border-color;
            transition: all $transition-fast;

            @extend .icon-check;

            &:before {
                font-family: "icomoon";
                position: absolute;
                top: 0;
                left: 0;
                color: $white;
            }
        }

        &.disabled {
            color: $light-grey;
        }

        input[type="checkbox"] {
            width: 10px;
            height: 10px;
            overflow: hidden;
            display: block;
            position: absolute;
            top: 5px;
            left: 3px;
            margin: 0;
            opacity: 0;

            &:checked + .icon-checkbox {
                border-color: $primary;
                background-color: $primary;
            }

            &:checked:disabled + .icon-checkbox {
                border-color: $light-grey;
                background-color: $light-grey;
            }


            &:focus {
                outline-offset: 5px;
            }
        }
    }
}

.radio-group {
    position: relative;
    .icon-radio:before {
        content: "";
        display: block;
        position: absolute;
        width: 1.6rem;
        height: 1.6rem;
        top: 2px;
        left: 0;
        background-color: $white;
        border: 1px solid $border-color;
        border-radius: 50%;

    }

    .icon-radio:after {
        content: "";
        display: none;
        position: absolute;
        width: 0.8rem;
        height: 0.8rem;
        top: 4px;
        left: 2px;
        background-color: $primary;
        border: 3px solid $white;
        border-radius: 50%;
    }


    input {
        opacity: 0;

            &:checked + .icon-radio:after {
                display: block;
            }

            &:checked + .icon-radio:before {
                border-color: $primary;
            }
    }
}

.radio-group-custom-form {
    position: relative;

    .label-custom-form{
        display: flex;
        margin-left: -18px;
        span{
            margin: -2px 0 4px 5px;
            font-size: 1em;
            font-weight: 300;
            padding-bottom: 4px;
        }
    }
    .icon-radio:before {
        content: "";
        display: block;
        position: relative;
        width: 1.6rem;
        height: 1.6rem;
        top: 2px;
        left: 0;
        background-color: $white;
        border: 1px solid $border-color;
        border-radius: 50%;

    }

    .icon-radio:after {
        content: "";
        display: none;
        position: relative;
        width: 0.8rem;
        height: 0.8rem;
        top: -14px;
        left: 2px;
        background-color: $primary;
        border: 3px solid $white;
        border-radius: 50%;
    }


    input {
        opacity: 0;

        &:checked + .icon-radio:after {
            display: block;
        }

        &:checked + .icon-radio:before {
            border-color: $primary;
        }
    }
}


.textarea-group,
.captcha-group,
.checkbox-group {
    .validation-advice {
        position: static;
        padding-left: 20px;
    }
}

// placeholder
::-webkit-input-placeholder,
:-moz-placeholder,
:-ms-input-placeholder,
::-moz-placeholder {
    text-transform: uppercase;
    color: $dark-grey;
    font-size: 1.6rem;
    font-weight: 300;
}

// error messages
.form-group label + .mage-error,
.form-group textarea + .mage-error,
.form-group input + .mage-error,
.form-group select + .mage-error,
.field input + .mage-error,
.field select + .mage-error,
.validation-advice {
    text-transform: uppercase;
    color: $primary !important;
    font-size: 1rem;
    position: relative;
}

.validation-error .control-label {
    color: $primary !important;
}

input.mage-error,
select.mage-error,
textarea.mage-error {
    border-color: $primary !important;
}

.form.send.confirmation{
    text-align: center;
    margin: 0 auto;
    padding-top: 20px;

    .actions-toolbar {
        .primary button{
            @include make-btn-primary;
        }
        .secondary button{
            @include make-btn-secondary;
        }
    }



}
