.stor-selector {
    @include min-screen($screen__m) {
        display: flex;
        margin: 0 0 0 10px;
    }

    @include min-screen($screen__l) {
        margin: 0 0 0 20px;
    }

    @include min-screen($screen__xl) {
        display: block;
        text-align: center;
        margin: 0;
        padding-bottom: 20px;
    }

    &.stor-selector--international {
        @include min-screen($screen__xl) {
            padding-bottom: 0;
        }
    }

    &.stor-selector--other-country {
        flex-direction: column;

        .stor-selector__block {
            margin: auto;
            padding-bottom: 48px;
            width: fit-content;

            .btn {
                .icon-flag {
                    width: 2rem;
                    height: 2rem;
                    margin: -4px 0;
                }
            }
        }
    }

    a:hover {
        color: $primary;
    }

    // @include min-screen($screen__m) {
    //     padding: 0 80px;
    // }

    &__title {
        @extend .title-h2;
        text-align: center;
        margin-bottom: 15px;
        display: block;
    }

    &__description {
        text-align: center;
        margin-bottom: 20px;
        margin-top: -20px;
        display: block;
    }

    &__column {

        margin-left: 16px;
        margin-right: 16px;

        @include min-screen($screen__m) {
            width: 33.33%;
            padding: 0 10px;
            margin-left: 0;
            margin-right: 0;
        }

        @include min-screen($screen__xl) {

            width: 300px;
            display: inline-block;
            vertical-align: top;
            text-align: left;
            margin: 0 20px;
            padding: 0;
        }

        .stor-selector__column-list li a:not(:first-child) .country-flag,
        .stor-selector__column-list li a:not(:first-child) .ecommerce-flag,
        .stor-selector__column-list li .separator:last-child {
            display: none;
        }
    }

    &__column-title {
        text-transform: uppercase;
        font-size: 1em;
        font-weight: 500;
        letter-spacing: 2px;
        color: $black;
        margin-bottom: 20px;
        margin-top: 20px;
    }
    &__column-title:nth-child(3) {
        margin-top: 100px;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            margin-bottom: 0.5rem
        }
    }

    &__column-list {
        columns: 1;
        column-gap: 20px;

        a {
            display: inline-block;
            padding-left: 24px;
            position: relative;
            color: $dark-grey;

            @include min-screen($screen__m) {
                padding-left: 30px;
            }
        }
        a:not(:first-child) {
            padding-left: 0px;
        }
        a:not(:last-child){
            padding-right: 7px;
        }

    }

    .country-name,
    .country-flag {
        vertical-align: middle;
        display: inline-block;
    }

    .country-name {
        font-size: 0.875em;
        font-weight: 400;
        margin-left: 5px;

        @include min-screen($screen__m) {
            font-size: 1.125em;
        }
    }

    .country-flag {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        overflow: hidden;
        margin-top: -2px;

        @include min-screen($screen__m) {
            height: 16px;
            width: 16px;
        }

        &.country-flag--international {
            overflow: visible;
        }

        .icon-flag {
            width: 2rem;
            height: 2rem;

            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            @include min-screen($screen__m) {
                width: 2.2rem;
                height: 2.2rem;
            }
        }

        .icon-flag--flag-international {
            width: 14px;
            height: 14px;
            stroke: black;
            top: 30%;

            @include min-screen($screen__m) {
                width: 18px;
                height: 18px;
                top: 50%;
            }
        }
    }

    .ecommerce-flag {
        position: absolute;
        top: 2px;
        left: 0;

        @include min-screen($screen__m) {
            top: 4px;
        }

        svg {
            color: transparent;
            stroke: black;
            stroke-width: 0.3px;
            width: 1.6rem;
            height: 1.6rem;

            @include min-screen($screen__m) {
                width: 1.8rem;
                height: 1.8rem;
            }
        }
    }
    &__wrapper {
        display: flex;
        flex-direction: row;
        padding: 30px 120px;
        justify-content: center;

        @include max-screen($screen__xl) {
            padding: 30px 80px;
        }
        @include max-screen($screen__l) {
            padding: 30px;
        }
        @include max-screen($screen__m) {
            display: block;
            padding: 0;
        }

        .stor-selector__column {
            display: flex;
            justify-content: center;
        }

        .stor-selector__image-container {
            max-width: 420px;
            margin: auto;

            img {
                max-width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .modal-popup.modal-slide {
        left: 0;
    }

    .modal-popup.modal-slide .modal-inner-wrap[class] {
        background: white;
    }

    .modal-popup.modal-slide._inner-scroll .modal-inner-wrap{
        height: 100%;
    }

    .modal-slide .action-close, .modal-popup .action-close {
        top: 0;
        right: 0;
    }

    .stor-selector__column-list li {
        width: 220px;
    }
}

.stor-selector__ecommerce{
    margin-bottom: 0.3rem;
}
