.global-message-container .messages {
    ul {
        li {
            margin-bottom: 0;
        }

        li + li {
            margin-top: 10px;
        }
    }

    .error-msg {
        font-size: 1em;
    }
}

.products-section .message,
.page.messages .message {
    text-align: center;

    &.error,
    &.success,
    &.warning,
    &.notice {
        > div::before {
            display: none !important;
        }

        padding: 15px;
        border-style: solid;
        border-width: 1px;
    }

    &.error {
        border-color: #ebccd1;
        background-color: #f2dede;
        color: #a94442;
    }

    &.success {
        background-color: #dff0d8;
        border-color: #d6e9c6;
        color: #3c763d;
    }

    &.warning {
        background-color: #fdf0d5;
        border-color: #FAD894;
        color: #6f4400;
    }

    &.notice {
        background-color: #fdf0d5;
        border-color: #fee0b5;
        color: #6f4400;
    }
}

.cms-home {
    #maincontent {
        .page.messages {
            position: relative;
            .message {
                position: absolute;
                top: 120px;
                width: 100%;
                z-index:1;
            }
        }
    }
}

.message.global.noscript{ // javascript disabled message
    display: none;
}
