%icon_chevron{
    background-color: $primary;
    border-radius: 50%;
    color: $white;
    height: 36px;
    overflow: hidden;
    width: 36px;
    top: 43%;
    &:before{
        font-size: 15px;
        line-height: 1;
    }
    &:after{
        display: none;
    }
    @include min-screen($screen__l) {
        &:not(.swiper-button-disabled) {
            opacity: 1;
            visibility: visible;
        }
    }
}

.swiper-container {
    padding-bottom: 30px;
    background: white;
    max-width: 100vw;
    overflow: hidden;
    padding: 0 15px 23px;
    position: relative;
    @include min-screen($screen__m) {
        padding: 0 23px 23px;
    }

    @include min-screen($screen__xl) {
        padding: 0 calc(3vw + 20px) 23px;
    }

    .swiper-wrapper {
        margin-bottom: 0px;

        @include min-screen($screen__m) {
            margin-bottom: 80px;
        }
    }

    .swiper-scrollbar {
        height: 23px;
        width: 200px;
        max-width: 100%;
        background: #bdbdbd;
        cursor: pointer;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        border-top: 10px solid white;
        border-bottom: 10px solid white;
        border-radius: 0;

        @include min-screen($screen__m) {
            width: 656px;
            bottom: 60px;
        }

        &-drag {
            background: $primary;
            height: 23px; //3px;
            position: absolute;

            top: -10px;
            border-top: 10px solid white;
            border-bottom: 10px solid white;
            border-radius: 0;

            span {
                display:none;
            }
        }
        &:empty{
            display: none;
        }
    }

    .swiper-slide {
        width: auto;
        opacity: 0.6;
        height: auto;
        position: relative;

        .products-slider & {
            min-width: 164px;
            opacity: 1;
        }

        .pagebuilder-overlay {
            padding: 0 !important;
        }

        .pagebuilder-poster-content {
            max-width: 75vw;
        }

        &.is-hoverable {
            &::after {
                content: "";
                position: absolute;
                left: 0;
                width: 0%;
                height: 3px;
                background-color: $primary;
                top: calc(100% + 10px);
                transition: width $transition-fast;
            }

            &:hover {
                &::after {
                    width: 100%;
                }
            }
        }

        transition: opacity 0.3s ease-in;

        //&:not(.swiper-slide-visible) {
        //    opacity: 0;
        //}

        @include min-screen($screen__m) {
            opacity: 1;
        }

        &:last-child {
            margin-right: 0px !important;
        }

        div[data-element] {
            display: flex;
            height: 100%;
            align-items: normal;
        }

        &-active {
            opacity: 1;
        }

        &--media {
            display: flex;
            align-items: center;
            // max-width: 80vw;

            // @include min-screen($screen__m) {

            // }
        }

        &--text-only,
        &--text {
            max-width: 80vw;
            padding: 0 10px;

            display: flex;
            flex-flow: column;
            justify-content: center;

            @include min-screen($screen__m) {
                padding: 0 40px;
                max-width: 680px;
            }

            @include min-screen($screen__xl) {
                padding: 0 120px;
                max-width: 800px;
            }
        }

        &__text {
            @extend .text-media__text;
            width: calc(45% - 10px);

            @include max-screen($screen__m) {
                width: 70vw;
                padding-left: 20px;
            }
        }

        &__label {
            @extend .text-media__label;
        }

        &__title,
        &__description {
            color: white;
        }

        &__title {
            @extend .text-media__title;
        }

        &__description {
            @extend .text-media__description;
        }

        &__overlay {
            @extend .text-media__overlay;
        }

        &__video {
            @extend .text-media__video !optional;
            padding-top: 56.25%;
            position: relative;
            min-width: 80vw;

            @include min-screen($screen__m) {
                min-width: 640px;
            }

            iframe {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100% !important;
                height: 100% !important;
            }
        }
    }

    .swiper-pagination {
        display: none;
    }

    .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        display: inline-block;
    }

    .swiper-notification {
        text-indent: -9999999px;
        overflow: hidden;
        height: 0;
        width: 0;
        display: block;
    }

    .swiper-button-prev,
    .swiper-button-next {
        position: absolute;
        top: 50%;
        width: 60px;
        height: 60px;
        transform: translateY(-50%);
        border: 0;
        background: transparent;
        z-index: 10;
        opacity: 0;
        transition: all $transition-fast;
        overflow: hidden;

        &:focus {
            opacity: 1;
        }
    }

    .swiper-button-prev {
        left: 0;

        &:focus {
            left: 20px;
        }
        &.icon-chevron-left{
            @extend %icon_chevron;
        }
    }

    .swiper-button-next {
        right: 0;

        &:focus {
            right: 20px;
        }
        &.icon-chevron-right{
            @extend %icon_chevron;
        }
    }
}

.bg-black {
    .swiper-container {
        background: black;
        color: white;

        padding: 50px 0 90px;

        @include min-screen($screen__m) {
            padding: 100px 0 170px;
        }
    }

    .swiper-scrollbar {
        border-color: black;
        bottom: 40px;

        @include min-screen($screen__m) {
            bottom: 90px;
        }

        &-drag {
            border-color: black;
        }
    }
}

.products-slider {
    .swiper-slide {
        &:first-child {
            padding-left: 0;

            @include min-screen($screen__m) {
                padding-left: 0;
            }
        }

        &:last-child {
            padding-right: 0;

            @include min-screen($screen__m) {
                padding-right: 0;
            }
        }
    }
}

.slick-arrow { // For Page Builder slider use
    @extend %icon_chevron;
    background-color: $primary !important;
    color: $white !important;
    z-index: 9;
    &::before{
        color: inherit !important;
        font-family: $font-icons !important;
    }
    &.slick-prev {
        @extend .swiper-button-prev;

        &::before {
            content: '\e902';
            padding: 0;
        }

        &:focus {
            left: 20px;
        }
    }

    &.slick-next {
        @extend .swiper-button-next;

        &::before {

            content: '\e903';
            padding: 0;
        }

        &:focus {
            right: 20px;
        }
    }
    &.slick-disabled{
        opacity: 0 !important;
    }
}

.slick-dots li {
    &:before{
        display: none;
    }
    button{
        background: $dark-grey;
        border: none;
        transition: background .2s;
    }
    button:hover,
    &.slick-active button{
        background: $primary;
        border: none;
    }
}
