
.find-service-center {
    background: url("../images/logo/world-backgrounrd-big.png") no-repeat;
    background-size: cover;

    width: 100%;
    max-width: 1440px;
    height: 55vw;
    max-height: 800px;
    margin: auto;

    padding-top: 2vw;
}
@media (min-width: 768px) {
    .find-service-center {
        padding-top: 6vw;
    }
}

.find-service-center__title {
    width: 100%;
    text-align: center;
}
.find-service-center__title h1 {
    text-transform: uppercase;

    font-weight: bold;
    font-size: 36px;
}

@media (min-width: 768px) {
    .find-service-center__title h1 {
        font-size: 42px;
    }
}
@media (min-width: 992px) {
    .find-service-center__title h1 {
        font-size: 56px;
    }
}

.find-service-center__content {
    width: 100%;
    margin: 32px auto;
    padding-left: 15px;
    padding-right: 15px;
}
@media (min-width: 768px) {
    .find-service-center__content {
        width: 64%;
    }
}
@media (min-width: 992px) {
    .find-service-center__content {
        width: 32%;
    }
}

.find-service-center__content select {
    display: inline-block;
    width: 75%;
}
.find-service-center__content button {
    display: inline-block;
    width: 20%;
    height: 38px;
    margin-left: 3%;

    background-color: #000000;
    color: #FFFFFF;
}
.find-service-center__content button span {
    display: none;
}
