.stop-fake {
    margin: auto;
    padding-right: 18px;
    padding-bottom: 50px;
    padding-left: 18px;
    font-size: 1.8rem;
    font-weight: 300;

    @include min-screen($screen__m) {
        max-width: $width-large;
        padding-right: 0;
        padding-left: 0;
    }

    .stop-fake__title {
        margin-bottom: 15px;
        text-align: center;
    }

    .stop-fake__authorised-retailer {
        margin-bottom: 27px;
        text-align: center;
    }

    .stop-fake__subtitle {
        &--margin-top {
            margin-top: 200px;
        }

        & + .stop-fake__section-title {
            margin-top: 10px;
        }
    }

    .stop-fake__input-container {
        position: relative;

        button {
            position: absolute;
            top: 5px;
            right: 5px;
            border: 0;
            background: transparent;
            padding: 10px;
            display: block;
        }

        .icon {
            transform: rotate(90deg);
        }
    }

    .stop-fake__input {
        height: 50px;
        padding-right: 35px;
    }

    .stop-fake__submit-button {
        @extend .btn;
        @extend .-primary;
        margin-top: 30px;
        width: 100%;

        @include min-screen($screen__m) {
            width: auto;
        }
    }

    .stop-fake__message,
    .stop-fake__message-icon {
        display: flex;
    }

    .stop-fake__message {
        align-items: center;
        margin-top: 30px;
        font-size: 1.6rem;
        font-weight: 500;
        letter-spacing: 2px;
        text-transform: uppercase;
    }

    .stop-fake__message-icon {
        align-self: flex-start;

        svg {
            margin-right: 20px;
        }
    }

    .stop-fake__success {
        .stop-fake__message {
            color: $green;
        }

        .stop-fake__message-icon {
            padding-top: 2px;
        }
    }

    .stop-fake__failure {
        .stop-fake__message {
            color: $primary;
        }

        .stop-fake__message-icon {
            padding-top: 5px;
        }
    }

    .stop-fake__warning {
        display: block;
        margin-top: 40px;
        margin-bottom: 60px;
    }

    .stop-fake__section-title {
        margin-top: 50px;
        font-weight: bold;
    }

    .stop-fake__icon {
        & + h2 {
            @extend .title-h2;
            margin-top: 0;
        }
    }
}
