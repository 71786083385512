.account-login {

    h1 {
        @extend .title-h2;
        text-align: center;
    }
}

.acc-form {
    h2 {
        @extend .title-h4;
    }
}