.pager {
    display: flex;
    justify-content: center;
    margin-top: 50px !important;
    padding-left: 0;

    .pager__item {
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;

        & + .pager__item {
            margin-left: 10px;
        }

        &:last-of-type:hover .icon {
            transform: translateX(3px);
        }

        a {
            display: inline-block;
            width: 100%;
            height: 100%;
            color: black;
            transition: all .3s;

            &:hover {
                color: $primary;

                .icon {
                    fill: $primary;
                    transform: translateX(-3px);
                }
            }
        }

        .icon {
            fill: black;
            vertical-align: middle;
            transition: transform .3s;
        }
    }

    .pager__item--selected {
        color: white;
        background-color: $primary;
    }
}
