.magento_rma-guest-returns, .sales-guest-form {
    .page-main {
        .columns {
            margin-left: auto;
            margin-right: auto;
            padding: 25px 15px;

            @include min-screen($screen__xl) {
                max-width: $screen__xl;
            }

            @include screen($screen__l, $screen__xl) {
                max-width: $screen__l;
            }

            @include screen($screen__m, $screen__l) {
                max-width: $screen__m;
            }
        }
    }
}

.sales-guest-view, .sales-order-view {
    .page-title {
        .actions-toolbar {
            margin-bottom: 30px;

            @include min-screen($screen__l) {
                flex: 0.5;
                text-align: right;

                .btn {
                    margin-left: 10px;
                }
            }

            @include max-screen($screen__l) {
                .btn {
                    width: 100%;
                    margin-bottom: 15px;
                }
            }
        }
    }
}

.magento_rma-guest-create,
.magento_rma-returns-create,
.magento_rma-guest-returns,
.magento_rma-returns-history,
.magento_rma-guest-view,
.magento_rma-returns-view,
.sales-guest-form
{
    .page-title {
        @include min-screen($screen__l) {
            display: flex;
        }

        h1 {
            @include min-screen($screen__l) {
                flex: 1;
            }

            @extend .title-h1;
        }

        .actions-toolbar {
            margin-bottom: 30px;

            @include min-screen($screen__s) {
                flex: 0.5;
                text-align: right;
            }

            @include max-screen($screen__s) {
                .btn {
                    width: 100%;
                    margin-bottom: 15px;
                }
            }
        }
    }

    .ajax-loader {
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;

        .ajax-loader-background {
            background-color: #fff;
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0.5;
        }

        .ajax-loader-img-container {
            position: absolute;
            left: calc(50%);
            top: calc(50%);
            transform: translateX(-50%) translateY(-50%);
            width: 125px;
            height: 125px;
        }
    }

    .modal-popup {
        &.confirm {
            .modal-inner-wrap {
                max-width: 73rem;

                .modal-content {
                    padding: 0 3rem;
                }
            }
        }
    }

    .form {
        div.form-group {
            width: calc(100% - 30px);
            margin-top: 30px;
        }

        div.actions-toolbar {
            margin-left: 0;
            margin-right: 0;
            margin-top: 30px;
        }

        .fieldset {
            .block-title {
                margin-top: 0;
                margin-bottom: 2rem;
                font-size: 16px;
                line-height: 23px;
                letter-spacing: 2px;
                text-transform: uppercase;

                button.add {
                    margin-right: 15px;
                }
            }
        }

        #registrant-options {
            display: flex;
            flex-wrap: wrap;

            div.fields.additional {
                flex: 1 1 450px;
                padding: 15px;

                @include min-screen($screen__xl) {
                    border-bottom: 1px solid $light-grey2;

                    &:nth-child(even) {
                        border-left: 1px solid $light-grey2;
                    }
                }

                div.form-group {
                    width: 100%;
                    margin-left: 0;
                    margin-right: 0;

                    &:first-child {
                        margin-top: 0;
                    }
                }

                div.actions-toolbar {

                    &.delete {
                        text-align: right;
                        margin-right: 15px;
                    }
                }
            }
        }
    }

    .block {
        .block-content {
            .box-content {
                table.table-information {
                    tbody {
                        tr {
                            td {
                                padding-top: 0;
                                padding-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .acc-block-recent-return {
        margin-bottom: 15px;

        .page-title > h1 {
            @extend .title-h1;
        }

        .acc-block-content {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .pages {
                display: flex;

                .toolbar-amount {
                    align-self: center;
                    padding: 0;

                    @include min-screen($screen__xs) {
                        flex: 1;
                    }

                    @include max-screen($screen__xs) {
                        width: 35%;
                    }
                }

                .limiter {
                    @include min-screen($screen__xs) {
                        flex: 1;
                        text-align: right;
                    }

                    @include max-screen($screen__xs) {
                        width: 65%;
                    }
                }
            }

            .table {
                margin: 1.5rem 0;
            }

            .table-return-items {
                &.history,
                &.recent,
                &.return-summary {
                    td.col.subtotal, th.col.subtotal {
                        text-align: right;
                    }

                    margin: 1.5rem 0;

                    thead {
                        th {
                            background-color: $lighter-grey;
                        }
                    }

                    thead th,
                    tbody td {
                        text-align: center;
                    }

                    tbody {
                        tr {
                            td {
                                &.id,
                                &.total span {
                                    font-weight: bold;
                                }

                                &.status {
                                    font-style: italic;
                                }

                                &.actions a span {
                                    text-decoration: underline;
                                }
                            }
                        }
                    }

                    @include max-screen($screen__l) {
                        thead,
                        tfoot th {
                            display: none;
                        }

                        tbody tr,
                        tfoot {
                            border: 1px solid $light-grey2;
                            margin-bottom: 1.5rem;
                        }

                        tbody td,
                        tfoot tr {
                            &:not(:last-child) {
                                border-bottom: 1px solid $lighter-grey !important;
                            }
                        }

                        tbody,
                        tfoot {
                            tr {
                                display: flex;
                                flex-direction: column;

                                td {
                                    text-align: right;
                                    position: relative;
                                    vertical-align: middle;

                                    &::before {
                                        content: attr(data-th);
                                        font-weight: bold;
                                        float: left;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .form-tracking {
                .form-group {
                    margin-top: 0;
                    text-align: center;
                }
            }
        }
    }

    .acc-block-return-comments {
        display: none;

        .acc-block-content {
            .return-comments-container {
                display: flex;
                flex-wrap: wrap;

                .form-returns-comments {
                    flex: 1 1 450px;

                    div.form-group {
                        margin: 0 0 0 15px;
                        width: calc(100% - 15px);

                        @include max-screen($screen__xl) {
                            margin: 30px 0 0;
                            width: 100%;
                        }
                    }

                    div.actions-toolbar {
                        margin: 0;
                        float: right;
                    }
                }

                .returns-comments {
                    flex: 1 1 450px;

                    .returns-comment {
                        padding: 15px;
                        border-bottom: 1px solid $light-grey2;

                        &:first-child {
                            border-top: 1px solid $light-grey2;
                        }

                        .comment-header {
                            margin: 0;

                            .comment-author {
                                font-weight: bold;
                            }

                            .comment-date {
                                float: right;
                            }
                        }
                    }
                }
            }
        }
    }

}
