////// tissot btn

button {
  line-height: 1.6em;
}

@mixin make-btn {
  font-family: $font-family;
  display: inline-block;
  padding: 12px 30px;
  border-radius: 2px;
  border: 1px solid transparent;
  font-weight: 400;
  font-size: 0.75em;
  line-height: 1.33em;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
}

@mixin make-btn-primary {
  font-family: $font-family;
  background: $primary;
  color: $white;
  position: relative;
  overflow: hidden;
  border: 0;

  span {
    z-index: 2;
    position: relative;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0%;
    width: 100%;
    transition: all $transition-05;
    z-index: 1;
    // background: linear-gradient(to right, rgba(147,41,30,1) 0%,rgba(227,33,25,1) 51%,rgba(227,33,25,1) 100%);
    background: $primary;
  }

  &:hover, &:focus {
    color: $white;

    &:before {
      // background: linear-gradient(to right, rgba(147,41,30,1) 0%,rgba(227,33,25,1) 95%,rgba(227,33,25,1) 100%);
      background: $primary;
      transition: all $transition-02;
    }
  }

  &:focus {
    border-color: $white;
    box-shadow: 0 0 8px rgba($primary, 0.8);
    outline: 1px dashed $primary;
    outline-offset: 5px;
  }

  &:visited {
    color: $white;
  }

  &:visited:not(:hover) {
    color: $white;
  }


  &:disabled {
    background: #bdbdbd;

    &:before {
      display: none;
    }
  }
}

@mixin make-btn-secondary {
  background: $primary;
  color: $white;
  position: relative;
  overflow: hidden;
  border: 0;

  span {
    z-index: 2;
    position: relative;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0%;
    width: 100%;
    transition: all $transition-05;
    z-index: 1;
    background-color: $black;
  }

  &:hover, &:focus {
    color: $white;

    &:before {
      width: 180%;
      transition: all $transition-02;
    }
  }

  &:focus {
    border-color: $white;
    box-shadow: 0 0 8px rgba($black, 0.8);
    outline: 1px dashed $primary;
    outline-offset: 5px;
  }

  &:visited {
    color: $white;
  }

  &:disabled {
    background: #bdbdbd;

    &:before {
      display: none;
    }
  }
}

@mixin make-btn-stroke {
  border-color: $black;
  background: transparent;
  color: $black;
  box-shadow: 0 0 0 rgba($black, 0.8);
  transition: all $transition-02;

  &:hover, &:focus, &:active {
    color: $white;
    background: $black;
  }

  &:focus {
    border-color: $white;
    box-shadow: 0 0 8px rgba($black, 0.5);
    outline: 1px dashed $primary;
    outline-offset: 5px;
  }

  &.disabled {
    background: $light-grey2;
    border-color: $light-grey;
    cursor: not-allowed;

    &:hover {
      color: $black;
    }
  }
}

.buttonallwatch {
    margin-bottom: 100px;
    display: inline-block;

    .collection-subcategories__show-more {
        right: 50% !important;
        position: absolute !important;
        transform: translate(50%) !important;
    }
}
.btn {
  @include make-btn;

  &.-primary {
    @include make-btn-primary;
  }

  &.-secondary {
    @include make-btn-secondary;
  }

  &.-stroke {
    @include make-btn-stroke;
  }

  &.-big {
    font-size: 1.0em;
    font-weight: bold;
    padding: 14px 52px;
  }
}
.action.primary {
    @include make-btn-primary;
}


