.title-h1 {
    font-family: $font-family-headings;
    font-stretch: condensed;
    font-weight: normal;
    font-size: 3.125em;
    line-height: 1.25em;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $primary;

    @include min-screen($screen__l) {
        font-size: 3.125em; //50px
    }
}

.title-h2 {
    font-family: $font-family-headings;
    font-stretch: condensed;
    font-weight: normal;
    font-size: 1.56em; //25px
    line-height: 1.175em;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $primary;

    @include min-screen($screen__l) {
        font-size: 2.5em; //40px
    }

}

.title-h3 {
    font-family: $font-family-headings;
    font-weight: normal;
    font-size: 2.18em;
    line-height: 1.25em;
    text-transform: uppercase;
    color: black;

    @include min-screen($screen__l) {
        font-size: 2.18em; //35px
    }
}

.title-emphasis {
    font-family: $font-family;
    font-weight: 500;
    font-size: 18px; //16px
    line-height: 1.44em;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: black;
}

.title-h4 {
    font-family: $font-family-headings;
    font-weight: 500;
    font-size: 1em; //16px
    line-height: 1.44em;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: black;
}

.text-default {
    font-family: $font-family;
    font-weight: 300;
    font-size: 1.125em; //18px
    line-height: 1.66em;
    color: $dark-grey;
}

.text-muted {
    font-family: $font-family;
    font-weight: 300;
    font-size: 1em; //18px
    line-height: 1.5em;
    color: $light-grey;
}

.text-highlight {
    font-family: $font-family-headings;
    font-weight: bold;
    font-size: 0.875em; //14px
    line-height: 1.5em;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $primary;
}

.link-more {
    font-family: $font-family;
    font-weight: 300;
    font-size: 0.75em; //12px
    line-height: 1.33em;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $primary;
    margin-left: 18px;
    position: relative;
    display: inline-block;

    @extend .icon-chevron-right;

    &:before {
        font-family: 'icomoon';
        position: absolute;
        left: -18px;
        top: 46%;
        transform: translateY(-50%);
        transition: left $transition-fast;
    }

    &:hover {
        &:before {
            left: -15px;
        }
    }
}

.link-back {
    font-family: $font-family;
    font-weight: 400;
    font-size: 1.2rem; //12px
    line-height: 1.33em;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: black !important;
    margin-left: 18px;
    position: relative;

    @extend .icon-chevron-left;

    &:before {
        font-family: 'icomoon';
        position: absolute;
        left: -18px;
        top: 46%;
        transform: translateY(-50%);
        transition: left $transition-fast;
        display: block;
    }

    &:hover {
        &:before {
            left: -15px;
        }

        .icon {
            left: -15px;
        }
    }
    .icon {
        position: absolute;
        left: -18px;
        top: 42%;
        transform: translateY(-50%);
        transition: left $transition-fast;
        display: block;
    }
}

.link-next {
    font-family: $font-family-headings;
    font-weight: 400;
    font-size: 1.2rem; //12px
    line-height: 1.33em;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $black !important;
    margin-right: 18px;
    position: relative;
    float: right;

    @extend .icon-chevron-right;

    &:before {
        font-family: 'icomoon';
        position: absolute;
        right: -18px;
        top: 46%;
        transform: translateY(-50%);
        transition: right $transition-fast;
        display: block;
    }

    &:hover {
        &:before {
            right: -15px;
        }

        .icon {
            right: -15px;
        }
    }

    &::after {
        clear: both;
    }

    .icon {
        position: absolute;
        right: -18px;
        top: 42%;
        transform: translateY(-50%);
        transition: right $transition-fast;
        display: block;
    }
}

.link-storelocator {
    font-family: $font-family-headings;
    font-weight: 400;
    font-size: 1.2rem; //12px
    line-height: 1.33em;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $dark-grey;
    position: relative;
    margin-bottom: 20px;

    @extend .icon-map-pin;

    &:before {
        font-family: 'icomoon';
    }
}
