.product-description {
    max-width: $width-medium;
    margin: 45px auto 0;

    @include min-screen($screen__m) {
        margin: 100px auto 0;
    }

    @include min-screen($screen__xl) {
        margin: 180px auto 0;
    }

    &__title {
        @extend .title-h3;
        text-align: center;
    }

    p {
        @extend .text-default;
        color: $light-grey;
    }
}

////////////////////////////////////////////////////////////////////////////////////

.product-form {
    padding: 0 16px;
    margin: 0 auto;

    @include min-screen($screen__m) {
        padding: 0 20px;
    }

    @include min-screen($screen__xl) {
        margin-left: 3vw;
        margin-right: 3vw;
    }
}

.product-add-form {
    width: 100%;
    position: relative;

    > form {
        width: 100%;
    }

    .product-options-wrapper {
        padding: 0 0 25px;

        .title {
            font-family: $font-family;
            text-transform: uppercase;
            font-size: 1.6rem;
            font-weight: 600;
            height: auto;
            padding: 0 20px 0 0;
            color: #202020;
        }
    }

    .product-options-bottom,
    .product-options-bottom-links{
        display: block;
        align-items: stretch;

        button.btn {
            height: unset;
            margin-top: 10px;

            @include min-screen($screen__l) {
                &.action.tocart, &.btn-open-modal-retailer-links {
                    margin-bottom: 10px !important;
                }

            }

            span {
                white-space: pre-wrap;
            }
        }

        .open-contact-us-in-store-div,
        .product-options-bottom-button,
        .open-appointment-in-store-div,
        .open-digital-appointment-div,
        .open-reserve-in-store-div {
            text-align: left;
            display: block;
            width: 100%;

            @include max-screen($screen__l) {
                margin-bottom: 10px;
            }

            .btn{
                @include max-screen($screen__l) {
                    width: 100%;
                }
            }
        }

        .open-appointment-in-store-div,
        .open-digital-appointment-div,
        .open-reserve-in-store-div,
        .open-contact-us-in-store-div{
            display: flex;
            justify-content: flex-start;
            align-items: center;

            a#file-help-btn {
                top: unset;
                right: unset;
                position: relative;
            }

            button.btn {
                display: inline-block;
                flex-wrap: wrap;
                align-items: center;
                height: 100%;
                width: calc(100% - 30px);
            }
        }

        @include min-screen($screen__l) {
            display: flex;
            flex-wrap: wrap;
        }

        .product-addtocart-backsale-text {
            width: 100%;
        }

        .btn {
            overflow: hidden;
        }
    }

    .open-contact-us-in-store-div,
    .open-appointment-in-store-div,
    .open-digital-appointment-div,
    .open-reserve-in-store-div {
        display: inline-block;
        width: 100%;
        margin-top: 10px;

        @include min-screen($screen__l) {
            width: calc(50% + 25px);
        }

        .btn {
            width: calc(100%);
        }

        #file-help-btn {
            width: fit-content;
            position: relative;
            right: calc(-100% + 32px);
            top: -30px;
        }
    }

    .action.tocartupdate {
        line-height: 2.2rem;
        padding: 14px 17px;
    }

    .btn {
        width: 100%;
        margin: 0 !important;
        margin-bottom: 11px !important;

        span {
            white-space: nowrap;
        }

        @include min-screen($screen__l) {
            width: calc(50% - 5px);
            margin-right: 5px !important;
        }
    }

    .help-btn {
        display: flex;
        align-items: center;
    }

    .update {
        .btn {
            width: 100%;
        }
    }

    &.--online-button {
        .open-contact-us-in-store,
        .open-appointment-in-store,
        .open-reserve-in-store {
            margin-top: -30px;
        }
    }

    .product-retailers-pop {
        width: 100%;
        max-width: 600px;
        position: absolute;
        background-color: #ffffff;
        border: 1px solid #7777;
        z-index: 2;
        box-sizing: border-box;
        padding: 5px;

        &__close {
            text-align: left;
            height: fit-content;
            width: 100%;

            button {
                padding: 0 15px 0 15px;
            }
        }

        .product-retailers-header {
            padding-left: 50px;
            padding-right: 50px;
            padding-bottom: 10px;

            h1 {
                width: 100%;
                text-align: center;
                font-size: 24px;
                font-weight: 500;
                margin-bottom: 20px;
            }

            p {
                width: 100%;
                text-align: center;
                font-size: 14px;
                font-weight: 300;
            }

            margin-bottom: 20px;
        }

        .product-retailers-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .retailer-container {
                padding: 3px;
                text-align: center;

                @include min-screen($screen__m) {
                    width: calc(50% - 30px);
                }

                @include max-screen($screen__m) {
                    width: 65%;
                }

                .retailer-media {
                    width: 75%;
                    display: inline-block;
                }

                .retailer-link {
                    width: min-content;
                }
            }
        }
    }
}

.product-options-bottom {
    padding: 5px 0 0 0;
}

.product-options-bottom-links {
    padding: 0 0 0 0;
}

.out-stock-container{
    .out-stock-buttons{
        padding: 2px 0 0 0;
    }
}

.modal-popup.modal-product-360 {
    .modal-inner-wrap {
        max-height: 100vh;
        width: 100vw;
        height: 100vh;
        margin: 0;
        display: flex;
        flex-direction: column;

        .modal-content,
        #modal-360,
        .modal-dialog,
        .modal-inner-content {
            height: 100%;
            max-height: 100%;
        }

        .modal-inner-content {
            display: flex;
            flex-direction: column;
            align-items: center;

            .container-360 {
                flex-grow: 1;
                width: 100%;
                margin: 20px;
                background-image: var(
                    --background-image-modal-360
                ); // set by jQuery widget
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;

                img {
                    // visibility:hidden instead of display:none so the browser
                    // loads the images, preventing flickering on the 360
                    visibility: hidden;
                    position: absolute;
                    top: 0;
                }
            }
        }
    }
}

.page-product {
    padding-bottom: 30px;
    max-width: 100vw;

    @include min-screen($screen__m) {
        padding-bottom: 50px;

        .content-push {
            &:nth-child(even) {
                .text-media__text {
                    order: -1;
                }

                .text-media__media {
                    order: 1;
                }
            }

            &:nth-child(odd) {
                .text-media__text {
                    order: 2;
                }

                .text-media__media {
                    order: -1;
                }
            }
        }
    }

    #product-user-manual {
        min-width: 100%;
    }
}

.product-mosaic {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include min-screen($screen__m) {
        flex-direction: row;
        flex-wrap: wrap;
        margin: -10px;
    }

    @include min-screen($screen__xl) {
        margin: -2vw;
    }

    &__item {
        margin-top: 15px;

        .simple_text,
        .simple_html {
            margin: initial;
            padding: initial;
        }
        .simple_html {
            max-width: initial;
            .content-system--html{
                ul{
                    padding-left: 0px;
                }
            }
        }

        @include min-screen($screen__m) {
            width: calc(50% - 20px);
            margin: 10px;
        }

        @include min-screen($screen__xl) {
            width: calc(50% - 4vw);
            margin: 2vw;
        }

        &:nth-child(2) {
            background: transparent;
        }
    }

    &__img-container {
        padding-top: 100%;
        position: relative;
        overflow: hidden;
        background: $ultralight-grey;
        cursor: zoom-in;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(1);
            transition: all 0.5s ease-in-out;
            min-width: 100%;
        }
    }

    #product-mosaic-container {
        position: relative;

        #product-360-container {
            position: absolute;
            bottom: 0;
            right: 5px;
            cursor: pointer;

            img {
                -ms-transition: 0.6s ease-in-out;
                -webkit-transition: 0.6s ease-in-out;
                transition: 0.6s ease-in-out;

                &:hover {
                    -ms-transform: rotate(-360deg); /* IE 9 */
                    -webkit-transform: rotate(
                        -360deg
                    ); /* Chrome, Safari, Opera */
                    transform: rotate(-360deg);
                }
            }
        }

        .rotor-notice {
            font-style: italic;
            margin: 10px;
            font-size: 14px;
        }
    }
}

.product-mosaic__info-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    .product-mosaic__info {
        flex: 1;
        max-width: 100%;

        .product-label {
            @extend .text-highlight;
            margin-bottom: 0;

            @include min-screen($screen__m) {
                margin-bottom: 5px;
            }
        }

        .product-name {
            @extend .title-h1;
            margin-bottom: 0;

            @include min-screen($screen__m) {
                margin-bottom: 15px;
            }
        }

        .product-sku {
            font-size: 1em;
            color: $dark-grey;
            font-weight: 300;
        }

        .price-container .price {
            font-size: 1em;
        }

        .product-price {
            font-weight: 400;
            font-family: $font-family-headings;
            font-size: 1.56em; //25px
            line-height: 1.25em;
            text-transform: uppercase;
            color: black;
            margin: 10px 0 15px;

            .product-price-extra {
                display: block;
                font-size: 0.5em;
                line-height: 1.72em;

                @include min-screen($screen__m) {
                    font-size: 0.35em;
                }
            }

            @include min-screen($screen__m) {
                font-size: 2.18em; //35px
            }

            .product-price {
                font-size: 1em;
            }

        .price-information {
            font-size: 1.75rem;
            line-height: 1.25em;
            display: block;
            width: fit-content;
            margin-bottom: 30px;

            &.old-price {
                text-decoration: line-through;
                display: inline;
                color: $light-grey;
            }

            img {
                max-height: 2.25em;
                vertical-align: middle;
            }
        }
    }

        .retailer-link {
        width: 100%;

        @include min-screen($screen__l) {
            width: calc(50% - 5px);
        }
    }

    .product-variants-list {
            list-style: none;
            padding: 0;
            display: block;
            font-size: 0;
            white-space: nowrap;
            overflow: auto;
            margin: 0 -17px 20px;

            @include min-screen($screen__m) {
                margin: 0 0 20px -10px;
                display: flex;
                flex-flow: row;
                flex-wrap: wrap;

                white-space: normal;
                font-size: 1em;
            }

            li {
                width: 60px;
                margin-left: 10px;

                display: inline-block;

                &:first-child {
                    margin-left: 17px;
                }

                &:last-child {
                    margin-right: 17px;
                }

                @include min-screen($screen__m) {
                    &:first-child {
                        margin-left: 10px;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            img {
                max-width: 100%;
                height: auto;
                display: block;
            }

            a {
                display: block;
                border: 1px solid $border-color;
                transition: border-color $transition-slow;
                overflow: hidden;

                &:hover {
                    border-color: $border-active-color;
                }
            }
        }

    .product-size {
        display: flex;
        flex-wrap: wrap;
        padding: 10px 0 25px 0;

        .custom-select-2 {
            margin-right: 6px;
            padding-bottom: 5px;

            @include min-screen($screen__m) {
                width: calc(50% - 5px);
            }
            @include min-screen($screen__l) {
                padding-bottom: 4px;
            }
            @include max-screen($screen__s) {
                margin-bottom: 20px;
            }
        }

        div.product-size-description {
            flex: 1;
        }

        label {
            display: none;
        }

        select {
            display: inline-block;
        }

        a {
            color: $black;
            text-decoration: underline;
            display: inline-block;
            font-weight: 300;
            font-size: 1.125em;
        }
    }

        .product-cta {
            margin-top: 30px;
            display: flex;
            flex-flow: column;

            @include min-screen($screen__m) {
                flex-flow: row;
                flex-wrap: nowrap;
                justify-content: space-between;
            }

            .btn {
                width: 100%;

                @include min-screen($screen__m) {
                    width: calc(50% - 5px);
                }

                & + .btn {
                    margin-top: 10px;

                    @include min-screen($screen__m) {
                        margin-top: 0;
                    }
                }
            }
        }

        .product-info--additional {
            .link-storelocator__button {
                @include min-screen($screen__l) {
                    width: calc(50% - 5px);
                }
            }
            ul {
                padding-left: 0;
            }
        }

        .product-bullets {
            margin-top: 15px;
            margin-bottom: 20px;

            ul.product-assets,
            ul {
                list-style: none;
                padding-left: 0;
                margin-bottom: 12px;

                > li {
                    margin-top: 8px;
                    margin-bottom: 0.5rem;
                    padding-left: 16px;
                    position: relative;
                    line-height: 21px;
                    font-size: 18px;
                    text-transform: uppercase;

                    &:before {
                        content: "";
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        background: $primary;
                        position: absolute;
                        left: 0;
                        top: 11px;
                    }

                    strong {
                        color: $primary;
                        font-weight: normal;
                    }
                }
            }
        }
    }
}

.tissot-m2 {

    &-products {
        .swiper-pagination.swiper-pagination-bullets {
            display: none;
        }
    }
}

.swiper-slide {
    .product-item-link {
        position: relative;
        &:hover {
            &::after {
                width: 100%;
                left: 0;
                bottom: -26px;
                position: absolute;
                content: "";
                height: 3px;
                background-color: $primary;
            }
        }
    }
}

.product-group {
    text-align: center;

    &__swiper-container {
        @extend .swiper-container;
    }

    &-slider .swiper-scrollbar {
        bottom: 0;
        overflow: hidden;

    }

    .product-group-item {
        width: 50vw;
        height: 100%;
        display: inline-block;
        border: 1px solid $border-color;
        transition: border-color $transition-slow;
        overflow: hidden;

        &__link {
            width: auto;
        }

        &__img-container {
            position: relative;
            padding-top: 100%;
            overflow: hidden;
        }

        &__img {
            position: absolute;
            top: 0;
            left: 0;
        }

        &:hover {
            border-color: $primary;
        }

        @include min-screen($screen__m) {
            width: auto;
        }
    }
}

.products-slider {
    margin: 60px 0 50px;
    text-align: center;
    position: relative;

    @include min-screen($screen__m) {
        margin: 60px 20px 50px;
    }

    @include min-screen($screen__xl) {
        margin: 105px calc(3vw + 20px) 50px;
    }

    &__title {
        @extend .title-h2;
        margin-bottom: 35px;
    }

    &__btn-more {
        @extend .btn;
        @extend .-primary;
        top: 30px;
    }

    &__swiper-container {
        @extend .swiper-container;
        padding: 0 16px 23px;
        margin-bottom: 20px;

        .swiper-wrapper {
            margin-bottom: 0;
            .swiper-slide.swiper-slide-visible{
                opacity: 1;
            }
        }
    }

    .swiper-scrollbar {
        bottom: 0;
        //top: 10px;
    }
    &:not(.auto){
        .product-thumbnail {
            width: 50vw;

            @include min-screen($screen__m) {
                width: auto;
            }
        }
    }
}

.product-video {
    position: relative;
    padding-top: 75%;
    margin-top: 15px;

    @include min-screen($screen__m) {
        margin-top: 55px;
        padding-top: 56.25%;
    }

    iframe {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100% !important;
        height: 100% !important;
    }
}

.product-specs {
    margin-top: 60px;
    text-align: center;
    max-width: $width-large;

    @include min-screen($screen__m) {
        margin: 120px auto 0;
    }

    &__title {
        @extend .title-h2;
        text-align: center;
        margin-left: 16px;
        margin-right: 16px;
        font-size: 2.81em;
    }
}

.specs-usermanual {
    margin-top: 60px;
    text-align: center;
    max-width: $width-medium;

    p {
        font-size: 0.8125em;
        line-height: 2em;
    }

    .custom-select-2 {
        width: auto;
        display: inline-block;

        label {
            display: none;
        }

        select {
            text-transform: none;
            font-size: 0.8125em;
            font-weight: normal;
        }
    }

    .btn-container {
        margin: 40px;
        display: block;
    }

    .btn svg {
        vertical-align: middle;
        margin-left: 10px;
    }

    @include min-screen($screen__m) {
        margin: 70px auto 0;
    }
}

.product-specs + .specs-usermanual {
    margin-top: -50px;
}

.tabs {
    margin: 50px 0;

    @include min-screen($screen__m) {
        margin: 50px 25px;
    }
}

.tabs-btns {
    display: none;

    @include min-screen($screen__m) {
        border-bottom: 1px solid $border-color;
        display: flex;
        justify-content: space-between;
    }

    button {
        font-family: $font-family;
        background: transparent;
        border: 0;
        padding: 10px 20px 10px 0;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 0.875em;
        color: $light-grey;
        letter-spacing: 1px;
        transition: color $transition-fast;
        display: block;
        position: relative;
        margin-bottom: -1px;

        &:after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 100%;
            height: 1px;
            background-color: $primary;
            transition: right $transition-fast;
        }
    }

    button[aria-selected="true"] {
        color: $primary;

        &:after {
            right: 0;
        }
    }
}

.tabs-container {
    padding: 0 25px;
    margin-bottom: 100px;
    text-align: left;
    transition: all $transition-slow;
    border-bottom: 1px solid $border-color;

    @include min-screen($screen__m) {
        border-bottom: 0;
        padding: 40px 25px;
        margin-bottom: 0;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 20px 0 0 0;
        display: flex;
        flex-direction: column;

        @include min-screen($screen__m) {
            margin: 0 0 0 -50px;
            width: calc(100% + 50px);
            flex-direction: row;
            flex-wrap: wrap;
        }

        li {
            margin-bottom: 25px;

            @include min-screen($screen__m) {
                width: 33.33%;
                padding-left: 50px;
                margin-bottom: 30px;
            }
        }
    }

    h4 {
        @extend .title-h4;
        font-weight: 500;
        font-size: 0.875em;
        color: $light-grey;
        letter-spacing: 1px;
        margin: 0 0 10px;
    }

    p {
        margin: 0;
    }

    div[role="tabpanel"],
    .tab__content {
        max-height: 0px;
        overflow: hidden;
        transition: all $transition-slow;

        @include min-screen($screen__m) {
            max-height: 1000px;
            min-height: 200px;
            transition: all 1s ease-in;
        }

        &[hidden] {
            display: block;

            @include min-screen($screen__m) {
                display: none;
            }
        }
    }

    button[role="tab"],
    .tab__btn--mob {
        display: block;
        width: calc(100% + 50px);

        margin: 0 -25px;
        padding: 25px;
        text-transform: uppercase;
        font-weight: 600;
        text-align: left;
        font-size: 0.875em;
        color: $dark-grey;
        background: transparent;
        border: 0;
        border-radius: 0;
        border-top: 1px solid $border-color;

        position: relative;

        @extend .icon-chevron-down;

        &:before {
            font-family: "icomoon";
            position: absolute;
            right: 25px;
            top: 25px;
            color: $primary;
            font-size: 1.5em;
            transform: rotateX(0);
            transition: all $transition-fast;
        }

        @include min-screen($screen__m) {
            display: none;
        }
    }

    button[aria-selected="true"],
    .tab__btn--mob[aria-selected="true"] {
        &:before {
            transform: rotateX(180deg);
        }

        & + div[role="tabpanel"],
        .tab__content {
            max-height: 1000px;
        }
    }

    @include max-screen($screen__m) {
        button[aria-selected="true"],
        .tab__btn--mob[aria-selected="true"],
        button[aria-selected="false"],
        .tab__btn--mob[aria-selected="false"] {
            &:before {
                transform: rotateX(0deg);
            }
        }

        button[aria-selected="true"].is-open--mob,
        .tab__btn--mob[aria-selected="true"].is-open--mob,
        button[aria-selected="false"].is-open--mob,
        .tab__btn--mob[aria-selected="false"].is-open--mob,
        .is-open--mob {
            &:before {
                transform: rotateX(180deg);
            }
        }

        .tab__btn--mob[aria-selected="true"] + div[role="tabpanel"] {
            max-height: 0;
        }

        .tab__btn--mob.is-open--mob + .tab__content,
        .tab__btn--mob.is-open--mob + div[role="tabpanel"] {
            max-height: 1000px;
        }
    }
}

.product-mosaic-modal__img-container img {
    display: block;
    margin: auto auto 10px auto;
}

.modal-product-image.modal-popup._inner-scroll .modal-inner-wrap {
    max-height: 100%;
    margin: 0;
    width: 100%;
    height: 100%;

    .modal-header {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 1;

        @include min-screen($screen__m) {
            display: none;
        }
    }

    .modal-content {
        overflow: auto;
        margin: auto;
        width: 100%;
    }

    #product-modal-mosaic {
        display: flex;
        flex-direction: column;
        align-items: center;

        .product-mosaic-modal__img-container {
            cursor: url("../images/logo/x-circle.png"),
                url("../images/logo/x-circle.png") 5 5, progress;

            img {
                max-width: fit-content;
            }
        }
    }
}

.product-group-slider .swiper-wrapper .swiper-slide {
    // max-height: 68px;
    // width: 68px;
    opacity: 1;
    a {
        display: block;
        border: 1px solid $border-color;
        transition: border-color $transition-slow;
        overflow: hidden;

        &:hover {
            border-color: $primary;
        }
    }

    img {
        // max-height: 68px;
        display: block;
        margin: auto;
    }
}

.product-retailers-pop button.close {
    background: none;
    border: none;
    font-size: 32px;
    line-height: 32px;
    font-weight: lighter;
    color: #8f8f8f;
}

.catalog-product-view .page {
    padding-top: 60px;

    &.messages {
        padding-top: 0;
    }
}

#reserve-in-store .reserve-wrap {
    @include max-screen($screen__m) {
        margin: 0;
        width: 100%;
        fieldset {
            width: 100%;


            .field.qty {
                width: 20%;
                padding: 0;
            }

            .actions {
                min-width: 400px;
                padding-top: 0;
            }
        }
    }
}

.product-options-bottom {
    .box-tocart {
        width: 100%;

        fieldset {
            width: 100%;

            .field.qty {
                width: 20%;
                padding: 0;
            }

            .actions {
                min-width: 400px;
                padding-top: 0;
            }
        }
    }
}

.product-out-of-stock-price {
    display: inline-block;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 1.33em;
    letter-spacing: 2px;
    color: #404141;
}

.price-box {
    display: inline-block;
}

.product-retailers-pop {
    @include min-screen($screen__l) {
        width: calc(50% - 5px);
    }

    position: relative;
    background-color: #FFFFFF;
    border: 1px solid #7777;
    z-index: 2;
    box-sizing: border-box;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px;

    &__close {
        text-align: right;
        height: fit-content;

        button {
            padding: 0 15px 0 15px;
        }
    }

    .product-retailers-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .retailer-container {
            @include min-screen($screen__m) {
                width: calc(50% - 30px);
            }

            @include max-screen($screen__m) {
                width: 65%;
            }

            text-align: center;

            .retailer-media {
                width: 75%;
                display: inline-block;
            }

            .retailer-link {
                width: min-content;
            }
        }
    }
}

.products-slider__btn-more-div{
    padding-top: 15px;
}

#product_addtocart_form .last {
    margin-bottom: 0px;
}

.product-sticky-price {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    min-height: 40px;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    @include max-screen($screen__m) {
        &__price,
        &__link {
            width: 50%;
        }

        &__price {
            padding: 0 5px;
        }
    }

    @include min-screen($screen__m) {
        &__price {
            padding: 0 20px;
        }

        &__link {
            width: auto;
        }
    }

    &__price,
    &__link {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 2px;
        text-transform: uppercase;
    }

    &__price {
        color: white;
    }

    &__link {
        min-width: 150px;

        a,
        button {
            height: 100%;
            width: 100%;
            font-size: 12px;
            border-radius: 0;

            &:before {
                display: none;
            }
        }
    }
}

.warranty-3-years {
    min-height: 228px;
    padding: 30px 10px;
    background-image: var(--background-image);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        background: radial-gradient(
            1601.75% 111.22% at 64.6% -6.65%,
            rgba(164, 164, 164, 0) 28.58%,
            rgba(174, 174, 174, 0.69) 73.82%,
            rgba(243, 243, 243, 0.52) 98.64%
        );

        @include min-screen($screen__m) {
            background: radial-gradient(
                100% 3346.72% at 0% 40.13%,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0.42) 58.85%,
                rgba(156, 154, 154, 0.554803) 79.13%,
                rgba(253, 253, 253, 0.72) 98.64%
            );
        }
    }

    &__title {
        text-transform: uppercase;
        color: $primary;
        font-size: 35px;
        z-index: 1;
    }

    &__cosc {
        color: white;
        letter-spacing: 1px;
        z-index: 1;
    }

    &__img {
        z-index: 1;

        @include min-screen($screen__m) {
            position: absolute;
            bottom: 20px;
            right: 20px;
        }
    }
}

#product-img-loader {
    max-width: 110px;
}

.section-services{
    >p {
        color: $primary !important;
        font-weight: bold !important;
    }
    ul{
        &.list{
            list-style: none;
            margin-left: 0px;
            padding-left: 0px;
            li{
                &.mido-modal {
                    &:hover{
                        cursor: pointer;
                    }
                    &:before{
                        content: initial;
                    }
                    padding-left: 0;
                    svg {
                        width: 2.5rem;
                        height: 2.5rem;
                        margin-right: 10px;
                    }
                    span {
                        vertical-align: super;
                    }

                }

            }
        }

    }

}
