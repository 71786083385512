.footer {
    border-top: 1px solid $border-color;

    .footer-list {
        margin: 0;
        padding: 0;
        list-style: none;
        .footer-label {
            @include min-screen($screen__xl) {
                font-size: 1.6rem;
                line-height: 2.5rem;
            }
        }
    }

    .footer-label {
        display: block;
        margin-top: 10px;
        font-size: 1.6rem;
        letter-spacing: 1px;
        font-weight: 500;

        @include min-screen($screen__s) {
            margin-top: 30px;
        }

        @include min-screen($screen__xl) {
            font-size: 1.5rem;
            line-height: 2rem;
        }
    }

    li {
        margin-bottom: 0;
        width: 150px;
        text-align: center;

        &,
        a {
            text-transform: uppercase;
        }
    }

    .footer-top {
        justify-content: center;
        padding: 40px 0;

        @include min-screen($screen__s) {
            padding: 25px 0;
        }

        @include min-screen($screen__xl) {
            padding: 25px 3vw;
        }

        ul {
            display: flex;
            width: 100%;

            @include max-screen($screen__s) {
                flex-wrap: wrap;
            }
        }

        li {
            width: 50%;
            padding: 0 8px;

            @include max-screen($screen__s) {
                &:nth-child(n+3) {
                    margin-top: 50px !important;
                }
            }

            @include min-screen($screen__m) {
                width: 25%;

                // &:nth-child(n+3) {
                //     margin-top: 0 !important;
                // }
            }

            @include min-screen($screen__l) {
                padding: 0 2%;
            }
        }

        .footer-label {
            margin-top: 10px;
        }
        .icon {
            color: $primary;
        }
        .icon-flag {
            color: #fff;
        }
    }

    .footer-bottom {
        padding: 40px 0 34px;
        background-color: $primary;

        .icon {
            color: #fff;
        }

        .footer-copyright {
            margin-top: 52px;
            border-top: 1px solid #D8D8D8;
            padding-top: 46px;
        }

        @include min-screen($screen__xl) {
            padding: 40px 0;
            > .wide_contentsystem_summary, > .footer-menus {
                padding: 0 3vw;
            }
            .footer-copyright {
                margin-top: 59px;
                border-top: 1px solid #D8D8D8;
                padding-top: 42px;
            }
        }

        &,
        a {
            color: white;
        }
    }

    .footer-logo {
        text-align: center;
    }

    .footer-titles,
    .footer-menus {
        display: flex;
        justify-content: center;

        @include max-screen($screen__s) {
            flex-flow: row wrap;
        }
    }

    .footer-titles {
        margin-top: 80px !important;

        li {
            width: 50%;
            padding: 0 10px;

            &:nth-child(n+3) {
                margin-top: 50px !important;
            }

            @include min-screen($screen__s) {
                width: 25%;

                &:nth-child(n+3) {
                    margin-top: 0 !important;
                }
            }

            @include min-screen($screen__m) {
                padding: 0 20px;
            }

            &.social-links a + a {
                margin-left: 14px;
            }
        }
    }

    .footer-menus {
        margin-top: 43px;

        .wide_contentsystem_summary {
            width: 50%;

            @include max-screen($screen__s) {
                &:nth-child(n+3) {
                    margin-top: 26px;
                }
            }

            @include min-screen($screen__s) {
                width: 25%;
            }

            @include min-screen($screen__l) {
                padding: 0 2%;
            }
        }
    }

    .footer-menu {
        li {
            margin: 0;
            padding-left: 30px;
            width: calc(100% - 30px);
            word-wrap: break-word;
            text-align: left;

            @include min-screen($screen__s) {
                width: auto;
                padding-left: 0;
                text-align: center;
            }

            & + li {
                margin-top: 30px;
            }
        }

        a {
            position: relative;
            display: inline-block;
            font-size: 1.1rem;
            line-height: 1.3rem;
            max-width: 100%;
            &:hover:after {
                width: 100%;
            }

            &:after {
                position: absolute;
                bottom: -5px;
                display: block;
                content: "";
                width: 0;
                height: 1px;
                background-color: white;
                transition: width .3s;
            }
        }
    }

    .footer-copyright {
        margin-top: 91px !important;
        margin-bottom: 0 !important;
        font-size: 1rem;
        text-align: center;
        color: $light-grey2;
    }

    .icon {
        width: 2.8rem;
        height: 2.8rem;
        color: #fff;

        &--red {
            color: $primary;
        }

        & + .icon {
            margin-left: 5px;
        }

        &.icon-flag {
            clip-path: circle(10px);
        }
    }

    &.content {
        border: 0;
        padding: 0;
        margin: 0;

    }

    .wide_contentsystem_summary {
        margin-bottom: 0;
    }
}
