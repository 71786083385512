* {
    box-sizing: border-box;
}

// helpers
@import 'theme/variables';
@import 'theme/fonts';
@import 'theme/icons';
@import 'theme/common';
@import 'theme/layout';
@import 'theme/typography';
@import 'theme/buttons';
@import 'theme/blockquote';
@import 'theme/forms';
@import 'theme/swiper';
@import 'theme/modals';
@import 'theme/cookies';
@import 'theme/page-builder';

// blocks
@import 'theme/header';
@import 'theme/background-video';
@import 'theme/background-homepage';
//@import 'theme/block-preview-account';
@import 'theme/block-preview-account';
@import 'theme/breadcrumbs';
@import 'theme/product';
@import 'theme/product-thumb';
@import 'theme/footer';
@import 'theme/prefooter';
@import 'theme/popin-store-selector';

@import 'theme/404';
@import 'theme/pager';
@import 'theme/bracelet';
@import 'theme/newsletter';
@import 'theme/stop-fake';
@import 'theme/content-system';
@import 'theme/catalogue';
@import 'theme/login';
@import 'theme/loader';
@import 'theme/store_locator';
@import 'theme/countdown';
@import 'theme/faq';

@import 'theme/popin-reserve-in-store';

// content-types
@import 'content-types/cat-thumb';
@import 'content-types/news-thumb';
@import 'content-types/category-push';
@import 'content-types/content-push';
@import 'content-types/content-types-rte';
@import 'theme/contacts';
@import 'content-types/maintenance';

//checkout
@import 'theme/messages';
@import 'theme/checkout';

//account
@import 'theme/account';
@import 'theme/owc';
@import 'theme/rma';

//find a service center
@import 'theme/findaservicecenter';

// customer service
@import 'theme/customer-service';

// column_image_link
@import 'theme/column-image-link';
